import { ACCOUNT, OPPORTUNITY } from 'components/dashboard/Metrics/constants';
import {
  BIColumnListItem,
  BIReportMetricNewborn,
} from 'components/dashboard/Metrics/metrics.types';

export const SUPPORTED_REPORT_VIEW_OBJECTS: string[] = [OPPORTUNITY, ACCOUNT];

export const DEFAULT_ACCOUNTS_ORDER_BY: BIColumnListItem = {
  name: 'account.open_opportunities',
  label: 'Open opportunities',
  type: 'number',
};

export const NEW_METRIC: Partial<BIReportMetricNewborn> = {
  object: OPPORTUNITY,
  name: '',
  filters: [],
  metadata: {
    type: 'simple',
  },
};
