import classNames from 'classnames';
import React, { useState, useContext } from 'react';
import UserAvatar from 'react-user-avatar';
import { Checkbox, Popup } from 'semantic-ui-react';

import logoutIcon from 'assets/images/logout_icon.svg';
import BuButton, { BuControlSize } from 'components/UI/BuButton';
import { ScheduleSubmissionAlert } from 'components/UI/ScheduleSubmissionAlert';
import { useHeader } from 'components/UI/Wrapper/Header/header.context';
import * as s from 'components/UI/Wrapper/Header/styles';
import { IProps } from 'components/UI/Wrapper/Header/types';
import SearchTopPanel from 'components/dashboard/SearchTopPanel';
import Tabs from 'components/dashboard/Tabs';
import { clearModalStorage } from 'components/modals/ModalContext/modal.context.utils';
import { avatarColors } from 'components/settings/styles';
import { redirect } from 'navigation/utils';
import store, { persistor } from 'store/configureStore';

const Header: React.FC<IProps> = ({
  isAdmin,
  isAdminEnabled,
  isImpersonate,
  user,
  location,
  doLogoutUser,
  appInitiated,
  updateAdminState,
}) => {
  const {
    partition,
    showTabs,
    setIsSubmitForecastPanelOpen,
    showSubmitForecastButton,
    renderExtraTabs,
  } = useHeader();
  const [isOpen, setOpen] = useState(false);

  function logOut() {
    doLogoutUser(user.email, user.api_access_token).then(() => {
      persistor.purge();
      appInitiated();
      store.dispatch({ type: 'LOGOUT_SUCCESS' });
      clearModalStorage();
      redirect('/login');
    });
  }

  const userProfileTrigger = (
    <div className={classNames(s.userAvatar, { active: isOpen })}>
      <UserAvatar
        size={32}
        name={user.name || user.email || 'NA'}
        colors={avatarColors}
      />
    </div>
  );

  const handleSubmitForecastSelect = () => {
    setIsSubmitForecastPanelOpen(true);
  };

  const userProfileContent = (
    <div className={s.userCard}>
      <div className="detail">
        <div className="icon">
          <UserAvatar
            size={63}
            name={user.name || user.email || 'NA'}
            colors={avatarColors}
          />
        </div>
        <div className="header">{user.name}</div>
        <div className="sub">{user.email}</div>
        <div className="sub">{user.role}</div>
        {isAdmin && (
          <div className={s.toggleContainer}>
            {`${isAdminEnabled ? 'Admin' : 'User'} View`}
            <Checkbox
              className={s.checkboxContainer}
              toggle
              checked={isAdminEnabled}
              onClick={() => updateAdminState({ isEnabled: !isAdminEnabled })}
            />
          </div>
        )}
      </div>
      <div className="menu">
        <a
          className="item"
          href="https://www.boostup.ai/terms-and-conditions/"
          target="_blank"
        >
          Terms & Conditions
        </a>
        <a
          className="item"
          href="https://www.boostup.ai/privacy-policy/"
          target="_blank"
        >
          Privacy Policy
        </a>
      </div>
      <div className="menu">
        <div className="item" onClick={logOut}>
          Sign Out
          <img src={logoutIcon} alt="sign out" />
        </div>
      </div>
    </div>
  );

  return (
    <div className={s.header}>
      <div className={s.headerWrapper}>
        <div>
          {!!partition && showTabs && (
            <Tabs partition={partition} wrapped>
              {renderExtraTabs}
            </Tabs>
          )}
        </div>
        {showSubmitForecastButton && (
          <div className={s.tabs_buttons_container}>
            <ScheduleSubmissionAlert />
            <BuButton
              className={s.tabs_button_forecast}
              size={BuControlSize.REGULAR}
              onClick={handleSubmitForecastSelect}
            >
              Submit Forecast
            </BuButton>
          </div>
        )}
        <div className={s.searchBarAvatarWrapper}>
          <div className={s.header_center}>
            {location.pathname !== '/search' && (
              <SearchTopPanel replaceSearch={false} />
            )}
          </div>
          <div className={s.header_right}>
            <div className={s.search_settings}>
              <Popup
                basic
                className={s.popupNoPadding}
                trigger={userProfileTrigger}
                content={userProfileContent}
                flowing
                on="click"
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                horizontalOffset={8}
                position="bottom right"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
