import { BreadcrumbOptions } from 'highcharts';
import React, { useState } from 'react';
import { Loader } from 'semantic-ui-react';
import { WidgetChart } from '../Chart/WidgetChart';

import { IColumn, IRow } from 'components/UI/common/TypedTable/TypedTable';
import { DataFetchErrorMessage } from 'components/dashboard/Metrics/Widget/Messages/DataFetchErrorMessage';
import { NoDataMessage } from 'components/dashboard/Metrics/Widget/Messages/NoDataMessage';
import { WidgetTable } from 'components/dashboard/Metrics/Widget/Table/WidgetTable';
import { HierarchicalWidget } from 'components/dashboard/Metrics/Widget/hooks/useHierarchicalWidgetFetching/useHierarchicalWidgetFetching.helper';
import { LoaderContainer } from 'components/dashboard/Metrics/Widget/widgets.styles';
import { VisualizationType } from 'components/dashboard/Metrics/enums';
import {
  BIMetricToChartType,
  BIMetricsMap,
  BIWidget,
} from 'components/dashboard/Metrics/metrics.types';
import { QueryStatus } from 'utils/network';
import { OnChartDataClick } from '../Chart/WidgetChart.types';
import { ConfigClickExtraData } from '../Table/helpers/columnsHelpers';
import { TotalsSection } from './MetricsTotals/TotalSection';
import {
  updateMetricToChartTypes,
  useGetTotalSectionConfig,
} from './MetricsTotals/helper';

interface Props {
  readonly isDashboardWidget?: boolean;
  readonly isReadOnly?: boolean;
  readonly metricsInUse: BIMetricsMap;
  readonly metricToChartType: BIMetricToChartType[];
  readonly showControls: boolean;
  readonly widget: BIWidget;
  readonly widgetData: HierarchicalWidget;
  readonly widgetDataStatus: QueryStatus;
  readonly isTableRefetching?: boolean;
  readonly showMetrics?: boolean;
  onUpdateWidget: (changes: Partial<BIWidget>) => void;
  onChartDataClick: OnChartDataClick;
  onTableDataClick: (
    column: IColumn,
    row: IRow,
    extraData: ConfigClickExtraData
  ) => void;
  onTotalsClick: (metricId: string, chartLevel?: any) => void;
  onTableSortChange?: (columnName: string) => void;
  // REMOVE CONDITIONAL ?
  addSubTreeToFetch?: (subTree: string) => void;
}

export const WidgetVisualization: React.FC<Props> = ({
  isDashboardWidget = false,
  isReadOnly = false,
  metricsInUse,
  metricToChartType,
  showControls,
  widget,
  widgetData,
  widgetDataStatus,
  isTableRefetching,
  showMetrics = true,
  onUpdateWidget,
  onChartDataClick,
  onTableDataClick,
  onTotalsClick,
  onTableSortChange,
  addSubTreeToFetch,
}) => {
  const [chartLevels, setChartLevels] = useState<BreadcrumbOptions[]>([]);

  const updateTotal = (levelObject: BreadcrumbOptions[]): void => {
    setChartLevels(JSON.parse(JSON.stringify(levelObject)));
  };

  const handleVisualizationChange = (
    chartType: VisualizationType,
    metricToChartTypes: BIMetricToChartType[]
  ): void => {
    onUpdateWidget({
      properties: {
        ...widget?.properties,
        metricToChartType: metricToChartTypes,
      },
      chart_type: chartType,
    });
  };

  const handleComputeUserHierarchyResponseChange = (compute: boolean): void => {
    onUpdateWidget({
      compute_user_hierarchy_response: compute,
    });
  };

  const handleRemoveMyReporteesDataChange = (show: boolean): void => {
    onUpdateWidget({
      advanced_configurations: {
        ...widget?.advanced_configurations,
        remove_reportees_data_from_managers: show,
      },
    });
  };

  const isTableVisualization = metricToChartType.some(
    (el) => el.chartType === 'table'
  );

  const hasData = widgetData.totals;
  const hasErrors = !!Object.keys(widgetData.errors).length;

  const availablePivots = widgetData.pivotConfigurations.length || 0;
  const hasPivots = availablePivots > 0;

  const isChartVisualization = !isTableVisualization && widgetData && hasPivots;

  const { getTotalsFromWidget, getManagerActionsConfig } =
    useGetTotalSectionConfig();

  const totalMetrics = getTotalsFromWidget(
    widgetData,
    widget,
    metricToChartType,
    chartLevels,
    showControls
  );

  const managerActionsConfig = getManagerActionsConfig(
    widget,
    isDashboardWidget,
    isReadOnly
  );

  const internalHandleVisualizationChange = (
    metricId: string,
    chartType: VisualizationType
  ): void => {
    const result: BIMetricToChartType[] = updateMetricToChartTypes(
      metricToChartType,
      chartType,
      metricId
    );

    handleVisualizationChange(chartType, result);
  };

  return (
    <>
      {widgetDataStatus === 'loading' && (
        <LoaderContainer>
          <Loader active content="Loading" />
        </LoaderContainer>
      )}
      {widgetDataStatus === 'success' && (
        <>
          {!!widgetData && (hasData || hasErrors) && showMetrics && (
            <TotalsSection
              totalPanelConfig={totalMetrics}
              managerActionsConfig={managerActionsConfig}
              chartLevels={isTableVisualization ? [] : chartLevels}
              onTotalClick={onTotalsClick}
              onVisualizationChange={internalHandleVisualizationChange}
              onComputeUserHierarchyResponseChange={
                handleComputeUserHierarchyResponseChange
              }
              onRemoveMyReporteesDataChange={handleRemoveMyReporteesDataChange}
            />
          )}

          {hasData && (
            <>
              {isTableVisualization && (
                <WidgetTable
                  sortByColumn={widget.properties?.table_view_order_by_column} // null if metric creation
                  treeWidget={widgetData}
                  widgetConfig={widget as BIWidget}
                  metricsInUse={metricsInUse}
                  isTableRefetching={isTableRefetching}
                  onTableDataClick={onTableDataClick}
                  onTableSortChange={onTableSortChange}
                  addSubTreeToFetch={addSubTreeToFetch}
                  data-testing="widget-table-section"
                />
              )}

              {isChartVisualization && (
                <WidgetChart
                  metricToChartType={metricToChartType}
                  widgetData={widgetData}
                  metricsInUse={metricsInUse}
                  widget={widget}
                  onDataClick={onChartDataClick}
                  updateTotal={updateTotal}
                  data-testing="widget-chart-section"
                />
              )}
            </>
          )}

          {hasPivots && !hasData && !hasErrors && (
            <NoDataMessage data-testing="no-data-message" />
          )}
        </>
      )}

      {widgetDataStatus === 'error' && (
        <DataFetchErrorMessage data-testing="data-fetch-error-message" />
      )}
    </>
  );
};
