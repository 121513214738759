import classNames from 'classnames';
import { css } from 'emotion';
import debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuIcon from 'components/UI/BuIcon';
import BuInput from 'components/UI/BuInput';
import BuRadio from 'components/UI/BuRadio';
import BuSelect from 'components/UI/BuSelect';
import { ISelectOption } from 'components/UI/BuSelect/types';
import { Conditions } from 'components/dashboard/Metrics/Create/Conditions/Conditions';
import {
  DEFAULT_ACCOUNTS_ORDER_BY,
  NEW_METRIC,
  SUPPORTED_REPORT_VIEW_OBJECTS,
} from 'components/dashboard/Metrics/Create/WidgetCreate/WidgetCreateOptions/ReportDefinition/constants';
import {
  InputContainer,
  MessageSpan,
  RadiosContainer,
} from 'components/dashboard/Metrics/Create/WidgetCreate/WidgetCreateOptions/ReportDefinition/styles';
import { DEFAULT_OPPORTUNITIES_ORDER_BY } from 'components/dashboard/Metrics/Create/WidgetCreate/WidgetCreateOptions/constants';
import { ACCOUNT, OPPORTUNITY } from 'components/dashboard/Metrics/constants';
import { MetricCreateSubTitle } from 'components/dashboard/Metrics/metrics.styles';
import {
  BIColumnListItem,
  BIReportMetric,
  BIMetricSimple,
  BIMetricsQueryFilter,
  BIWidget,
} from 'components/dashboard/Metrics/metrics.types';
import { getObjectList } from 'selectors/revbi/metrics';

interface Props {
  readonly widget: BIWidget;
  readonly columnList: BIColumnListItem[];
  setWidget: (widget: Partial<BIWidget>) => void;
}

enum Direction {
  Descending = -1,
  Ascending = 1,
}

export const ReportDefinition: React.FC<Props> = ({
  widget,
  columnList,
  setWidget,
}) => {
  const [localMetric, setLocalMetric] = useState<Partial<BIReportMetric>>(
    widget.metric_list[0] || NEW_METRIC
  );
  const [numberOfRecords, setNumberOfRecords] = useState<number>(
    widget.limit ?? 10
  );
  const [isValidNumberOfRecords, setValidNumberOfRecords] =
    useState<boolean>(true);
  const [orderBy, setOrderBy] = useState<string>(
    () => widget?.order_by_column?.name || ''
  );
  const [direction, setDirection] = useState<Direction>(() =>
    widget?.order_by?.[0] === 'ascending'
      ? Direction.Ascending
      : Direction.Descending
  );

  const objectList = useSelector(getObjectList);

  useEffect(() => {
    setOrderBy(() => widget?.order_by_column?.name || '');
    setDirection(() =>
      widget?.order_by?.[0] === 'ascending'
        ? Direction.Ascending
        : Direction.Descending
    );
  }, [widget]);

  const handleNumberOfRecordsChange = useCallback(
    debounce((e: React.ChangeEvent<HTMLInputElement>) => {
      const numOfRecords = e.currentTarget.valueAsNumber;
      setNumberOfRecords(numOfRecords);

      if (!numOfRecords || numOfRecords < 1 || numOfRecords > 100) {
        setValidNumberOfRecords(false);
      } else {
        setValidNumberOfRecords(true);
        setWidget({ limit: numOfRecords });
      }
    }, 400),
    [widget]
  );

  const handleObjectChange = useCallback(
    (values: string[]) => {
      setLocalMetric((prev) => ({ ...prev, object: values[0], filters: [] }));
      setWidget({
        metric_list: [{ ...localMetric, object: values[0], filters: [] }],
        order_by_column:
          values[0] === OPPORTUNITY
            ? DEFAULT_OPPORTUNITIES_ORDER_BY
            : DEFAULT_ACCOUNTS_ORDER_BY,
      });
    },
    [localMetric]
  );

  const handleOrderBy = (columnName: string): void => {
    setOrderBy(columnName);
    const column = columnList.find(
      (c: BIColumnListItem) => c.name === columnName
    );
    if (column) {
      setWidget({
        order_by_column: column,
      });
    }
  };

  const handleOrderByDirection = (direction: Direction): void => {
    setDirection(direction);
    setWidget({
      order_by: [
        direction === Direction.Ascending ? 'ascending' : 'descending',
      ],
    });
  };

  // this funtion makes me thinking we have to improve it...
  // probably wasn't a good idea to move it here...
  const handleChangeFilters = (
    complete: boolean,
    filters: BIMetricsQueryFilter[]
  ) => {
    const newMetric = { ...localMetric, filters };
    setLocalMetric(newMetric);
    setWidget({ metric_list: [newMetric] });
  };

  const objectSelectorOptions = useMemo(
    () =>
      objectList
        .filter((object: string) =>
          SUPPORTED_REPORT_VIEW_OBJECTS.includes(object)
        )
        .map((object: string) => ({
          text: object[0].toUpperCase() + object.substring(1),
          value: object,
        })),
    [objectList]
  );

  const columnsSelectorOptions = useMemo(
    () =>
      columnList
        ? columnList
            .map((column: BIColumnListItem) => ({
              text: column.label,
              value: column.name,
            }))
            // To remove duplicates which are causing issues
            .filter(
              (v, i, a) => a.findIndex((v2) => v2.value === v.value) === i
            )
            .sort((a: ISelectOption, b: ISelectOption) =>
              a.text.localeCompare(b.text, undefined, {
                numeric: true,
                sensitivity: 'base',
              })
            )
        : [],
    [columnList]
  );

  const getOrderByValue: string[] = useMemo(() => {
    if (orderBy) return [orderBy];

    if (localMetric?.object === OPPORTUNITY)
      return ['opportunity.last_touched'];

    if (localMetric?.object === ACCOUNT) return ['account.open_opportunities'];

    return [''];
  }, [orderBy, localMetric.object]);

  return (
    <>
      <InputContainer>
        <MetricCreateSubTitle>Object</MetricCreateSubTitle>
        <BuSelect
          fullWidth
          secondary
          options={objectSelectorOptions}
          defaults={[localMetric.object ?? '']}
          placeholder="Select a table"
          testingLabel="object"
          onChange={handleObjectChange}
        />
      </InputContainer>
      <InputContainer>
        <Conditions
          metric={localMetric as BIMetricSimple}
          columnFields={[localMetric.object ?? '']}
          onCompleteConditions={handleChangeFilters}
        />
      </InputContainer>
      <InputContainer>
        <MetricCreateSubTitle>Number of records</MetricCreateSubTitle>
        <BuInput
          isAlignLeftText={false}
          type="number"
          value={numberOfRecords}
          testingLabel="number-of-records"
          onChange={(e) => handleNumberOfRecordsChange(e)}
          className={classNames(
            css`
              width: 100%;
              margin-bottom: 4px;
              input {
                text-align: left !important;
              }
            `,
            !isValidNumberOfRecords &&
              css`
                border-color: var(--bu-red-400) !important;
                input {
                  background-color: var(--bu-red-100);
                  color: var(--bu-red-600);
                  box-shadow: none !important;
                }
              `
          )}
        />

        {isValidNumberOfRecords ? (
          <>
            <BuIcon
              name={BoostUpIcons.BadgeInfoSolid}
              className={css`
                font-size: 18px;
              `}
              color="var(--bu-primary-500)"
            />
            <MessageSpan isValid>
              Choose up to 100 records to show in your list
            </MessageSpan>
          </>
        ) : (
          <>
            <BuIcon
              name={BoostUpIcons.BadgeWarningSolid}
              className={css`
                font-size: 18px;
              `}
              color="var(--bu-red-500)"
            />
            <MessageSpan isValid={false}>
              Please choose a value that is less or equal to 100
            </MessageSpan>
          </>
        )}
      </InputContainer>
      <InputContainer>
        <MetricCreateSubTitle>Order by</MetricCreateSubTitle>
        <BuSelect
          secondary
          fullWidth
          searchable
          isLargePlaceholder
          options={columnsSelectorOptions}
          onChange={(values: string[]) => handleOrderBy(values[0])}
          defaults={getOrderByValue}
          placeholder="Select a option"
          testingLabel="order-by"
        />
        <RadiosContainer>
          <BuRadio
            onChange={(_, { value }) => {
              setDirection(value as Direction);
              handleOrderByDirection(value as Direction);
            }}
            value={Direction.Descending}
            checked={direction === Direction.Descending}
            label="Descending"
            testingLabel="Descending"
          />
          <BuRadio
            onChange={(_, { value }) => {
              setDirection(value as Direction);
              handleOrderByDirection(value as Direction);
            }}
            value={Direction.Ascending}
            checked={direction !== Direction.Descending}
            label="Ascending"
            testingLabel="Ascending"
          />
        </RadiosContainer>
      </InputContainer>
    </>
  );
};
