import {
  AGGREGATION_OPTIONS,
  FORECAST,
  FORECAST_SUBMISSION,
  TARGET,
} from 'components/dashboard/Metrics/constants';
import {
  getForecastSubmissionDescription,
  getTargetMetricDescription,
} from 'components/dashboard/Metrics/metrics.helpers';
import { BIMetricSimple } from 'components/dashboard/Metrics/metrics.types';

export const getAggFunc = (
  metricObject: string = '',
  aggregationFunction: string = ''
): string => {
  if (metricObject === TARGET) {
    return TARGET;
  } else if (metricObject === FORECAST_SUBMISSION) {
    return FORECAST;
  } else {
    return aggregationFunction;
  }
};

export const getMetricDescription = (metric: BIMetricSimple): string => {
  if (metric.description) {
    return metric.description;
  }

  if (metric.object === TARGET) {
    return getTargetMetricDescription(
      metric?.target_period,
      metric?.target_type
    );
  }

  if (metric.object === FORECAST_SUBMISSION) {
    return getForecastSubmissionDescription(
      metric.forecast_submission_metric,
      metric.forecast_submission_type
    );
  }

  const aggregationFunction = AGGREGATION_OPTIONS.find(
    (option) => option.value === metric?.aggregation_function
  )?.text;
  const column = `of ${
    !!metric?.column ? metric?.column?.label : metric?.object
  }`;

  if (aggregationFunction && column) {
    return `${aggregationFunction} ${column}`;
  }

  if (aggregationFunction) {
    return `${aggregationFunction}`;
  }

  return '';
};
