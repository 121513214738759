import classNames from 'classnames';
import {
  pivotTitleSeparator,
  pivotTitleWrapper,
  pivotWidgetSearchOption,
} from 'components/dashboard/Metrics/Widget/Controls/Live/styles';
import { MONTH, QUARTER, WEEK } from 'components/dashboard/Metrics/constants';
import {
  BIColumnListItem,
  BIPivotDescriptor,
} from 'components/dashboard/Metrics/metrics.types';
import { getIconByEntityName } from 'common/icons';

const buildTimeGroupName = (
  group: BIColumnListItem,
  timeField: BIColumnListItem | undefined
) => {
  switch (group.name) {
    case WEEK:
      return {
        name: `${timeField?.name}_WoW`,
        label: `${timeField?.label}-WoW`,
        type: 'timePeriod',
      };
    case MONTH:
      return {
        name: `${timeField?.name}_MoM`,
        label: `${timeField?.label}-MoM`,
        type: 'timePeriod',
      };
    case QUARTER:
      return {
        name: `${timeField?.name}_QoQ`,
        label: `${timeField?.label}-QoQ`,
        type: 'timePeriod',
      };
    default:
      return {
        name: group?.name.replace('$', '.'),
        label: group?.label,
        type: group?.type,
      };
  }
};

export const selectedPivotLabel = (
  pivot: BIColumnListItem,
  timeField: BIColumnListItem | undefined
): string => {
  if ([QUARTER, MONTH, WEEK].includes(pivot?.name)) {
    const groupReNamed = buildTimeGroupName(pivot, timeField);

    return groupReNamed.label;
  }

  return pivot?.label && pivot?.label !== 'None' ? pivot.label : 'Add pivot';
};

const optionBuilder = (
  el: BIPivotDescriptor,
  idx: number,
  isNewGroup: boolean,
  title: string,
  itemText: string,
  onChange: (values: string[], pivotNro: number) => void,
  dataTestingId: string,
  pivotNro: number,
  groups: BIPivotDescriptor[]
) => {
  const IconPath = getIconByEntityName(el.table_icon);
  return (
    <div key={`${el.name}-${idx}`}>
      {isNewGroup ? (
        <div className={pivotTitleWrapper}>
          <img src={IconPath} />
          <div className={pivotTitleSeparator}>
            {title.replace('__c', ' C').replace('_', ' ')}
          </div>
        </div>
      ) : null}
      <div
        key={el.name}
        className={classNames(pivotWidgetSearchOption, {
          selected: groups[pivotNro]?.name === el.label,
        })}
        onClick={() => onChange([el.name], pivotNro)}
        data-testing={`${dataTestingId}-${idx}`}
        data-testing-value={`${title} - ${itemText}`}
      >
        {itemText}
      </div>
    </div>
  );
};

export const pivotOptionsBuilder = (
  options: BIPivotDescriptor[],
  onChange: (values: string[], pivotNro: number) => void,
  dataTestingId: string,
  searchingValue: string = '',
  pivotNro: number = 0,
  groups: BIPivotDescriptor[]
) => {
  let titleGroup = '';
  let isNewGroup = false;

  return [
    ...options
      .filter((el) =>
        el.label.toLowerCase().includes(searchingValue.toLowerCase())
      )
      .map((el, idx) => {
        const group = el.table_name ?? '';
        const optionLabel = el.label;
        isNewGroup = group !== titleGroup;
        titleGroup = group;

        return optionBuilder(
          el,
          idx,
          isNewGroup,
          group,
          optionLabel,
          onChange,
          dataTestingId,
          pivotNro,
          groups
        );
      }),
  ];
};

export const removePivotBuilder = (
  dataTestingId: string,
  index = 0,
  onChange: (values: string[], pivotNro: number) => void
): JSX.Element => (
  <div
    key="none"
    className={classNames(pivotWidgetSearchOption)}
    onClick={() => onChange(['none'], index)}
    data-testing={`${dataTestingId}-none`}
  >
    Remove pivot
  </div>
);
