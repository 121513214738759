import 'abortcontroller-polyfill/dist/abortcontroller-polyfill-only';
import classNames from 'classnames';
import { css } from 'emotion';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Icon, Loader, SemanticCOLORS } from 'semantic-ui-react';
import { SemanticICONS } from 'semantic-ui-react/dist/commonjs/generic';

import { actions } from 'actions';
import { BoostUpIcons } from 'assets/css/boostup-icons';
import { FILTER_NAME } from 'common/constants';
import BuIcon from 'components/UI/BuIcon';
import { DropdownCalendar } from 'components/UI/OpenFiltersPanel/Dropdowns/Calendar';
import { DropdownNormal } from 'components/UI/OpenFiltersPanel/Dropdowns/Normal';
import { DropdownText } from 'components/UI/OpenFiltersPanel/Dropdowns/Text';
import { CLEAR_ICON_TIMEOUT } from 'components/settings/ManageFilters/ManagePageDefaults/FiltersPanel';
import { QueryStatus, fetchApi } from 'utils/network';

const TABS_WITH_LOCKED_SELECTION = [
  FILTER_NAME.ForecastOpportunitiesModal,
  FILTER_NAME.ForecastAnalyticsModal,
];

export type FilterDropdownOwnProps = {
  filter: { name: string; config: Filters.Config };
  onLock?: (tab: string, name: string) => void;
  onRemove?: (name: string) => void;
  onSubmit?: (name: string) => void;
  partition: 'globalDefaults' | 'initialState';
  profileId?: string;
  queryParams: string;
  reset?: boolean;
  tab: string;
  handleRemoveExternal?: () => void;
};

type DispatchProps = {
  persistFilter: ({
    name,
    tab,
    values,
    withReset,
  }: Filters.PersistSettingsFiltersParams) => void;
  setFilterExplicitly: ({
    config,
    name,
    partition,
    tab,
  }: Filters.SetExplicitlyParams) => void;
};

const mapStatusToIcon: {
  [key in QueryStatus]: {
    color: SemanticCOLORS;
    name: SemanticICONS;
  };
} = {
  error: { color: 'red', name: 'info circle' },
  success: { color: 'green', name: 'check circle outline' },
  loading: { color: 'black', name: 'info' },
  notAsked: { color: 'black', name: 'info' },
};

const getApiPoint = ({
  partition,
  profileId,
  tab,
}: {
  partition: 'globalDefaults' | 'initialState';
  profileId?: string;
  tab?: string;
}): string => {
  if (profileId) {
    return `${process.env.REACT_APP_BACKEND_URL}/api/profile/profile/${profileId}/filters/${tab}/preset_values`;
  }

  if (partition === 'globalDefaults') {
    return `${process.env.REACT_APP_BACKEND_URL}/api/settings/filters/company_preset_values`;
  }

  if (partition === 'initialState') {
    return `${process.env.REACT_APP_BACKEND_URL}/api/settings/filters/preset_values`;
  }

  return '';
};

const containerStyle = css`
  display: flex;
  flex-direction: row;
  margin-right: 10px;
`;

const withBorder = css`
  border: 1px solid var(--bu-gray-300);
  border-radius: 2px;
  border-left: none;
`;

const actionIconContainerStyle = css`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 32px;
  width: 32px;
  padding: 2px 0 5px 5px;
  font-size: 18px;
`;

const lockIconContainer = css`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 32px;
  width: 32px;
  padding: 2px 0 5px 0;
  font-size: 18px;

  &.isLocked {
    background-color: #cfeafd;
  }
`;

const addPointer = css`
  cursor: pointer;
`;

const FilterDropdown: React.FC<FilterDropdownOwnProps & DispatchProps> = ({
  filter,
  onRemove,
  onLock,
  onSubmit,
  partition,
  persistFilter,
  profileId,
  queryParams,
  reset,
  setFilterExplicitly,
  tab,
  handleRemoveExternal,
}) => {
  const { config, name } = filter;
  const { isChanged, isLocked, type } = config || {};

  const [status, setStatus] = useState<QueryStatus | null>(null);
  const [lockStatus, setLockStatus] = useState<QueryStatus>('notAsked');
  const [submitted, setSubmitted] = useState<string>(queryParams);
  const [lastSuccess, setLastSuccess] = useState<Filters.SetExplicitlyParams>({
    config,
    name,
    partition,
    profileId,
    tab,
  });

  const abortController = new AbortController();
  const isLoading = status === 'loading';
  const queryMethod = profileId ? 'put' : 'post';
  const url = getApiPoint({ partition, profileId, tab });
  const Handler = ['date', 'datepicker'].includes(type)
    ? DropdownCalendar
    : type === 'search_bar'
    ? DropdownText
    : DropdownNormal;
  const isDeleteButton = partition === 'initialState';
  const isLockButton = profileId;

  let timeoutId: ReturnType<typeof setTimeout> | null = null;

  useEffect(() => {
    if (status === 'success') {
      timeoutId = setTimeout(() => setStatus(null), CLEAR_ICON_TIMEOUT);
    }

    if (status === 'error') {
      timeoutId = setTimeout(() => {
        setFilterExplicitly(lastSuccess);
        setStatus(null);
      }, CLEAR_ICON_TIMEOUT);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [JSON.stringify(lastSuccess), status]);

  useEffect(() => {
    if (
      (reset && isChanged) ||
      (type !== 'checkbox' && queryParams !== submitted)
    ) {
      setStatus('notAsked');
    }
  }, [queryParams, reset, submitted]);

  useEffect(() => {
    if (status === 'notAsked') {
      const setData = () => {
        setSubmitted(queryParams);
        setLastSuccess({ config, name, partition, tab });
        onSubmit && onSubmit(name);
        /**
         * It is already being called on FiltersPanel, but when we have
         * to call preset_values route, we have to call it again
         * to update the preset_values for the filters to update the state
         */
        handleRemoveExternal && handleRemoveExternal();
      };

      fetchApi<string, {}>({
        queryMethod,
        queryParams,
        setData,
        setStatus,
        signal: abortController.signal,
        url,
      });
    }

    return () => {
      if (isLoading) {
        abortController.abort();
      }
    };
  }, [JSON.stringify(filter), partition, tab, queryParams, status]);

  useEffect(() => {
    setLockStatus('success');
  }, [isLocked]);

  const handleLock = useCallback(() => {
    if (lockStatus !== 'loading' && onLock) {
      setLockStatus('loading');
      onLock(tab, name);
    }
  }, [isLocked, lockStatus, tab, name]);

  const handleChange = useCallback(
    (values, withReset) => {
      if (!isLoading) {
        persistFilter({ name, profileId, tab, values, withReset });
      }
    },
    [isLoading, name, tab]
  );

  const handleClose = useCallback(() => {
    if (queryParams !== submitted) {
      setStatus('notAsked');
    }
  }, [queryParams, submitted]);

  const handleRemove = useCallback(() => {
    if (partition === 'initialState') {
      onRemove && onRemove(name);
    }
  }, [name, partition]);

  if (!config) {
    return null;
  }

  // We don't let user to set default values for this modal filters [VPD-9304] [VPD-12348]
  const isValueSelectionLockedForTab = TABS_WITH_LOCKED_SELECTION.includes(tab);

  return (
    <div className={containerStyle}>
      <Handler
        config={{
          ...config,
          allowFuture: true,
          isLocked: config.isLocked || isValueSelectionLockedForTab,
        }}
        isProfileSettings={!!profileId && !isValueSelectionLockedForTab}
        key={name}
        name={name}
        onChange={handleChange}
        onClose={handleClose}
        tab="settings"
      />

      <div
        className={classNames(actionIconContainerStyle, {
          [withBorder]: isDeleteButton,
        })}
      >
        {isLoading && <Loader active size="mini" />}

        {(!status || status === 'notAsked') && isDeleteButton ? (
          <div onClick={handleRemove}>
            <BuIcon name={BoostUpIcons.Trash} className={addPointer} />
          </div>
        ) : null}

        {(status === 'success' || status === 'error') && (
          <Icon
            color={mapStatusToIcon[status].color}
            name={mapStatusToIcon[status].name}
          />
        )}
      </div>

      {isLockButton ? (
        <div
          className={classNames(lockIconContainer, {
            [withBorder]: isLockButton,
            isLocked,
          })}
          onClick={handleLock}
        >
          {lockStatus !== 'loading' &&
            (isLocked ? (
              <BuIcon name={BoostUpIcons.Lock} />
            ) : (
              <BuIcon name={BoostUpIcons.Unlock} />
            ))}
          {lockStatus === 'loading' && <Loader active size="mini" />}
        </div>
      ) : null}
    </div>
  );
};

const mapDispatchToProps = (
  dispatch: Function,
  ownProps: FilterDropdownOwnProps
): DispatchProps => {
  const { partition, profileId } = ownProps;

  const action = profileId
    ? actions.ui.filtersSettings.persistProfileFilters
    : partition === 'globalDefaults'
    ? actions.ui.filtersSettings.persistGlobalDefaults
    : actions.ui.filtersSettings.persistPageDefaults;

  return {
    persistFilter: ({
      name,
      tab,
      values,
      withReset,
    }: Filters.PersistSettingsFiltersParams) =>
      dispatch(action({ name, profileId, tab, values, withReset })),
    setFilterExplicitly: ({
      config,
      name,
      partition,
      profileId,
      tab,
    }: Filters.SetExplicitlyParams) =>
      dispatch(
        actions.ui.filtersSettings.setExplicitly({
          config,
          name,
          partition,
          profileId,
          tab,
        })
      ),
  };
};

export default connect(null, mapDispatchToProps)(FilterDropdown);
