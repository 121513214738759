import { WidgetMetricConfiguration } from 'api/RevBiWidget';

import { formatMoney, formatNumber } from 'common/numbers';
import {
  IColumn,
  IRow,
  SortOrder,
  ValueProp,
} from 'components/UI/common/TypedTable/TypedTable';
import { ColumnTypes } from 'components/UI/common/TypedTable/renderers';
import { COLUMN_DEFAULTS } from 'components/dashboard/Metrics/Widget/Table/constants';
import { getUnits } from 'components/dashboard/Metrics/Widget/helper';
import { UnitType } from 'components/dashboard/Metrics/enums';
import {
  BIMetricSimple,
  BIMetricsMap,
} from 'components/dashboard/Metrics/metrics.types';

export type ConfigClickExtraData = {
  subValueClicked?: boolean;
};

export const getSortingConfiguration = (
  availablePivots: number,
  isFirstPivotDate: boolean
) => {
  if (availablePivots === 1) {
    if (isFirstPivotDate) {
      return {
        firstColumnSortable: false,
        metricsSortable: false,
      };
    } else {
      return {
        firstColumnSortable: true,
        metricsSortable: true,
      };
    }
  } else {
    if (isFirstPivotDate) {
      return {
        firstColumnSortable: true,
        metricsSortable: true,
      };
    } else {
      return {
        firstColumnSortable: true,
        metricsSortable: false,
      };
    }
  }
};

export const getDateExtraHeadersForMetrics = (
  datePivotValues: string[],
  metricsCount: number
) => [
  {
    id: 'Hierarchy',
    label: '',
    field: '',
    type: ColumnTypes.TEXT,
    sortable: false,
    sort_order: SortOrder.ASCENDING,
    config: {},
    colSpan: 1,
  },
  ...datePivotValues.map((datePivotValue) => {
    return {
      id: datePivotValue,
      label: datePivotValue,
      field: datePivotValue,
      type: ColumnTypes.TEXT,
      sortable: false,
      sort_order: SortOrder.ASCENDING,
      config: {},
      colSpan: metricsCount,
    };
  }),
];

export const getMetricsForExtraHeaders = (
  extraHeaders: IColumn[],
  metricColumns: WidgetMetricConfiguration[]
): WidgetMetricConfiguration[] =>
  extraHeaders
    // First header is for the hierarchy
    // We don't need metric columns for that
    // so we remove it
    .slice(1)
    .flatMap((extraHeader) =>
      metricColumns.map((metricColumn) => {
        const fieldForMetric = `${metricColumn.field_name}|${extraHeader.field}`;

        return {
          ...metricColumn,
          field_name: fieldForMetric,
        };
      })
    );

const getFormattedValue = (
  type: string,
  value: ValueProp,
  companyCurrencyCode: string,
  prefix: string,
  sufix: string
): string | number => {
  const signal = (value as number) < 0 ? '-' : '';
  const percentageSign = type === 'percentage' ? '%' : '';
  if (isNaN(value as number)) {
    return '-';
  }

  const formattedNumber = `${signal}${prefix}${formatNumber(
    Math.abs(value as number),
    0
  )}${percentageSign}${sufix}`;

  switch (type) {
    case 'money':
      return formatMoney(companyCurrencyCode, value as number, 0);
    case 'number':
    case 'percentage':
      return formattedNumber;
    default:
      return `${signal}${prefix}${value}${sufix}`;
  }
};

export const getMetricColumns = (
  metricsColumns: WidgetMetricConfiguration[],
  metricsInUse: BIMetricsMap,
  isSortable: boolean,
  companyCurrencyCode: string,
  onTableDataClick: (
    column: IColumn,
    row: IRow,
    extraData: ConfigClickExtraData
  ) => void
): IColumn[] =>
  metricsColumns.map((metricColumn) => {
    const metricID = metricColumn.field_name.split('|')?.[0];
    const extraHeaderValue = metricColumn.field_name.split('|')?.[1] ?? '';

    const tableColumn = {
      ...COLUMN_DEFAULTS,
      id: metricColumn.field_name,
      align: 'right',
      customColor: metricColumn.displayConfig?.column_highlight_color,
      field: metricColumn.field_name,
      label: metricColumn.display_name,
      sortable: isSortable,
      extraHeader: extraHeaderValue,
    };

    if (['percentage', 'money', 'number'].includes(metricColumn.type)) {
      const metricDefinition = metricsInUse[metricID];
      const prefix = getUnits(UnitType.Prefix, metricDefinition);
      const sufix = getUnits(UnitType.Suffix, metricDefinition);
      const isCountAgg =
        (metricDefinition as BIMetricSimple)?.aggregation_function === 'count';

      // counting is always a number,
      // doesn't matter which kind of column type are you counting.
      // and if type is not defined will set as a number.
      const type = isCountAgg
        ? 'number'
        : (metricDefinition as BIMetricSimple)?.column?.type ??
          metricColumn.type ??
          'number';

      /**
       * remember if the first pivot is date, we need take care about the |
       * to know which is the real value for that period time
       */
      const displayMetrics = metricColumn.displayConfig?.subvalues ?? {};
      const postPipeSection = metricColumn.field_name.split('|')[1];
      const metricKeys = Object.keys(displayMetrics);
      const subValueKey = metricKeys[0];
      const subValueData = { ...displayMetrics[subValueKey] };

      if (postPipeSection) {
        subValueData.field_name = `${subValueData.field_name}|${postPipeSection}`;
      }

      const template = subValueData.template
        ? `${subValueData.template}: {value}`
        : '{value}';

      return {
        ...tableColumn,
        type: metricColumn.type,
        config: {
          click: (
            column: IColumn,
            row: IRow,
            extraData: ConfigClickExtraData
          ) => {
            onTableDataClick(column, row, extraData);
          },
          formatter: (value: ValueProp) =>
            getFormattedValue(type, value, companyCurrencyCode, prefix, sufix),
          subValue: {
            relativeField: subValueData?.field_name ?? '',
            template,
          },
        },
      } as IColumn;
    }

    return {
      ...tableColumn,
      type: metricColumn.type === 'date' ? ColumnTypes.TEXT : metricColumn.type,
      config: {},
    } as IColumn;
  });
