import React, { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

import BuSelect from 'components/UI/BuSelect';
import {
  ALL_ACTIVE_OPTION,
  ALL_OPTION,
} from 'components/dashboard/Metrics/Widget/TemplateFilters/TemplateFilter/constants';
import {
  BIColumnListItem,
  BIMetricsFilter,
  BIPicklistOption,
} from 'components/dashboard/Metrics/metrics.types';
import { fetchApi } from 'utils/network';

interface Props {
  templateFilter?: BIMetricsFilter;
  widgetFilterLabel: string;
  widgetFilterName: string;
  widgetFilterType: string;

  updateFilters: (
    emptyFilters: boolean,
    operator: string,
    value: string | string[] | number
  ) => void;
}

export const PicklistTypeFilter: React.FC<Props> = ({
  templateFilter,
  widgetFilterLabel,
  widgetFilterName,
  widgetFilterType,
  updateFilters,
}) => {
  const [picklistValues, setPicklistValues] = useState<BIPicklistOption[]>([]);

  useEffect(() => {
    fetchApi<BIColumnListItem, BIPicklistOption[]>({
      url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/company_settings/get_column_values`,
      queryMethod: 'post',
      queryParams: {
        label: widgetFilterLabel,
        name: widgetFilterName,
        type: widgetFilterType,
      },
      setData: (result: BIPicklistOption[]) => {
        // Filter to remove duplicates which are causing issues
        const cleanRst = result.filter(
          (v, i, a) => a.findIndex((v2) => v2.value === v.value) === i
        );
        setPicklistValues(cleanRst);
      },
      setError: (error: string | null) => {
        toast.error(`Failed to load picklist values: ${error}`);
      },
    });

    return () => {
      setPicklistValues([]);
    };
  }, []);

  const selectorOptions = useMemo(() => {
    const allExists = picklistValues.some((e) => e.display_name === 'All');
    const optionList = picklistValues.map((value: BIPicklistOption) => ({
      text: value.display_name,
      value: value.value,
    }));

    return { options: optionList, hasAll: allExists };
  }, [picklistValues]);

  const selectedValues = useMemo(() => {
    const filterValues = (templateFilter?.value as string[]) || [];

    if (filterValues.length) {
      return filterValues;
    } else if (selectorOptions.hasAll) {
      return ['__all__'];
    } else {
      return [];
    }
  }, [selectorOptions.hasAll, templateFilter?.value]);

  const handlePicklistValuesChange = (values: string[]): void => {
    /**
     * if the selected options include all active, we should send all the active options
     * if the selected options include all, we should send only all as a value
     */
    if (values.includes(ALL_ACTIVE_OPTION.value)) {
      const newValues = [
        ...values,
        ...picklistValues
          .filter((value) => value.is_active)
          .map((value) => value.value),
      ];
      const cleanNewValues = new Set(newValues);
      cleanNewValues.delete(ALL_ACTIVE_OPTION.value);
      cleanNewValues.delete(ALL_OPTION.value);
      updateFilters(!values.length, 'in', [
        ...cleanNewValues.values(),
      ] as string[]);
      return;
    } else if (values.includes(ALL_OPTION.value)) {
      updateFilters(true, 'in', [ALL_OPTION.value]);
      return;
    }
    updateFilters(!values.length, 'in', values);
  };

  return (
    <BuSelect
      multiselect
      secondary
      searchable
      isRevBIMultiPicklist={widgetFilterType === 'multipicklist'}
      options={selectorOptions.options}
      defaults={selectedValues}
      inlineLabel={widgetFilterLabel}
      testingLabel={`PicklistTypeFilter`}
      returnFullList={!selectorOptions.hasAll}
      onChange={handlePicklistValuesChange}
    />
  );
};
