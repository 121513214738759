import { css } from 'emotion';

export const container = css`
  position: relative;

  & .bu-btn-content {
    white-space: nowrap;
  }

  button {
    color: var(--bu-primary-700) !important;
    font-family: var(--bu-font-medium);

    i {
      border-color: var(--bu-primary-700) !important;
      top: 15px;
    }
  }
`;
