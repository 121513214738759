import classNames from 'classnames';
import isEqual from 'lodash/isEqual';
import { isEmpty, equals } from 'ramda';
import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Dimmer } from 'semantic-ui-react';

import { actions } from 'actions';
import * as forecastActions from 'actions/forecastActions';
import TotalPanel from 'components/dashboard/Forecast/TotalPanel';
import {
  IMetric,
  IMetricData,
} from 'components/dashboard/Forecast/dashboardTypes';
import * as styles from 'components/dashboard/Forecast/styles';
import { IReduxState } from 'reducers/types';
import * as selectors from 'selectors';
import { FiltersForAPI } from 'selectors/openFilters';

interface OwnProps {
  tab?: string;
  filters: FiltersForAPI;
  classes?: Record<string, boolean> | string;
  selectedBusinessType?: string;
  showingMetrics?: boolean;
}

const sortMetrics = (data: IMetric) => {
  const metricsOrdered: IMetricData[] = [];

  if (data) {
    Object.entries(data).forEach(([name, metric]) => {
      metricsOrdered[metric.order] = { name, ...metric };
    });
  }

  return metricsOrdered;
};

const ForecastTotalsPanel: React.FC<IProps & RouteComponentProps> = ({
  tab,
  filters,
  match,
  classes,
  location,
  selectedBusinessType,
  metricsData,
  isLoading,
  isTotalsUpToDate,
  selectedItem,
  isMetricsExpanded,
  filterPersist,
  persistMetricsStatus,
  isBookedTotalEnabled,
  selectItem,
  fetchForecastData,
  opportunityMetricsBarClickableEnabled,
  wonStages,
  showingMetrics,
}) => {
  const [initialFilters, setInitialFilters] =
    useState<FiltersForAPI | null>(null);
  const [isFirstExpand, setFirstExpand] = useState<boolean>(true);
  const prevFilters = useRef<FiltersForAPI | null>(null);
  const previousIsTotalsUpToDate = useRef(isTotalsUpToDate);
  const previousSelectedBusinessType = useRef<string | undefined>(undefined);
  const containerRef = useRef<HTMLDivElement>(null);
  const persistTab = tab || 'forecast';

  useEffect(() => {
    setInitialFilters(filters);
  }, []);

  useEffect(() => {
    if (isMetricsExpanded && isFirstExpand) {
      setFirstExpand(false);
    }
  }, [isFirstExpand, isMetricsExpanded]);

  const shouldRefreshMetric = useMemo(
    () => !isEqual(prevFilters.current, filters),
    [filters]
  );

  useEffect(() => {
    /**
     * Call fetchForecastData only if the filters are not emtpy and
     * either the isTotalsUpToDate has not changed (filters has changed) or
     * the isTotalsUpToDate is false (totals are not up to date).
     */
    let shouldFetchForecastData: boolean =
      !isEmpty(filters) && !isFirstExpand && shouldRefreshMetric;

    if (selectedBusinessType) {
      shouldFetchForecastData =
        shouldFetchForecastData ||
        Boolean(
          !isEqual(previousSelectedBusinessType.current, selectedBusinessType)
        );
    }

    if (shouldFetchForecastData) {
      fetchForecastData(filters);
      prevFilters.current = filters;
      previousIsTotalsUpToDate.current = isTotalsUpToDate;
      if (selectedBusinessType) {
        previousSelectedBusinessType.current = selectedBusinessType;
      }
    }
  }, [
    isFirstExpand,
    isTotalsUpToDate,
    JSON.stringify(filters),
    selectedBusinessType,
    shouldRefreshMetric,
  ]);

  useEffect(() => {
    selectItem('');
  }, [match]);

  const transformValues = (values: string[]) =>
    values.map((id) => ({ id, checked: true }));

  const clickHandler = useCallback(
    (name: string, metric: IMetricData): void => {
      const isSelected = selectedItem === name;

      selectItem(isSelected ? '' : name);

      if (isSelected && initialFilters) {
        Object.keys(metric.filters).forEach((filterName) => {
          filterPersist({
            tab: persistTab,
            name: filterName,
            values: transformValues(initialFilters![filterName]),
            withReset: true,
          });
        });
      } else {
        const metricFilters = {
          ...metric.filters,
          opportunity_stages: equals(metric.filters['opportunity_stages'], [
            '__won__',
          ])
            ? wonStages
            : metric.filters['opportunity_stages'] ||
              initialFilters!['opportunity_stages'],
        };

        Object.entries(metricFilters).forEach(([key, values]) => {
          filterPersist({
            tab: persistTab,
            name: key,
            checkedAll: isEmpty(values),
            values: transformValues(values),
            withReset: true,
          });
        });
      }
    },
    [selectedItem, metricsData]
  );

  useEffect(() => {
    persistMetricsStatus({
      path: location.pathname,
      expanded: !!showingMetrics,
    });
  }, [showingMetrics]);

  const metrics = sortMetrics(metricsData);

  return (
    <div
      className={classNames('main-total-panel', {
        [styles.metrics_main]: true,
        [styles.metrics]: match.path === '/opportunities',
      })}
    >
      <div
        className={classNames(
          {
            [styles.totalPanelWrapper]: true,
            hidden_panel: !isMetricsExpanded,
          },
          classes
        )}
      >
        <Dimmer.Dimmable dimmed={isLoading}>
          <div
            className={styles.totalPanelRow}
            style={{
              gridTemplateColumns: `repeat(${
                metrics.length > 5 ? '6' : metrics.length
              }, 1fr)`,
            }}
            ref={containerRef}
          >
            {metrics.map((metric, index) => {
              if (metric!.name === 'Booked' && !isBookedTotalEnabled) {
                return null;
              }

              return (
                <TotalPanel
                  key={`${metric!.name}-${metric!.order}`}
                  id={metric!.name}
                  isLoading={isLoading}
                  clickable={
                    tab !== 'pipeline_analytics' &&
                    opportunityMetricsBarClickableEnabled
                  }
                  title={metric!.display_name!}
                  value={metric!.value}
                  count={metric!.count}
                  onClick={() => clickHandler(metric!.name!, metric!)}
                  selectedItem={selectedItem}
                  metricNumber={index}
                />
              );
            })}
          </div>
        </Dimmer.Dimmable>
      </div>
    </div>
  );
};

const mapStateToProps = (state: IReduxState) => ({
  metricsData: selectors.getMetricsData(state),
  isLoading: selectors.isForecastLoading(state),
  isTotalsUpToDate: selectors.getTotalsStatus(state),
  selectedItem: selectors.getSelectedItem(state),
  isMetricsExpanded: selectors.isMetricsExpanded(state),
  isBookedTotalEnabled:
    selectors.getFeatureFlags(state)['top_metric_booked_enabled'],
  opportunityMetricsBarClickableEnabled:
    selectors.getFeatureFlags(state)[
      'opportunity_metrics_bar_clickable_enabled'
    ],
  wonStages: selectors.getSettingsStagesByKind(state, 'won'),
});

const mapDispatchToProps = {
  fetchForecastData: forecastActions.fetchForecastData,
  selectItem: forecastActions.selectItem,
  filterPersist: actions.ui.filters.persist,
  persistMetricsStatus: actions.ui.appState.persistMetricsStatus,
};

type DispatchProps = typeof mapDispatchToProps;
type StateProps = {
  metricsData: IMetric;
  isLoading: boolean;
  isTotalsUpToDate: boolean;
  selectedItem: string;
  isMetricsExpanded: boolean;
  isBookedTotalEnabled: boolean;
  opportunityMetricsBarClickableEnabled: boolean;
  wonStages: string[];
};

export type IProps = OwnProps &
  StateProps &
  DispatchProps &
  RouteComponentProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ForecastTotalsPanel));
