import { css } from 'emotion';
import React, { FC } from 'react';

import BuDropdown, {
  BuDropdownItem,
  BuDropdownItemContainer,
  getDropdownItemProps,
} from 'components/UI/BuDropdown';
import { IProps } from 'components/UI/TopicsDiscussedTabs/TimespanSelector/types';

const timeInterval = [
  { text: 'Last 7 days', value: 'L7D' },
  { text: 'Last 14 days', value: 'L14D' },
  { text: 'Last 30 days', value: 'L30D' },
  { text: 'Last 3 months', value: 'L3M' },
  { text: 'Last 6 months', value: 'L6M' },
  { text: 'All Time', value: 'ALT' },
];

const customBuDropdown = css`
  color: var(--bu-gray-800) !important;
  border-color: var(--bu-gray-400) !important;

  &.bu-dropdown.bu-secondary {
    .bu-btn-content {
      i.bu-down,
      i.bu-up {
        border-color: var(--bu-gray-800);
      }
    }
  }
`;

const Timespan: FC<IProps> = ({ value, onChange }) => {
  const activeOption = timeInterval.find((i) => value === i.value);

  return (
    <BuDropdown
      label={activeOption?.text}
      secondary
      className={customBuDropdown}
    >
      {(hide) => (
        <BuDropdownItemContainer
          style={{ maxHeight: 300 }}
          className="bu-font-default"
        >
          {timeInterval.map((el) => (
            <BuDropdownItem
              key={el.value}
              {...getDropdownItemProps(el, value === el.value, hide, () =>
                onChange(String(el.value))
              )}
            >
              {el.text}
            </BuDropdownItem>
          ))}
        </BuDropdownItemContainer>
      )}
    </BuDropdown>
  );
};

export default Timespan;
