import classNames from 'classnames';
import { isString, isArray, isNil } from 'lodash';
import React, { useState, useEffect, useRef } from 'react';
import { Icon } from 'semantic-ui-react';
import { formatTo } from 'sharedLogicMainAppAndRevBi/timeSpanOptions';
import { timeSpanOptions } from 'sharedLogicMainAppAndRevBi/timeSpanOptions';

import ChangesSinceTab from 'components/UI/TimeFilter/TabsForFilter/ChangesSinceTab';
import { timeOptions } from 'components/UI/TimeFilter/TabsForFilter/timeSpanOptions';
import {
  time_filter_ft_btn,
  time_filter_ft_dropdown,
  time_filter_ft_dropdown_wrap,
  time_filter_ft_label,
  time_filter_ft_wrapper,
  tooltip_ft,
} from 'components/UI/TimeFilter/styles';
import { ITimeFilterForTrendsProps } from 'components/UI/TimeFilter/types';

const betweenDateInitialState = { startDate: '', endDate: '' };
const defaultBtnLabel = 'Time Filter';

const TimeFilterForTrends: React.FC<ITimeFilterForTrendsProps> = ({
  value,
  onChange,
  tab,
}) => {
  const refBtnFilter = useRef<HTMLButtonElement>(null);
  const refPopupFilter = useRef<HTMLDivElement>(null);

  const [betweenDate, setBetweenDate] = useState(betweenDateInitialState);
  const [isCalendarActive, setIsCalendarActive] = useState<boolean>(true);
  const [isOpenFilter, setOpenFilter] = useState<boolean>(false);
  const [openDefaultTab, setOpenDefaultTab] = useState<string>('Changes Since');
  const [activeValue, setActiveValue] = useState<string>(
    Array.isArray(value) ? value[0] : value
  );

  const isDashboard = tab.includes('_dashboard');

  useEffect(() => {
    if (isString(value) && value.length === 21) {
      const startDate = value.split(',')[0];
      const endDate = value.split(',')[1];

      setOpenDefaultTab('Changes Since');

      setBetweenDate({
        startDate: formatTo(startDate, 'YYYY-MM-DD', 'MM/DD/YYYY'),
        endDate: formatTo(endDate, 'YYYY-MM-DD', 'MM/DD/YYYY'),
      });

      setActiveValue(value);
      return;
    }
    if (value && value[0] && value[0].length >= 10) {
      const startDate = value[0].split(',')[0];
      const endDate = value[0].split(',')[1];

      setBetweenDate({
        startDate: formatTo(startDate, 'YYYY-MM-DD', 'MM/DD/YYYY'),
        endDate: formatTo(endDate, 'YYYY-MM-DD', 'MM/DD/YYYY'),
      });
    }

    let actValue = isArray(value) ? value[0] : value;
    if (isCalendarActive) {
      setActiveValue(actValue);
    }
  }, [value]);

  useEffect(() => {
    document.addEventListener('mousedown', (e) => handleClickOutside(e));

    return () => {
      document.removeEventListener('mousedown', (e) => handleClickOutside(e));
    };
  }, [refPopupFilter]);

  const handleClickOutside = (e: Event) => {
    const refPopup = refPopupFilter.current;
    const refButton = refBtnFilter.current;

    if (
      !isNil(refPopup) &&
      !refPopup.contains(e.target as Node) &&
      !isNil(refButton) &&
      !refButton.contains(e.target as Node)
    ) {
      setOpenFilter(false);
    }
  };

  const toggleHandler = () => {
    setOpenFilter(!isOpenFilter);
  };

  const handleChange = (filter: string, value: string) => {
    if (filter === 'change_interval') {
      setIsCalendarActive(true);
      onChange(filter, [value]);

      // predefined value selected -> reset custom date
      if (!value.includes(',')) {
        setBetweenDate(betweenDateInitialState);
      }
    }
    if (filter === 'close_date_interval') {
      setIsCalendarActive(false);
      setActiveValue('');
      onChange('change_interval', ['']);
      onChange(filter, [value]);
    }
    setOpenFilter(false);
  };

  const labelButton = () => {
    if (!isCalendarActive) {
      return `Changes since: ${betweenDate.startDate}`;
    } else {
      if (activeValue) {
        const options = [
          ...timeSpanOptions.timeSpanPresent,
          ...timeSpanOptions.timeSpanFuture,
          ...timeSpanOptions.timeSpanPast,
          ...timeOptions.firstCol,
          ...timeOptions.secondCol,
          ...timeOptions.thirdCol,
        ];

        const timeSpan = options.find((el) => activeValue.includes(el.value));
        return `Changes since: ${
          (timeSpan && timeSpan.text) ||
          (betweenDate.startDate === ''
            ? 'Last 7 Days'
            : betweenDate.startDate || 'MM/DD/YYYY')
        }`;
      }
    }
    return defaultBtnLabel;
  };

  const btnFilterLabel = labelButton();

  const tooltipMessage = () => {
    if (isDashboard) {
      return `Changes in ${btnFilterLabel}`;
    }
    if (!isDashboard && btnFilterLabel !== 'Closing in the Future') {
      return `Closing in ${btnFilterLabel}`;
    }
    return btnFilterLabel;
  };

  const btnTooltip = tooltipMessage();
  return (
    <div className={time_filter_ft_wrapper}>
      <button
        className={classNames(time_filter_ft_btn, { open: isOpenFilter })}
        onClick={toggleHandler}
        ref={refBtnFilter}
        type="button"
      >
        <div className={time_filter_ft_label}>{btnFilterLabel}</div>
        <Icon
          color="blue"
          name={isOpenFilter ? 'chevron up' : 'chevron down'}
          size="small"
        />
      </button>
      {isOpenFilter && (
        <div ref={refPopupFilter} className={time_filter_ft_dropdown}>
          <div className={time_filter_ft_dropdown_wrap}>
            {openDefaultTab === 'Changes Since' && (
              <ChangesSinceTab
                betweenDate={betweenDate}
                isCalendarActive={isCalendarActive}
                onChange={handleChange}
                setBetweenDate={setBetweenDate}
                setIsCalendarActive={setIsCalendarActive}
                tab={tab}
                value={value}
              />
            )}
          </div>
        </div>
      )}
      {!isOpenFilter && <small className={tooltip_ft}>{btnTooltip}</small>}
    </div>
  );
};

export default TimeFilterForTrends;
