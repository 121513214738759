import { isBIMetricFormula } from '../Create/utils';
import { DrilldownTableSettings } from '../contexts/RevBISettingsContext';

import {
  FORMULA_PARSER_REGEXP,
  NUMBERS_REGEXP,
} from 'components/UI/BuFormulaTextField/constants';
import {
  DataObject,
  SyntheticMetricDataWidgetState,
} from 'components/dashboard/Metrics/Widget/types';
import { UnitType } from 'components/dashboard/Metrics/enums';
import {
  BIMetricCreated,
  BIMetricFormula,
  BIWidget,
  DrillDownParams,
  FormulaMetricValuesAsChartValue,
  SelectedValue,
} from 'components/dashboard/Metrics/metrics.types';
import { OpenRevBiDrilldownModal } from './hooks/useRevBiDrilldownModal';

export const getUnits = (type: UnitType, metric?: BIMetricCreated): string => {
  const isSynthetic = metric && isBIMetricFormula(metric);

  const isUnitsExist = metric?.properties && metric?.properties.metricUnit;
  const isTypesMatches = type === metric?.properties?.metricUnit?.type;

  if (isSynthetic && isUnitsExist && isTypesMatches) {
    return metric?.properties?.metricUnit?.unit || '';
  }

  return '';
};

export const getMetricNamesWithValues = (
  metricsInFormula: string[],
  row: DataObject,
  metricDisplayNames: WidgetMetricDisplayNameMap
): FormulaMetricValuesAsChartValue[] => {
  const rowAsEntries = Object.entries(row || {});
  const nonDuplicateMetricsInFormula = Array.from(new Set(metricsInFormula));

  return nonDuplicateMetricsInFormula.map((metricInFormula) => {
    const metricInRow = rowAsEntries.find(([key]) =>
      key.includes(metricInFormula)
    );

    const metricValue = metricInRow && (metricInRow[1] as string | number);

    return {
      id: metricInFormula,
      metricName: metricDisplayNames[metricInFormula] || '',
      value: metricValue,
    };
  });
};

export const getSyntheticMetricData = (
  parsedClickedMetricFormulaAsArray: string[],
  clickedMetric: BIMetricFormula
): SyntheticMetricDataWidgetState => {
  return {
    parsedFormula: parsedClickedMetricFormulaAsArray.join(''),
    syntheticMetric: clickedMetric,
  };
};

export const openSyntheticMetricDetailsModal = (
  title: string,
  drilldownParams: DrillDownParams,
  syntheticMetricData: SyntheticMetricDataWidgetState,
  selectedValue: SelectedValue,
  openSyntheticModal: OpenRevBiDrilldownModal,
  drilldownTableSettings?: Record<string, DrilldownTableSettings>
): void => {
  // TODO: proper check of data consistency
  if (
    syntheticMetricData.parsedFormula &&
    selectedValue.metricsValues &&
    syntheticMetricData.syntheticMetric
  ) {
    const props = {
      title: title,
      formula: syntheticMetricData.syntheticMetric?.synthetic_metric || '',
      conditions: syntheticMetricData.syntheticMetric?.filters,
      valuesByMetrics: selectedValue.metricsValues,
      drilldownParams: drilldownParams,
      metric: syntheticMetricData.syntheticMetric,
      selectedValue,
      drilldownTableSettings,
    };

    openSyntheticModal({
      scheme: '/revbi-formula-modal',
      props,
    });
  }
};

export function parseFormulaToMetricsIdArray(formula: string): string[] {
  const result = [];
  let match;
  while ((match = FORMULA_PARSER_REGEXP.exec(formula)) !== null) {
    let isMetric = match[0].length > 1 && !NUMBERS_REGEXP.test(match[0]);

    if (isMetric) {
      const metricId = match[0].replace(new RegExp(/[{}]/gm), '');
      result.push(metricId);
    }
  }

  return result;
}

export type WidgetMetricDisplayNameMap = Record<string, string>;

export const getWidgetMetricDisplayNameMap = (
  widget: BIWidget
): WidgetMetricDisplayNameMap =>
  Object.fromEntries(
    Object.entries(widget.advanced_configurations?.display?.metrics || {})
      .filter(([_, value]) => !!value.display_name_override)
      .map(([key, value]) => [key, value.display_name_override!])
  );
