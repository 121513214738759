import { css } from 'emotion';

import { fontSubtitle, checkedIcon } from 'assets/css/common';

export const wrapper = css`
  border-radius: 2px;
  margin-bottom: 20px;
  border: 1px solid var(--bu-table-border-color);
  background-color: var(--bu-white);
  box-shadow: 0px 1px 3px 0px #00000026;
`;

export const header = css`
  align-items: center;
  border-bottom: 1px solid var(--bu-gray-300);
  display: flex;
  justify-content: flex-start;
  padding: 14px 30px;
`;

export const header_title = css`
  font-size: 14px;
  ${fontSubtitle};
`;

export const dropdownContainer = css`
  &.ui.dropdown {
    margin: 0 5px;
    padding: 0;

    .menu {
      overflow: auto;
      max-height: 300px;
    }
  }

  &.ui.inline.dropdown {
    > .text {
      font-weight: normal;
      color: var(--bu-primary-500);
    }

    > .dropdown.icon {
      padding: 0;
    }
  }
`;

export const widgetActions = css`
  height: 50px;

  border-bottom: 1px solid #ccc;

  background-color: #fcfcfc;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const checkbox_custom = css`
  .ui.checkbox {
    margin-right: 7px;
    margin-top: 8px;

    label:before {
      border-radius: 2px;
      width: 14px;
      height: 14px;
    }

    input:checked ~ label:before {
      background-color: var(--bu-primary-500) !important;
      border-color: var(--bu-primary-500) !important;
    }

    input:checked ~ label:after {
      content: '' !important;
      top: 3px;
      left: 3px;
      width: 8px !important;
      height: 8px !important;

      ${checkedIcon}
    }
  }
`;

export const resetMargins = css`
  .ui.form &.inline.fields {
    margin: 0;

    & .field {
      padding-right: 35px;
    }
  }
`;

export const fieldSpacing = css`
  .ui.form &.inline.fields .field {
    padding-right: 35px;
  }
`;

export const dot = css`
  &:before {
    content: '';
    width: 12px;
    height: 12px;
    display: inline-block;
    border-radius: 6px;
    margin-right: 6px;
  }
  &.pipeline_created_value:before {
    background-color: #6fa3ff;
  }
  &.pipeline_creation_target:before {
    background-color: #6236ff;
  }
`;

export const widgetTable = css`
  display: flex;

  .grid-column {
    flex: 1;
    border-right: 1px solid #ccc;

    > .grid-cell {
      display: flex;
      justify-content: left;
      align-items: center;
      padding: 0 25px;
      font-size: 13px;
      height: 35px;

      &.grid-header {
        justify-content: center;
        border-top: 1px solid #ccc;
        background-color: #f4f7f8;
        font-family: var(--bu-font-semi-bold);
        font-size: 14px;
        position: relative;
      }
    }
  }
`;

export const mainColumn = css`
  &.grid-column {
    width: 220px;
    flex: none !important;
  }
`;

export const currentQuarterLabel = css`
  position: absolute;
  left: 25px;

  &.ui.label {
    margin-right: 10px;

    &.blue {
      background-color: #0096bc !important;
    }
  }
`;
