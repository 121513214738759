import React, { Dispatch, SetStateAction } from 'react';

import { FunnelWidget } from 'components/dashboard/Metrics/Widget/FunnelWidget/FunnelWidget';
import { HistoricalWidgetDashboard } from 'components/dashboard/Metrics/Widget/Historical/HistoricalWidgetDashboard';
import { MetricsWidgetDashboard } from 'components/dashboard/Metrics/Widget/MetricsWidgetDashboard';
import { ReportWidget } from 'components/dashboard/Metrics/Widget/Report/ReportWidget';
import { AnalysisType } from 'components/dashboard/Metrics/constants';
import {
  BIDashboardSettings,
  BIWidget,
} from 'components/dashboard/Metrics/metrics.types';

interface Props {
  isReadOnly?: boolean;
  isDashboardModal?: boolean;
  // Remove showFilters when [REV-2444] is done
  // Remove prop from all components and interfaces
  showFilters?: boolean;
  dashboardId?: string;
  dashboardName?: string;
  dashboardSettings?: BIDashboardSettings;
  widget: BIWidget;
  onCloneWidget?: (widgetId: string) => void;
  onRemoveWidget?: (widgetId: string) => void;
  onEditWidget?: (widgetId: string) => void;
  setWidgetToShow: Dispatch<SetStateAction<BIWidget>>;
  onNameClicked?: (widget: Partial<BIWidget>) => void;
  onCloseWidgetModal?: () => void;
}

export const DashboardWidget: React.FC<Props> = ({
  isReadOnly = false,
  isDashboardModal = false,
  showFilters = false,
  dashboardId = '',
  dashboardName = '',
  dashboardSettings,
  widget,
  onCloneWidget,
  onRemoveWidget,
  onEditWidget,
  setWidgetToShow,
  onNameClicked,
  onCloseWidgetModal,
}) => {
  const handleTitleClicked = (): void => {
    onNameClicked && onNameClicked(widget);
  };

  const handleWidgetChange = (changes: Partial<BIWidget>) => {
    setWidgetToShow((prev) => ({
      ...prev,
      ...changes,
    }));
  };

  return (
    <>
      {widget.analysis_type === AnalysisType.LIVE && (
        <MetricsWidgetDashboard
          key={widget._id}
          isDashboardModal={isDashboardModal}
          isReadOnly={isReadOnly}
          dashboardId={dashboardId}
          dashboardName={dashboardName}
          dashboardSettings={dashboardSettings}
          widget={widget}
          showFilters={showFilters}
          setWidget={setWidgetToShow}
          onCloneWidget={onCloneWidget}
          onEditWidget={onEditWidget}
          onRemoveWidget={onRemoveWidget}
          onTitleClicked={handleTitleClicked}
          onCloseWidgetModal={onCloseWidgetModal}
        />
      )}

      {widget.analysis_type === AnalysisType.HISTORICAL && (
        <HistoricalWidgetDashboard
          key={widget._id}
          isDashboardModal={isDashboardModal}
          isReadOnly={isReadOnly}
          dashboardId={dashboardId}
          dashboardName={dashboardName}
          dashboardSettings={dashboardSettings}
          widget={widget}
          setWidget={setWidgetToShow}
          onCloneWidget={onCloneWidget}
          onEditWidget={onEditWidget}
          onRemoveWidget={onRemoveWidget}
          onTitleClicked={handleTitleClicked}
          onCloseWidgetModal={onCloseWidgetModal}
        />
      )}

      {widget.analysis_type === AnalysisType.REPORT && (
        <ReportWidget
          key={widget._id}
          isDashboardWidget
          isControlsShown={isDashboardModal}
          isDashboardModal={isDashboardModal}
          dashboardName={dashboardName}
          dashboardSettings={dashboardSettings}
          widget={widget}
          onEditWidget={onEditWidget}
          onChangeWidget={handleWidgetChange}
          onCloneWidget={onCloneWidget}
          onRemoveWidget={onRemoveWidget}
          onTitleClicked={handleTitleClicked}
          onCloseWidgetModal={onCloseWidgetModal}
        />
      )}

      {widget.analysis_type === AnalysisType.FUNNEL && (
        <FunnelWidget
          key={widget._id}
          isControlsShown={isDashboardModal}
          isDashboardWidget
          isDashboardModal={isDashboardModal}
          dashboardName={dashboardName}
          dashboardSettings={dashboardSettings}
          widget={widget}
          onCloneWidget={onCloneWidget}
          onEditWidget={onEditWidget}
          onRemoveWidget={onRemoveWidget}
          onTitleClicked={handleTitleClicked}
          onCloseWidgetModal={onCloseWidgetModal}
          onChangeWidget={handleWidgetChange}
        />
      )}
    </>
  );
};
