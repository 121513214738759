import classNames from 'classnames';
import { css } from 'emotion';
import { PointOptionsObject, SeriesScatterOptions } from 'highcharts';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { Dropdown, Grid, Popup } from 'semantic-ui-react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import MindtickleCellIcon from 'assets/fonts/boostup-icons/mindtickle_call_icon.svg';
import PlayCallIcon from 'assets/fonts/boostup-icons/play.svg';
import PlayCallIconActive from 'assets/images/new_icon/play_call_audio_active.svg';
import { shouldUpdateCache } from 'common/utils';
import BuGroupButton from 'components/UI/BuGroupButton';
import BuIcon from 'components/UI/BuIcon';
import EngagementChart from 'components/UI/ProspectEngagement/EngagementChart';
import { DictionaryPoint } from 'components/UI/ProspectEngagement/EngagementChart/types';
import StageHistoryChart from 'components/UI/ProspectEngagement/StageHistoryChart';
import * as styles from 'components/UI/ProspectEngagement/styles';
import {
  Call,
  CRMActivity,
  Email,
  Event,
  IProps,
  IStateProps2,
  NextStep,
  RepEmails,
} from 'components/UI/ProspectEngagement/types';
import { openModal, redirect } from 'navigation/utils';
import { fetchApi } from 'utils/network';

const crmActivityColors = [
  'rgb(100,202,230, 0.8)',
  'rgb(235,133,163, 0.8)',
  'rgb(97,227,125, 0.8)',
  'rgb(125,132,170, 0.8)',
  'rgb(194,142,215, 0.8)',
  'rgb(205,173,113, 0.8)',
];

const tooltipContainer = css`
  display: flex;
  flex-direction: column;
  max-height: 100px;
  height: 100%;
  max-width: 600px;
  width: 100%;
  z-index: 3;
`;

const descriptionStyle = css`
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const emailDropdownOptions = [
  { text: 'All Emails', value: false },
  { text: 'Important Emails', value: true },
];

const timespanToSubtract: { [key: string]: (string | number)[] } = {
  L7D: [7, 'days'],
  L14D: [14, 'days'],
  L30D: [30, 'days'],
  L3M: [3, 'months'],
  L6M: [6, 'months'],
};

const getCallIcon = (
  source: string,
  eventType?: 'mouseOut' | 'mouseOver'
): string => {
  if (source && source === 'Mindtickle') {
    return MindtickleCellIcon;
  } else {
    if (eventType === 'mouseOver') {
      return PlayCallIconActive;
    } else {
      return PlayCallIcon;
    }
  }
};

class ProspectEngagement extends React.PureComponent<IProps, IStateProps2> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      activePopup: null,
      isCallsActive: true,
      isEventsActive: true,
      isNextStepsActive: true,
      isProspectEmailsActive: true,
      isRepEmailsActive: true,
      isSfdcActivitiesActive: true,
      onlyImportant: false,
      selectedRole: 'All',
      selectedCrmActivity: 'All',
      timespanState: props.timespan,
    };
  }

  componentDidMount() {
    const { fetch } = this.props;
    const { timespanState } = this.state;
    // 2020-01-06 (Amit) : Disable caching until we implement correct caching

    if (fetch !== undefined && fetch !== null) {
      fetch(timespanState);
    }
  }

  componentDidUpdate(prevProps: IProps) {
    const { fetch, cacheTimestamp } = this.props;

    if (
      prevProps.cacheTimestamp !== cacheTimestamp ||
      prevProps.title !== this.props.title ||
      prevProps.accountId !== this.props.accountId
    ) {
      if (shouldUpdateCache(cacheTimestamp)) {
        fetch();
      }
    }
  }

  isAllActive = () => {
    const {
      isCallsActive,
      isEventsActive,
      isProspectEmailsActive,
      isRepEmailsActive,
      isNextStepsActive,
      isSfdcActivitiesActive,
    } = this.state;
    return (
      isCallsActive &&
      isEventsActive &&
      isProspectEmailsActive &&
      isRepEmailsActive &&
      isNextStepsActive &&
      isSfdcActivitiesActive
    );
  };

  getRepEmailsByRole = (
    role: keyof RepEmails | 'All',
    emails: RepEmails
  ): Email[] => {
    if (role === 'All') {
      return Object.values(emails).flat();
    }
    return emails[role];
  };

  onTimespanChange(value: string) {
    const { fetch } = this.props;
    const { onlyImportant } = this.state;
    fetch(value, onlyImportant);
    this.setState((prevState) => ({ ...prevState, timespanState: value }));
  }

  roleToggle(role: any) {
    this.setState((prevState) => ({ ...prevState, selectedRole: role }));
  }

  onlyImportantToggle(value: any) {
    const { fetch } = this.props;
    const { timespanState } = this.state;
    fetch(timespanState, value);
    this.setState((prevState) => ({ ...prevState, onlyImportant: value }));
  }

  getRoleDropdownOptions = () => {
    const { repEmails } = this.props;
    const roles = [{ text: 'All', value: 'All' }];
    Object.keys(repEmails).map((r) => roles.push({ text: r, value: r }));

    return roles;
  };

  isVisibleGraph = () => {
    const {
      isCallsActive,
      isEventsActive,
      isProspectEmailsActive,
      isRepEmailsActive,
      isNextStepsActive,
    } = this.state;

    if (
      isCallsActive &&
      isEventsActive &&
      isProspectEmailsActive &&
      isRepEmailsActive &&
      isNextStepsActive
    ) {
      return 'all';
    }
    if (
      !isCallsActive &&
      !isEventsActive &&
      isProspectEmailsActive &&
      !isRepEmailsActive &&
      !isNextStepsActive
    ) {
      return 'Prospect emails';
    }
    if (
      !isCallsActive &&
      !isEventsActive &&
      !isProspectEmailsActive &&
      isRepEmailsActive &&
      !isNextStepsActive
    ) {
      return 'Company emails';
    }
    if (
      !isCallsActive &&
      !isEventsActive &&
      !isProspectEmailsActive &&
      !isRepEmailsActive &&
      isNextStepsActive
    ) {
      return 'Next Steps';
    }
    return null;
  };

  rangeDate = (
    timespan: 'L7D' | 'L14D' | 'L30D' | 'L3M' | 'L6M' | 'ALT' | string
  ) => {
    const {
      events,
      calls,
      customerEmails,
      nextSteps,
      repEmails,
      sfdcActivities,
      stageHistory,
      frozenTime,
    } = this.props;
    const { selectedRole } = this.state;

    const dateFrozenTime = moment(frozenTime).valueOf();

    const repEmailsByRole: Email[] = Array.isArray(repEmails)
      ? repEmails
      : this.getRepEmailsByRole(selectedRole, repEmails);

    const dates = [
      ...events.map((item) => item.start),
      ...calls.map((item) => item.start),
      ...customerEmails.map((item) => item.received_at),
      ...repEmailsByRole.map((item) => item.received_at),
      ...nextSteps.map((item) => item.created_at),
      ...stageHistory.map((item) => item.created_at),
      ...sfdcActivities
        .filter((activityGroup) => activityGroup.activities)
        .map((activityGroup) => activityGroup.activities)
        .flat()
        .filter((activity) => activity?.activity_date)
        .map((activity) => activity?.activity_date),
    ].map((item) => moment(item).valueOf());

    const min = dates.length
      ? moment(Math.min(...dates))
          .startOf('day')
          .subtract(1, 'day')
          .valueOf()
      : undefined;

    const max = dates.length
      ? moment(Math.max(...dates))
          .startOf('day')
          .add(1, 'day')
          .valueOf()
      : undefined;

    const timeSpanInterval = {
      min,
      max: dateFrozenTime
        ? dateFrozenTime
        : timespan === 'ALT'
        ? max
        : moment().valueOf(),
    };

    const subtract = timespanToSubtract[timespan];

    return subtract
      ? {
          ...timeSpanInterval,
          min: dateFrozenTime
            ? moment(dateFrozenTime)
                .subtract(...subtract)
                .valueOf()
            : moment()
                .subtract(...subtract)
                .valueOf(),
        }
      : timeSpanInterval;
  };

  handleOver = ({ target, type }: React.SyntheticEvent<EventTarget>) => {
    if (target instanceof HTMLSpanElement) {
      const { clientWidth, id, scrollWidth } = target;

      if (clientWidth < scrollWidth) {
        this.setState({
          activePopup: type === 'mouseenter' ? id : null,
        });
      }
    }
  };

  renderSpanWithPopup = (id: string, text: string) => (
    <Popup
      content={<span>{text}</span>}
      open={this.state.activePopup === id}
      position="bottom center"
      trigger={
        <span
          className={styles.ellipsis}
          id={id}
          onMouseEnter={this.handleOver}
          onMouseLeave={this.handleOver}
        >
          {text}
        </span>
      }
    />
  );

  handleClickActivityPoint(activity: CRMActivity) {
    const { dealId, accountId } = this.props;

    openModal({
      scheme: '/activity/:activityId',
      params: { activityId: `${dealId || accountId}_${activity.record_id}` },
    });
  }

  handleClickNextStepPoint(nextStep: NextStep) {
    openModal({
      scheme: '/next-step/:nextStepId',
      params: { nextStepId: nextStep.id },
    });
  }

  handleClickEmailPoint(email: Email) {
    openModal({
      scheme: '/email/:emailId',
      params: { emailId: email.id },
    });
  }

  handleClickEventPoint(event: Event | Call) {
    if (event.is_call) {
      redirect('/transcript/:id', { id: event.id });
    } else {
      openModal({
        scheme: '/event/:eventId',
        params: { eventId: event.id },
      });
    }
  }

  redirectToUrl(event: Event | Call) {
    if (event.source !== 'BoostUp' && event.recording_url) {
      const { dealId, accountId, persistModalParams } = this.props;

      fetchApi({
        url: `${process.env.REACT_APP_BACKEND_URL}/api/data/events/call_ai/${event.id}`,
        queryMethod: 'get',
        setData(response) {
          if (response) {
            const activity = (response as any).activities[0];

            openModal({
              scheme: '/activity/:activityId',
              params: {
                activityId: `${dealId || accountId}_${activity.record_id}`,
                entityId: event.id,
              },
              persistParams: {
                activity: response,
              },
              persistor: persistModalParams,
            });
          }
        },
        setError() {
          const win = window.open(event.recording_url, '_blank');
          win?.focus();
          return null;
        },
      });
    } else {
      this.handleClickEventPoint(event);
    }
  }

  tooltipFormatter<T>(
    dateFieldName: keyof T,
    textFieldName: keyof T,
    headerFieldName?: keyof T,
    extraData?: ((item: T) => React.ReactNode) | null
  ) {
    return function (item: T) {
      const originalDescription = `${item[textFieldName]}`;
      const elipsis = `${originalDescription}`.length > 70 ? '...' : '';
      const description = `${originalDescription.substr(0, 70)}${elipsis}`;
      return renderToStaticMarkup(
        <div className={tooltipContainer}>
          {headerFieldName && <b>{item[headerFieldName]}</b>}
          <div className={descriptionStyle}>{description}</div>

          <span>
            {moment(item[dateFieldName] as moment.MomentInput)
              .local()
              .format('Do MMMM YYYY, h:mm a')}
          </span>
          {extraData ? extraData(item) : null}
        </div>
      );
    };
  }

  mapListToChartSeries<T, T2 extends keyof T>(
    list: T[],
    fieldName: T2
  ): Partial<SeriesScatterOptions> {
    return {
      data: list.map<PointOptionsObject>((item) => {
        const source = (item as any)?.source;

        return {
          x: moment(item[fieldName] as moment.MomentInput).valueOf(),
          y: 0,
          custom: {
            item: item,
          } as DictionaryPoint<T>,
          marker: {
            symbol: source ? `url(${getCallIcon(source)})` : 'circle',
          },
        };
      }),
    };
  }

  mapCallListToChartSeries<T, T2 extends keyof T>(
    list: T[],
    fieldName: T2
  ): Partial<SeriesScatterOptions> {
    return {
      ...this.mapListToChartSeries(list, fieldName),
      point: {
        events: {
          mouseOver({ target }) {
            const source = (target as any)?.custom?.item?.source;

            this.update({
              marker: {
                symbol: `url(${getCallIcon(source, 'mouseOver')})`,
              },
            });
          },
          mouseOut({ target }) {
            const source = (target as any)?.custom?.item?.source;

            this.update({
              marker: {
                symbol: `url(${getCallIcon(source, 'mouseOut')})`,
              },
            });
          },
        },
      },
    };
  }

  render() {
    const {
      status,
      title,
      events,
      calls,
      customerEmails,
      nextSteps,
      sfdcActivities,
      repEmails,
      dealId,
      stageHistory,
      persistModalParams,
    } = this.props;

    const { onlyImportant, selectedRole, timespanState } = this.state;

    const roleDropdownOptions = this.getRoleDropdownOptions();
    const visibleGraph = this.isVisibleGraph();

    const buttonsTimespan = [
      { label: '7d', value: 'L7D' },
      { label: '14d', value: 'L14D' },
      { label: '30d', value: 'L30D' },
      { label: 'Last 3m', value: 'L3M' },
      { label: 'Last 6m', value: 'L6M' },
      { label: 'All time', value: 'ALT' },
    ];

    const { min = 0, max = 0 } = this.rangeDate(timespanState);

    const companyEmails = this.getRepEmailsByRole(selectedRole, repEmails);

    const tooltipExtraByGroupType = (groupType: string) =>
      groupType === 'Events'
        ? (item: CRMActivity) =>
            item.additional_fields.Type && (
              <span>Type: {item.additional_fields.Type}</span>
            )
        : null;

    return (
      <div className={styles.graphics}>
        <div className={styles.header_prospect}>
          <p className="deal-body__list_item-header-text bu-font-heading">
            {title}
          </p>
          <div className={styles.headerActionsWrapper}>
            <div className={styles.filtersContainer}>
              <BuGroupButton
                options={buttonsTimespan.map((option) => ({
                  id: option.value,
                  text: option.label,
                }))}
                selectedOption={timespanState}
                onSelect={(id) => this.onTimespanChange(id)}
                useRevampStyle
              />
            </div>
            <Dropdown
              id="deal-body-email-dropdown"
              className={classNames(styles.dropdown, styles.emailDropdown)}
              value={onlyImportant}
              options={emailDropdownOptions}
              onChange={(e, { value }) => this.onlyImportantToggle(value)}
              simple
              item
            />
          </div>
        </div>

        <Grid className={styles.overflow} padded="horizontally">
          {visibleGraph !== 'Prospect emails' &&
          visibleGraph !== 'Company emails' ? (
            <EngagementChart<Event | Call>
              title="Meetings"
              icon={
                <BuIcon
                  color="var(--bu-color-meeting)"
                  name={BoostUpIcons.MeetingTimelineIcon}
                />
              }
              series={[
                this.mapListToChartSeries(events, 'start'),
                this.mapCallListToChartSeries(calls, 'start'),
              ]}
              count={events.length}
              min={min}
              max={max}
              color="var(--bu-color-meeting)"
              tooltipFormatter={this.tooltipFormatter<Event | Call>(
                'start',
                'summary'
              )}
              onPointClick={this.redirectToUrl.bind(this)}
            />
          ) : null}

          {visibleGraph === 'Prospect emails' || visibleGraph === 'all' ? (
            <EngagementChart<Email>
              title="Buying Team Emails"
              icon={<BuIcon name={BoostUpIcons.TimelineBuyingTeamEmail} />}
              series={[
                this.mapListToChartSeries(customerEmails || [], 'received_at'),
              ]}
              count={customerEmails.length}
              min={min}
              max={max}
              color="var(--bu-color-buying_team_email)"
              tooltipFormatter={this.tooltipFormatter<Email>(
                'received_at',
                'subject',
                'sender'
              )}
              onPointClick={this.handleClickEmailPoint.bind(this)}
            />
          ) : null}

          {visibleGraph === 'Company emails' || visibleGraph === 'all' ? (
            <EngagementChart<Email>
              title="Selling Team Emails"
              icon={<BuIcon name={BoostUpIcons.TimelineSellingTeamEmail} />}
              series={[this.mapListToChartSeries(companyEmails, 'received_at')]}
              count={companyEmails.length}
              min={min}
              max={max}
              filterValue={selectedRole}
              filterOptions={roleDropdownOptions}
              onFilterChange={({ value }) => this.roleToggle(value)}
              color="var(--bu-color-selling_team_email)"
              tooltipFormatter={this.tooltipFormatter<Email>(
                'received_at',
                'subject',
                'sender'
              )}
              onPointClick={this.handleClickEmailPoint.bind(this)}
            />
          ) : null}

          {dealId &&
          (visibleGraph === 'Next Steps' || visibleGraph === 'all') ? (
            <EngagementChart<NextStep>
              title="Next Steps"
              icon={<BuIcon name={BoostUpIcons.TimelineNextSteps} />}
              series={[this.mapListToChartSeries(nextSteps, 'created_at')]}
              count={nextSteps.length}
              min={min}
              max={max}
              color="var(--bu-color-next_steps)"
              tooltipFormatter={this.tooltipFormatter<NextStep>(
                'created_at',
                'next_step'
              )}
              onPointClick={this.handleClickNextStepPoint.bind(this)}
            />
          ) : null}

          {sfdcActivities.map((group, index) => (
            <EngagementChart<CRMActivity>
              key={group.label}
              title={group.display_name}
              icon={
                <BuIcon
                  name={BoostUpIcons.TimelineCrmActivity}
                  className={css`
                    color: ${crmActivityColors[
                      index % crmActivityColors.length
                    ]};
                  `}
                />
              }
              series={[
                this.mapListToChartSeries(
                  group.activities || [],
                  'activity_date'
                ),
              ]}
              count={group.activities?.length}
              min={min}
              max={max}
              color={crmActivityColors[index % crmActivityColors.length]}
              tooltipFormatter={this.tooltipFormatter<CRMActivity>(
                'activity_date',
                'subject',
                'type_display_name',
                tooltipExtraByGroupType(group.display_name)
              )}
              onPointClick={this.handleClickActivityPoint.bind(this)}
            />
          ))}

          {!isEmpty(stageHistory) ? (
            <StageHistoryChart stages={stageHistory} min={min} max={max} />
          ) : (
            <div style={{ height: 15 }} />
          )}
        </Grid>
      </div>
    );
  }
}

export default ProspectEngagement;
