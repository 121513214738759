import styled from '@emotion/styled';
import * as R from 'ramda';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Loader } from 'semantic-ui-react';

import { DownloadButtonProps } from 'components/UI/DownloadButton/types';
import Table from 'components/UI/TableConfig/Table';
import {
  columnTypes,
  ColumnTypesCallback,
  handleColumnClick,
} from 'components/UI/TableConfig/column-helper';
import {
  isColumnConfigClickable,
  TableConfigurationData,
} from 'components/UI/TableConfig/types';
import {
  IColumn,
  IRow,
  SortOrder,
  ValueProp,
} from 'components/UI/common/TypedTable/TypedTable';
import { currencyFormatter } from 'components/UI/common/TypedTable/formatters';
import { ColumnTypes } from 'components/UI/common/TypedTable/renderers';
import { isMetricFiltersValid } from 'components/dashboard/Metrics/Create/utils';
import { metricObjectToPlural } from 'components/dashboard/Metrics/Widget/Report/helpers';
import useTableSort from 'components/dashboard/Metrics/Widget/Report/useTableSort';
import { LoaderContainer } from 'components/dashboard/Metrics/Widget/widgets.styles';
import { ACCOUNT, OPPORTUNITY } from 'components/dashboard/Metrics/constants';
import { RevBISettingsContext } from 'components/dashboard/Metrics/contexts/RevBISettingsContext';
import { parseFilters } from 'components/dashboard/Metrics/metrics.helpers';
import {
  BIColumnListItem,
  BIDashboardSettings,
  BIMetricsQueryFilter,
  BIWidget,
  ExternalColumnFieldFilter,
} from 'components/dashboard/Metrics/metrics.types';
import useTableSearch from 'components/hooks/useTableSearch';
import { openModal } from 'navigation/utils';
import * as selectors from 'selectors';
import { fetchApi, QueryStatus } from 'utils/network';

const TableContainer = styled.div({
  overflow: 'auto',
  paddingTop: '10px',
});

type DealsApiResponse = {
  data: {
    count: number;
    deals: Deals.Deal[];
    total_amount: number;
  };
  accounts: IRow[];
  total_count: number;
};

interface Props {
  dashboardSettings?: BIDashboardSettings;
  widget: Partial<BIWidget>;
  onChangeWidget: (widget: Partial<BIWidget>) => void;
}

export const ReportTable: React.FC<Props> = ({
  dashboardSettings,
  widget,
  onChangeWidget,
}) => {
  const { currency: companyCurrencyCode } = useContext(RevBISettingsContext);

  const isMultiCurrencyEnabled = useSelector(selectors.isMulticurrencyEnabled);

  const [dataStatus, setDataStatus] = useState<QueryStatus>('notAsked');
  const [dataIds, setDataIds] = useState<string[]>([]);
  const [columns, setColumns] = useState<IColumn[]>([]);
  const [rows, setRows] = useState<IRow[]>([]);
  const [filteredRows, setFilteredRows] = useState<IRow[]>([]);

  const [columnList, setColumnList] = useState<BIColumnListItem[]>([]);
  const [columnFetchStatus, setColumnFetchStatus] =
    useState<QueryStatus>('notAsked');

  const [abortController, setAbortController] = useState(new AbortController());

  const [sortByField, setSortByField, sortBy] = useTableSort<IRow>(columns);
  const [, setSearchText, searchFilter] = useTableSearch<IRow>([
    ['opportunity_name'],
    ['account_name'],
  ]);

  const metricObject = widget.metric_list?.[0]?.object || 'opportunity';

  const metricFilters: BIMetricsQueryFilter[] =
    widget.metric_list?.[0]?.filters ?? [];

  const isMetricObjectEqualToOpportunity = metricObject === OPPORTUNITY;

  const handleOpportunitiesTableSpecialClick: ColumnTypesCallback = (props) => {
    if (props.column.type !== ColumnTypes.SALES_PROCESS) {
      handleColumnClick(props);
    }
  };

  const handleAccountsTableSpecialClick: ColumnTypesCallback = ({
    column,
    columnConfig,
    objectId,
  }) => {
    if (column.type === ColumnTypes.TEXT) {
      if (isColumnConfigClickable(columnConfig)) {
        switch (columnConfig.meta.object_id) {
          case 'account_id':
          case '_id':
            openModal({
              scheme: '/account/:id',
              params: {
                id: objectId,
              },
            });
            break;
          default:
            console.warn('Action not supported');
        }
      } else {
        console.warn('Action not supported');
      }
    } else if (column.type === ColumnTypes.SCORE) {
      openModal({
        scheme: '/account/:id',
        params: {
          id: objectId,
        },
      });
    }
  };

  const getColumnType = columnTypes(
    {
      currencyFormatter: currencyFormatter(
        companyCurrencyCode,
        0,
        isMultiCurrencyEnabled
      ),
      sequence: [],
    },
    isMetricObjectEqualToOpportunity
      ? handleOpportunitiesTableSpecialClick
      : handleAccountsTableSpecialClick,
    '',
    isMultiCurrencyEnabled
  );

  // Fetch column fields filters
  useEffect(() => {
    const abortController = new AbortController();

    fetchApi<ExternalColumnFieldFilter, BIColumnListItem[]>({
      url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/external/get_column_fields_filter`,
      queryMethod: 'post',
      queryParams: {
        tables: [metricObject],
      },
      setData: (fields: BIColumnListItem[]) => {
        setColumnList(parseFilters(fields));
      },
      setError: (error: string | null) => {
        setColumnList([]);
        toast.error(`Failed to load column fields filters: ${error}`);
      },
      setStatus: setColumnFetchStatus,
      signal: abortController.signal,
    });

    return () => {
      setColumnList([]);
      abortController.abort();
    };
  }, [metricObject]);

  // Fetch table columns
  useEffect(() => {
    const objectApiPath = metricObject
      ? isMetricObjectEqualToOpportunity
        ? 'opportunity/Opportunities'
        : 'account/Accounts'
      : '';

    if (objectApiPath && columnFetchStatus === 'success') {
      fetchApi<void, { data: TableConfigurationData }>({
        url: `${process.env.REACT_APP_BACKEND_URL}/api/settings/tables/${objectApiPath}`,
        queryMethod: 'get',
        setData: (result) => {
          const data = result ?? {};
          const cols = data.data.columns;

          if (!widget?.order_by_column?.name) {
            const defaultOrder = data.data.order;
            const column = columnList?.find(
              (c) => c.name === defaultOrder.object_field
            );

            if (column) {
              onChangeWidget({ order_by_column: column });
            }
          }

          const newColumns =
            cols
              ?.map<IColumn>((column, index) => ({
                field: column.object_field,
                sort_order: SortOrder.DESCENDING,
                sortable: column.sortable,
                id: `${column.field_name}-${index}`,
                label: column.display_name,
                ...getColumnType(column, cols),
                editable: false,
              }))
              .map((column) => ({
                ...column,
                showTooltip: ![
                  ColumnTypes.NUMBER,
                  ColumnTypes.PERCENT,
                  ColumnTypes.MONEY,
                ].includes(column.type),
              })) || [];

          setColumns(newColumns);
        },
        setError: (_: string | null) => {
          toast.error('Failed to load table columns');
          setDataStatus('error');
        },
      });
    }
  }, [metricObject, columnFetchStatus]);

  // Fetch table data
  useEffect(() => {
    const queryParams = {
      filters: metricFilters ?? [],
      widget_filters: widget.widget_filters ?? [],
      template_filters: widget.template_filters ?? [],
      order_by: widget.order_by_column?.name
        ? [
            {
              asc: widget.order_by?.[0] === 'ascending',
              column: widget.order_by_column,
            },
          ]
        : [],
      limit: widget?.limit ?? 10,
      offset: 0,
    };

    if (
      queryParams.order_by?.[0]?.column?.name &&
      queryParams.limit > 0 &&
      queryParams.limit < 101 &&
      (!queryParams.filters.length ||
        isMetricFiltersValid(widget.metric_list?.[0]))
    ) {
      setDataStatus('loading');
      let abort = abortController;
      if (dataStatus === 'loading') {
        abort.abort();
        abort = new AbortController();
        setAbortController(abort);
      }
      //this is the first dashboard setting, but in case we add one more, we should rethink this.
      //?user_status=active
      let querySetting = '';
      if (dashboardSettings) {
        querySetting = `?user_status=${dashboardSettings.userFilter}`;
      }
      fetchApi<any, Array<string>>({
        url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/drill_down/${metricObject}${querySetting}`,
        queryMethod: 'post',
        queryParams: queryParams,
        setData: (result) => {
          const data = result ?? [];
          setDataIds(data);

          const dataApiPath = metricObject
            ? isMetricObjectEqualToOpportunity
              ? 'deals'
              : 'accounts'
            : '';

          if (data.length && dataApiPath) {
            const apiDataParams = {
              ids: data,
              page_number: queryParams.offset,
              page_size: queryParams.limit,
              ...(isMetricObjectEqualToOpportunity && {
                skip_business_validation: true,
              }),
            };

            fetchApi<any, DealsApiResponse>({
              url: `${process.env.REACT_APP_BACKEND_URL}/api/data/${dataApiPath}/`,
              queryMethod: 'post',
              queryParams: apiDataParams,
              setData: (result) => {
                const data = result ?? {};

                let parsedDeals: IRow[] = [];
                if (isMetricObjectEqualToOpportunity) {
                  parsedDeals = data.data?.deals.map((deal) => ({
                    ...deal,
                    id: `${deal._id}`,
                    crm_metadata:
                      deal.crm_metadata && deal.crm_metadata.next_step === null
                        ? { ...deal.crm_metadata, next_step: '' }
                        : deal.crm_metadata,
                  }));
                }

                if (metricObject === ACCOUNT) {
                  parsedDeals = data.accounts?.map((account) => ({
                    ...account,
                    id: `${account._id}`,
                  }));
                }

                setRows(parsedDeals);
                if (!filteredRows.length) {
                  setFilteredRows(parsedDeals);
                }

                setDataStatus('success');
              },
              setError: (_: string | null) => {
                toast.error('Failed to load table data');
                setDataStatus('error');
              },
              signal: abort.signal,
            });
          } else {
            setRows([]);
            setFilteredRows([]);
            setDataStatus('success');
          }
        },
        setError: (_: string | null) => {
          toast.error('Failed to load table data');
          setDataStatus('error');
        },
        signal: abort.signal,
      });
    }
  }, [
    JSON.stringify(metricFilters),
    widget?.limit,
    widget?.order_by_column,
    widget?.order_by,
    JSON.stringify(widget?.template_filters),
    JSON.stringify(dashboardSettings),
  ]);

  useEffect(() => {
    if (rows.length) {
      setFilteredRows(sortBy(rows.filter(searchFilter)));
    }
  }, [rows, sortBy, searchFilter]);

  useEffect(() => {
    const splitName = widget?.order_by_column?.name?.split('.')?.[1];
    const orderedColumn = columns?.find((c) =>
      c.field?.includes(splitName as string)
    );

    if (orderedColumn) {
      const newSortColumn = `${
        widget?.order_by?.[0] === 'descending' ? '-' : ''
      }${orderedColumn?.field}`;

      if (sortByField !== newSortColumn) {
        setSortByField(newSortColumn);
      }
    }
  }, [dataIds]);

  const handleChange = useCallback(
    (column: IColumn, row: IRow, newValue: ValueProp) => {
      const {
        type,
        config: { multiselect },
      } = column;
      let valueUpdated = newValue;

      if (type === 'select' && multiselect) {
        valueUpdated = (newValue as string[]).join(';');
      }

      const newRows = filteredRows.map((item) =>
        item.id === row.id
          ? R.assocPath(column.field.split('.'), newValue, item)
          : item
      );

      const updateQueryParams = {
        [column.field]: valueUpdated,
      };
      setFilteredRows(newRows);

      const UPDATE_API_URL = `${process.env.REACT_APP_BACKEND_URL}/api/data/${
        isMetricObjectEqualToOpportunity ? 'deals' : 'accounts'
      }/update/${row.id}/`;

      fetchApi<any, any>({
        url: UPDATE_API_URL,
        queryMethod: 'post',
        queryParams: updateQueryParams,
        setData: (_) => {},
        setError: (_: string | null) => {
          toast.error('Failed to update row!');
          setFilteredRows(filteredRows);
        },
      });
    },
    [filteredRows]
  );

  const downloadButton: DownloadButtonProps = useMemo(
    () => ({
      serializedQueryParams: JSON.stringify({
        page_number: 1,
        page_size: widget?.limit ?? 10,
        ids: dataIds,
      }),
      queryMethod: 'post',
      url: `${process.env.REACT_APP_BACKEND_URL}/api/data/${
        isMetricObjectEqualToOpportunity ? 'deals' : 'accounts'
      }/csv`,
    }),
    [dataIds, metricObject, widget?.limit]
  );

  return (
    <>
      {dataStatus === 'loading' && (
        <LoaderContainer>
          <Loader active content="Loading" />
        </LoaderContainer>
      )}

      {dataStatus === 'success' && (
        <TableContainer>
          <Table
            hidePaginationStart
            hidePaginationEnd
            fullscreen
            showColumnsVisibilityToggle
            title={`Showing ${metricObjectToPlural(metricObject || '')}`}
            totalCount={filteredRows.length}
            currentPage={0}
            rowsPerPage={widget?.limit ?? 10}
            downloadButton={downloadButton}
            data={filteredRows}
            columns={columns}
            sortOrder={sortByField}
            onSort={setSortByField}
            onSearchChange={setSearchText}
            onChange={handleChange}
          />
        </TableContainer>
      )}
    </>
  );
};
