import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import BuRadio from 'components/UI/BuRadio';
import BuSelect from 'components/UI/BuSelect';
import BuSkeleton from 'components/UI/BuSkeleton';
import {
  inputsContainer,
  radiosContainer,
} from 'components/dashboard/Metrics/Create/MetricDefinitionInputs/styles';
import { MetricCreateSubTitle } from 'components/dashboard/Metrics/metrics.styles';
import {
  BIColumnListItem,
  BIMetricSimple,
  BIMetricsQueryFilter,
  BIPicklistOption,
} from 'components/dashboard/Metrics/metrics.types';
import { QueryStatus, fetchApi } from 'utils/network';

interface Props {
  conditions: BIMetricsQueryFilter[];
  targetPeriod?: string;
  targetType?: string;
  setTargetInputs: Dispatch<SetStateAction<Partial<BIMetricSimple>>>;
}

export const TargetFields: React.FC<Props> = ({
  conditions,
  targetPeriod,
  targetType,
  setTargetInputs,
}) => {
  const [targetTypes, setTargetTypes] = useState<BIPicklistOption[]>([]);
  const [targetPeriods, setTargetPeriods] = useState<BIPicklistOption[]>([]);
  const [loadTargetPeriodStatus, setLoadTargetPeriodStatus] =
    useState<QueryStatus>('notAsked');

  useEffect(() => {
    const abortControllerTargetType = new AbortController();
    const abortControllerTargetPeriod = new AbortController();

    fetchApi<BIColumnListItem, BIPicklistOption[]>({
      url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/company_settings/get_column_values`,
      queryMethod: 'post',
      queryParams: {
        label: 'Target Type',
        name: 'target.type',
        type: 'text',
      },
      setData: (result) => {
        setTargetTypes(result);
      },
      setError: (error: string | null) => {
        toast.error(`Failed to load target type values: ${error}`);
      },
      signal: abortControllerTargetType.signal,
    });

    fetchApi<BIColumnListItem, BIPicklistOption[]>({
      url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/company_settings/get_column_values`,
      queryMethod: 'post',
      queryParams: {
        label: 'Target Period',
        name: 'target.period',
        type: 'text',
      },
      setData: (result) => {
        setTargetPeriods(result);
      },
      setStatus: setLoadTargetPeriodStatus,
      setError: (error: string | null) => {
        toast.error(`Failed to load target period values: ${error}`);
      },
      signal: abortControllerTargetPeriod.signal,
    });

    return () => {
      if (abortControllerTargetType) {
        abortControllerTargetType.abort();
      }

      if (abortControllerTargetPeriod) {
        abortControllerTargetPeriod.abort();
      }

      setTargetTypes([]);
      setTargetPeriods([]);
    };
  }, []);

  const handleChangeTypeOfTarget = (values: string[]): void => {
    setTargetInputs((prev) => ({
      ...prev,
      target_type: values[0],
    }));
  };

  const handleChangeTargetPeriod = (
    _: React.FormEvent<HTMLInputElement>,
    { value }: { value: string }
  ): void => {
    const hasTargetTimePeriodCondition = conditions.some(
      (f) =>
        f?.and_condition?.[0].or_condition?.[0].column?.name ===
        'target.target_date'
    );
    setTargetInputs((prev) => ({
      ...prev,
      target_period: value,
      // If metric has target date condition we need to reset it's value
      ...(hasTargetTimePeriodCondition && {
        filters: conditions.map((f) => {
          if (
            f?.and_condition?.[0].or_condition?.[0].column?.name !==
            'target.target_date'
          ) {
            return f;
          }

          return {
            ...f,
            and_condition: [
              {
                or_condition: [
                  {
                    column: f?.and_condition?.[0].or_condition?.[0].column,
                    operator: f?.and_condition?.[0].or_condition?.[0].operator,
                    value: '',
                  },
                ],
              },
            ],
          };
        }),
      }),
    }));
  };

  return (
    <>
      <div className={inputsContainer} data-testing="type-target-section">
        <MetricCreateSubTitle>Type of Target</MetricCreateSubTitle>
        <BuSelect
          fullWidth
          isLargePlaceholder
          secondary
          placeholder="Select a target type"
          defaults={[targetType ?? '']}
          options={targetTypes.map((value: BIPicklistOption) => ({
            text: value.display_name,
            value: value.value,
          }))}
          onChange={handleChangeTypeOfTarget}
          testingLabel="target-type"
        />
      </div>

      <div className={radiosContainer} data-testing="target-period-section">
        <MetricCreateSubTitle>Target Period</MetricCreateSubTitle>
        {loadTargetPeriodStatus === 'loading' && (
          <BuSkeleton height={25} width="100%" />
        )}

        {targetPeriods.map((period) => (
          <BuRadio
            key={period.value}
            label={period.display_name}
            testingLabel={period.display_name}
            checked={targetPeriod === period.value}
            value={period.value}
            onChange={handleChangeTargetPeriod}
          />
        ))}
      </div>
    </>
  );
};
