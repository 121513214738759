import { css } from 'emotion';

export const ExpandPosition = css`
  margin: auto 0;
`;
export const ExpandCollapseIcon = css`
  margin: auto 10px;
  font-size: 1.2em;
  cursor: pointer;
`;

export const ExpandableHierarchyCellWrapper = css`
  display: flex;
  align-items: baseline;
  padding-top: 10px;
  padding-bottom: 10px;
`;

export const TextWrapper = (addIconPadding: boolean) => css`
  display: flex;
  flex-direction: column;
  padding-left: ${addIconPadding ? '29px' : 0};
`;

export const ExpandRotate = css`
  display: inline-block;
  transform: rotate(45deg);
`;

export const TooltipNextPivotName = css`
  max-width: '300px';
  text-align: 'center';
`;

export const CellMainContent = (pivotHierarchyDepth: number) => css`
  padding-left: ${pivotHierarchyDepth > 1
    ? 4 + (pivotHierarchyDepth - 1) * 10
    : 0}px;
  display: flex;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 300px;
  align-items: center;
`;

export const isSubtotal = css`
  background-color: var(--bu-gray-100) !important;
  border-right: 1px solid var(--bu-gray-300);
  border-bottom: 2px solid var(--bu-gray-400);
`;

export const LoadingNodeLoader = css`
  margin-left: 4px !important;
  margin-bottom: 2px !important;
`;

export const PivotIconContainer = (pivotHierarchyDepth: number) => css`
  margin-right: 5px;
  opacity: ${pivotHierarchyDepth > 1
    ? 0.85 - (pivotHierarchyDepth - 2) * 0.1
    : 1};
`;

export const TitleValue = css`
  font-size: 10px;
  display: block;
  color: var(--bu-gray-700);
`;
