import {
  IColumn,
  IDataCellProps,
  IRow,
  TypedTableCellConfig,
  ValueProp,
} from '../TypedTable';
import moment from 'moment';
import styled from '@emotion/styled';
import classNames from 'classnames';
import { css } from 'emotion';
import * as R from 'ramda';
import React, { useMemo, useState } from 'react';
import { Popup } from 'semantic-ui-react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import { fontDefault } from 'assets/css/common';
import { shouldRenderRiskScore } from 'common/helpers';
import BuIcon from 'components/UI/BuIcon';
import DeltaValueCell, {
  getValueFromDelta,
} from 'components/UI/common/TypedTable/renderers/common/DeltaValueCell';

enum Score {
  HIGH = 'high',
  MEDIUM = 'medium',
  LOW = 'low',
  NOT_SCORED = 'undefined',
}

export enum RiskFactorType {
  POSITIVE = 'positive',
  NEGATIVE = 'negative',
}

export interface IFactorItem extends Deals.RiskFactor, Deals.PositiveIndicator {
  name: string;
  score: number;
  text: string;
  updated_at: string;
}

export interface RiskScoreCellConfig extends TypedTableCellConfig {
  risk_factors_field?: string;
  positive_indicators_field?: string;
  moreButton?: {
    label: string;
    click: (column?: IColumn, row?: IRow) => void;
  };
}

const labelCell = css`
  display: flex;
  align-items: center;
  justify-content: space-around;

  .bu-label-tag {
    font-family: var(--bu-font-medium);
    font-size: 12px;
    width: 34px;
    height: 20px;
    margin-left: 20px;
    border-radius: 4px;
    padding: 2px 7px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.${Score.HIGH} .bu-label-tag {
    color: var(--bu-white);
    background-color: var(--bu-red-400);
  }
  &.${Score.MEDIUM} .bu-label-tag {
    color: var(--bu-white);
    background-color: var(--bu-orange-500);
  }
  &.${Score.LOW} .bu-label-tag {
    color: var(--bu-white);
    background-color: var(--bu-green-500);
  }
  &.${Score.NOT_SCORED} .bu-label-tag {
    color: var(--bu-white);
    background-color: var(--bu-gray-400);
  }
`;

const riskFactorPopupScroll = css`
  ${fontDefault};
  min-width: 400px;
  margin: -0.833em -1em;

  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 5px;
`;

const RiskFactorContainer = styled.div`
  height: 240px;

  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 10px;
`;

const RiskFactorPill = styled.div<{ className: string }>`
  font-size: 0.9em;
  border-radius: 5px;
  padding: 4px 10px;
  display: block;
  margin-bottom: 5px;
  border: 1px solid transparent;
  border-left-width: 5px;

  &.${RiskFactorType.NEGATIVE} {
    border-color: var(--bu-red-500);
    background-color: var(--bu-red-200);
  }

  &.${RiskFactorType.POSITIVE} {
    border-color: var(--bu-green-500);
    background-color: var(--bu-green-200);
  }
`;

const Tab = styled.div`
  display: flex;
  background-color: var(--bu-gray-200);
  box-shadow: inset 0 -1px 0 0 var(--bu-gray-500);
  position: relative;
`;

const TabButton = styled.button`
  font-size: 1em;

  background-color: var(--bu-gray-200);
  outline: none;

  border: none;
  border-bottom: 1px solid var(--bu-gray-500);
  border-right: 1px solid var(--bu-gray-500);

  width: 60px;
  height: 40px;
  line-height: 40px;

  cursor: pointer;

  &.${RiskFactorType.NEGATIVE} {
    .bu-icon {
      color: var(--bu-red-500);
    }
  }

  &.${RiskFactorType.POSITIVE} {
    .bu-icon {
      color: var(--bu-green-500);
    }
  }

  &.active {
    border-bottom: 1px solid var(--bu-white);
    background-color: var(--bu-white);
  }
`;

const TabContainer: React.FC<{
  active: RiskFactorType | null;
  negativeCount: number;
  positiveCount: number;
  latestUpdatedAt: string;
  onClose: () => void;
  onClick: (active: RiskFactorType | null) => void;
}> = ({
  active,
  negativeCount,
  positiveCount,
  latestUpdatedAt,
  onClose,
  onClick,
}) => (
  <Tab>
    <TabButton
      className={classNames({
        active: active === null,
      })}
      onClick={() => onClick(null)}
    >
      All ({negativeCount + positiveCount})
    </TabButton>
    <TabButton
      className={classNames(RiskFactorType.NEGATIVE, {
        active: active === RiskFactorType.NEGATIVE,
      })}
      onClick={() => onClick(RiskFactorType.NEGATIVE)}
    >
      <div className={classNames(labelCell, Score.HIGH, popupHeaderLabel)}>
        <div className="bu-label-tag">{negativeCount}</div>
      </div>
    </TabButton>
    <TabButton
      className={classNames(RiskFactorType.POSITIVE, {
        active: active === RiskFactorType.POSITIVE,
      })}
      onClick={() => onClick(RiskFactorType.POSITIVE)}
    >
      <div className={classNames(labelCell, Score.LOW, popupHeaderLabel)}>
        <div className="bu-label-tag">{positiveCount}</div>
      </div>
    </TabButton>
    <div className={closePopupButton}>
      {!!latestUpdatedAt && (
        <span>Updated at {moment(latestUpdatedAt).format('h.mma')}</span>
      )}
      <button onClick={onClose}>
        <BuIcon name={BoostUpIcons.ClosePopup} />
      </button>
    </div>
  </Tab>
);

const labelWithPopup = css`
  cursor: pointer;
`;

const closePopupButton = css`
  align-self: center;
  position: absolute;
  right: 8px;
  font-size: 0.9em;
  color: var(--bu-gray-700);

  button {
    border: none;
    background-color: var(--bu-gray-200);
    cursor: pointer;
    font-size: 18px;
    margin-left: 10px;
    color: var(--bu-gray-700);
  }
`;

const popupHeaderLabel = css`
  .bu-label-tag {
    margin-left: 0;
  }
`;

const MoreButton = styled.button`
  height: 40px;
  background-color: var(--bu-white);
  border: none;
  outline: none;
  color: var(--bu-primary-700);
  display: block;
  text-align: left;
  padding-left: 10px;
  cursor: pointer;
  border-top: 1px solid var(--bu-gray-500);
`;

const getScore = (value: ValueProp): Score => {
  const score = value as number;
  if (score === null) {
    return Score.NOT_SCORED;
  } else if (score > 80) {
    return Score.HIGH;
  } else if (score >= 50 && score <= 80) {
    return Score.MEDIUM;
  } else if (score < 50) {
    return Score.LOW;
  } else {
    return Score.NOT_SCORED;
  }
};

const stopClickPropagation = (e: React.MouseEvent<HTMLDivElement>) =>
  e.stopPropagation();

export const RiskScoreCellLabel: React.FC<{ value: number | null }> = ({
  value = null,
  ...rest
}) => {
  const score = getScore(value);

  return (
    <div
      className={classNames(labelCell, score, {
        [labelWithPopup]: !!value,
      })}
      {...rest}
    >
      <div className="bu-label-tag">{value === null ? '‒' : value}</div>
      <div className="bu-label-text"></div>
    </div>
  );
};

type IRiskScore = {
  value: number;
  onMoreButtonClick?(): void;
  moreButtonLabel?: string;
  riskFactors?: Deals.RiskFactor[];
  positiveIndicators?: Deals.PositiveIndicator[];
  activitySyncInfo?: Deals.ActivitySyncInfoRecord[];
  isProgression: boolean;
};
export const RiskScore: React.FC<IRiskScore> = ({
  value,
  onMoreButtonClick,
  moreButtonLabel,
  riskFactors = [],
  positiveIndicators = [],
  activitySyncInfo = [],
  isProgression,
}) => {
  const [isOpen, setOpen] = useState(false);
  const [activeTab, setActiveTab] = useState<RiskFactorType | null>(null);

  const latestUpdatedAt = useMemo(() => {
    let latestUpdatedAt =
      positiveIndicators[0]?.updated_at || riskFactors[0]?.updated_at || '';

    [...positiveIndicators, ...riskFactors].forEach((score) => {
      latestUpdatedAt = moment(score.updated_at).isSameOrAfter(latestUpdatedAt)
        ? score.updated_at
        : latestUpdatedAt;
    });

    return latestUpdatedAt;
  }, [positiveIndicators, riskFactors]);

  const handleCustomButtonClick = () => {
    setOpen(false);
    onMoreButtonClick?.();
  };

  if (
    !shouldRenderRiskScore(
      activitySyncInfo,
      value,
      isProgression,
      positiveIndicators,
      riskFactors
    )
  ) {
    return <RiskScoreCellLabel value={null} />;
  }

  return (
    <>
      {riskFactors.length + positiveIndicators.length > 0 && (
        <Popup
          basic
          hoverable
          trigger={<RiskScoreCellLabel value={value} />}
          open={isOpen}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          position="bottom left"
          mouseEnterDelay={500}
          offset={[0, 0]}
        >
          <Popup.Content className={riskFactorPopupScroll}>
            <TabContainer
              active={activeTab}
              negativeCount={riskFactors.length}
              positiveCount={positiveIndicators.length}
              latestUpdatedAt={latestUpdatedAt}
              onClose={() => setOpen(false)}
              onClick={setActiveTab}
            />
            <RiskFactorContainer>
              {[null, RiskFactorType.NEGATIVE].includes(activeTab) &&
                riskFactors.map((risk, index) => (
                  <RiskFactorPill
                    key={`risk-factor-${index}`}
                    className={RiskFactorType.NEGATIVE}
                  >
                    {risk.text}
                  </RiskFactorPill>
                ))}
              {[null, RiskFactorType.POSITIVE].includes(activeTab) &&
                positiveIndicators.map((risk, index) => (
                  <RiskFactorPill
                    key={`positive-indicator-${index}`}
                    className={RiskFactorType.POSITIVE}
                  >
                    {risk.text}
                  </RiskFactorPill>
                ))}
            </RiskFactorContainer>

            {onMoreButtonClick && moreButtonLabel && (
              <MoreButton
                onClick={handleCustomButtonClick}
                className="bu-font-default"
              >
                {moreButtonLabel}
              </MoreButton>
            )}
          </Popup.Content>
        </Popup>
      )}

      {riskFactors.length + positiveIndicators.length === 0 && (
        <RiskScoreCellLabel value={value} />
      )}
    </>
  );
};

const formatter = (v: ValueProp) => <RiskScoreCellLabel value={v as number} />;

const RiskScoreCell = ({ column, row, rows }: IDataCellProps) => {
  const rawValue = R.path(column.field.split('.'), row) as number;
  const value = column.delta
    ? (getValueFromDelta(rawValue) as number)
    : rawValue;
  const config = (column.config as RiskScoreCellConfig) || {};

  let riskFactors: IFactorItem[] = [];
  let positiveIndicators: IFactorItem[] = [];

  if (
    config.risk_factors_field &&
    row[config.risk_factors_field] &&
    row[config.risk_factors_field] instanceof Array
  ) {
    riskFactors = R.path(
      config.risk_factors_field.split('.'),
      row
    ) as IFactorItem[];
  }

  if (
    config.positive_indicators_field &&
    row[config.positive_indicators_field] &&
    row[config.positive_indicators_field] instanceof Array
  ) {
    positiveIndicators = R.path(
      config.positive_indicators_field.split('.'),
      row
    ) as IFactorItem[];
  }

  return (
    <div onClick={stopClickPropagation}>
      <DeltaValueCell
        column={column}
        row={row}
        rows={rows}
        formatter={formatter}
      >
        <RiskScore
          isProgression={false}
          value={value}
          onMoreButtonClick={() => config?.moreButton?.click?.(column, row)}
          moreButtonLabel={config?.moreButton?.label}
          riskFactors={riskFactors}
          positiveIndicators={positiveIndicators}
        />
      </DeltaValueCell>
    </div>
  );
};

export default RiskScoreCell;
