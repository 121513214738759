import * as t from 'actions/actionTypes';

export const snippetTypeMappings = {
  Questions: 'questions',
  'Customer Topics': 'topics',
  'Seller Topics': 'topics',
  'Dates Mentioned': 'dates',
  // 'People Mentioned' : 'people',
  // 'Companies Mentioned' : 'organizations',
  // 'Locations Mentioned' : 'locations',
  // 'Job Titles' : 'job_titles',
};

export const snippetSpeakerTypeMappings = {
  'Customer Topics': 'customer',
  'Seller Topics': 'company',
};

export const allTabs = [
  'forecast_analytics',
  'forecast_analytics_change',
  'forecast_opportunities',
  'forecast_roll_ups',
  'manage_forecast',
  'pipeline_analytics',
  'pipeline_dashboard',
  'pipeline_deals_progression',
] as const;

export type BusinessTypeTab = typeof allTabs[number];

export const forecastTabs: BusinessTypeTab[] = [
  'forecast_opportunities',
  'forecast_roll_ups',
];

export const tabsWithAnalytics = [
  'forecast_opportunities',
  'targets_forecast',
  'targets_pipeline_creation',
  'targets_pipeline_coverage',
  'forecast_analytics',
  'forecast_analytics_change',
  'forecast_roll_ups',
  'pipeline_analytics',
  'pipeline_dashboard',
  'accounts',
];

export const USER_ROLES = {
  ACCOUNT_EXECUTIVE: 'Account Executive',
  ACCOUNT_MANGER: 'Account Manager',
  BDR: 'BDR / SDR',
  DIRECTOR_OF_ACCOUNTS: 'Director / VP of Accounts',
  DIRECTOR_OF_CUSTOMER: 'Director / VP of Customer Success',
  SALES_ENGINEER: 'Sales Engineer',
  SALES_ENGINEER_MANAGER: 'Sales Engineer Manager',
  SALES_OPERATIONS: 'Sales Operations / Enablement',
  SALES_MANAGER: 'Sales Manager / VP Sales / CRO',
  CLEVEL: 'GM / CRO / CEO',
  CUSTOMER_SUCCESS_MANAGER: 'Customer Success Manager',
  FINANCE: 'Finance',
  OTHER: 'Other',
};
export type Roles = Array<keyof typeof USER_ROLES>;

export type Role = keyof typeof USER_ROLES;

export const MANAGER_ROLES = {
  SALES_OPERATIONS: USER_ROLES.SALES_OPERATIONS,
  SALES_MANAGER: USER_ROLES.SALES_MANAGER,
  CLEVEL: USER_ROLES.CLEVEL,
};

export const SALES_MANAGER_ROLES = [
  USER_ROLES.SALES_MANAGER,
  USER_ROLES.CLEVEL,
];

export const CALL_STATUS = {
  ALL: 'all',
  RECORDED: 'recorded',
  SUMMARIZED: 'summarized',
};

export const RESPONSE_STATUS_MAP: { [index: string]: string } = {
  none: 'No Response',
  notResponded: 'No Response',
  needsAction: 'No Response',
  accepted: 'Accepted',
  tentative: 'Tentative',
  tentativelyAccepted: 'Tentative',
  declined: 'Declined',
  cancelled: 'Declined',
  organizer: 'Accepted',
};

export const MONTHS: { [key: number]: string } = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December',
};

export const TIME_PERIODS = {
  THIS_WEEK: 'this_week',
  NEXT_WEEK: 'next_week',
  LAST_WEEK: 'last_week',
  THIS_MONTH: 'this_month',
  LAST_MONTH: 'last_month',
  NEXT_MONTH: 'next_month',
  LAST_3_MONTHS: 'last_3_months',
  LAST_6_MONTHS: 'last_6_months',
  LAST_9_MONTHS: 'last_9_months',
  LAST_12_MONTHS: 'last_12_months',
};

export const CALLS_STATS_CHART_IDS = {
  CALLS: 'calls-chart',
  EVENTS: 'events-chart',
};

export const SENTIMENT_GRADE_DISPLAY: { [index: string]: string } = {
  neutral: 'Neutral',
  positive: 'Positive',
  negative: 'Negative',
};

export const DEFAULT_EDITABLE_FIELDS = [
  'next step',
  'close date',
  'amount',
  'probability',
  'stage',
  'forecast categories',
];

export const BUYER_ENGAGEMENT_PAGE_SIZE = 5;

export const cancellationWhiteList = [t.FORECAST + t.GET, t.ACCOUNTS + t.GET];

export const BOOL_OPTIONS = [
  { text: 'true', value: true },
  { text: 'false', value: false },
];

export const DEFAULT_FIELDS_ACCOUNTS = [
  'account name',
  'user',
  'account value',
  'renewal date',
  'account stage',
  'open opportunities',
  'open amount',
  'won opportunities',
  'won amount',
  'account risk score',
  'last meeting',
  'last touched',
  'last engaged',
  'contacts touched',
  'contacts engaged',
  'events count',
  'account owner emails count',
  'buyer emails count',
  'seller emails count',
];

export const tabs = {
  opportunities: {
    title: 'Opportunities',
  },
  forecast: {
    title: 'Forecast',
  },
  pipeline: {
    title: 'Pipeline',
  },
  accounts: {
    title: 'Accounts',
  },
  'activity dashboard': {
    title: 'Activity Dashboard',
  },
  calls: {
    title: 'Calls',
  },
  sellerDashboard: {
    title: 'Seller Dashboard',
  },
} as const;

export type Tabs = keyof typeof tabs;

export const CHANGE_INTERVAL_OPTIONS: { [key: string]: string } = {
  ALT: 'All Time',
  FUT: 'Closing in the Future',
  L14D: 'Last 14 Days',
  L30D: 'Last 30 Days',
  L3M: 'Last 3 Months',
  L60D: 'Last 60 Days',
  L7D: 'Last 7 Days',
  L90D: 'Last 90 Days',
  LMO: 'Last Month',
  LQU: 'Last Quarter',
  N3M: 'Next 3 Months',
  N6M: 'Next 6 Months',
  NMO: 'Next Month',
  NQU: 'Next Quarter',
  SQS: 'Quarter Start',
  TMO: 'Current Month',
  TQU: 'Current Quarter',
  TYE: 'Current Year',
  TWE: 'Current Week',
  LWE: 'Last Week',
  YDY: 'Yesterday',
};

export type IntervalRule = {
  type: 'days' | 'week' | 'month' | 'quarter' | 'year';
  value: number;
};

export const CHANGE_INTERVAL_RULES: Record<
  string,
  [IntervalRule, IntervalRule]
> = {
  L7D: [
    { type: 'days', value: -7 },
    { type: 'days', value: -1 },
  ],
  L14D: [
    { type: 'days', value: -14 },
    { type: 'days', value: -1 },
  ],
  L30D: [
    { type: 'days', value: -30 },
    { type: 'days', value: -1 },
  ],
  L3M: [
    { type: 'month', value: -3 },
    { type: 'month', value: -1 },
  ],
  L60D: [
    { type: 'days', value: -60 },
    { type: 'days', value: -1 },
  ],
  L90D: [
    { type: 'days', value: -90 },
    { type: 'days', value: -1 },
  ],
  LMO: [
    { type: 'month', value: -1 },
    { type: 'month', value: -1 },
  ],
  N3M: [
    { type: 'month', value: 1 },
    { type: 'month', value: 3 },
  ],
  N6M: [
    { type: 'month', value: 1 },
    { type: 'month', value: 6 },
  ],
  NMO: [
    { type: 'month', value: 1 },
    { type: 'month', value: 1 },
  ],
  LQU: [
    { type: 'quarter', value: -1 },
    { type: 'month', value: -1 },
  ],
  NQU: [
    { type: 'quarter', value: 1 },
    { type: 'month', value: 1 },
  ],
  SQS: [
    { type: 'quarter', value: 0 },
    { type: 'month', value: 0 },
  ],
  TMO: [
    { type: 'month', value: 0 },
    { type: 'month', value: 0 },
  ],
  TQU: [
    { type: 'quarter', value: 0 },
    { type: 'month', value: 0 },
  ],
  TYE: [
    { type: 'year', value: 0 },
    { type: 'year', value: 0 },
  ],
  TWE: [
    { type: 'week', value: 0 },
    { type: 'week', value: 0 },
  ],
  LWE: [
    { type: 'week', value: -1 },
    { type: 'week', value: -1 },
  ],
  ALT: [
    { type: 'year', value: -10 },
    { type: 'year', value: 0 },
  ],
};

export const CHANGE_INTERVAL_HIERARCHY: Record<string, string[]> = {
  month: [
    'ALT',
    'L14D',
    'L30D',
    'L3M',
    'L60D',
    'L7D',
    'L90D',
    'LMO',
    'N3M',
    'N6M',
    'NMO',
    'TMO',
    'TWE',
    'LWE',
  ],
  quarter: [
    'ALT',
    'L14D',
    'L30D',
    'L3M',
    'L60D',
    'L7D',
    'L90D',
    'LMO',
    'N3M',
    'N6M',
    'NMO',
    'TMO',
    'TWE',
    'LWE',
    'LQU',
    'NQU',
    'SQS',
    'TQU',
    'TYE',
  ],
};

export const ENABLED_ANALYTICS_TRACKERS = {
  Amplitude: true,
  pendo: true,
};

export const TABLE_NAME = {
  Opportunities: 'Opportunities',
  DealsDelta: 'DealsDelta',
  ForecastSubmissionSettings: 'ForecastSubmissionSettings',
};
export const FEATURE_COOKIE_PREFIX = 'FEATURE__';
export const FILTER_NAME = {
  ForecastOpportunitiesModal: 'forecast_opportunities_modal',
  ForecastAnalyticsModal: 'forecast_analytics_modal',
  FullscreenTrendsModal: 'fullscreen_trends_modal',
};

export const ERROR_MESSAGES: { [key: string]: string } = {
  STRING_TOO_LONG: 'value entered exceeds the maximum limit of characters.',
};
