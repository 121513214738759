import isNil from 'lodash/isNil';
import reduce from 'lodash/reduce';
import * as R from 'ramda';
import { useState } from 'react';

import { DEFAULT_FIELDS_ACCOUNTS, ERROR_MESSAGES } from 'common/constants';
import { formatMoneyAndShort, shortNumberWithConfig } from 'common/numbers';
import { ISelectOption } from 'components/UI/BuSelect/types';
import { CustomCheckbox } from 'components/UI/FilterPanel/types';
import { UserCheckbox } from 'components/UI/FilterPanelUsers/TeamCheckbox/types';
import { navigationSchemes } from 'navigation/constants';
import { INavigationScheme } from 'navigation/types';

export function teamFlatter(team: UserCheckbox[]): UserCheckbox[] {
  return reduce(
    team,
    (acc: UserCheckbox[], member: UserCheckbox) => {
      let result = acc.concat(member);
      if (member.team !== undefined) {
        result = result.concat(teamFlatter(member.team));
      }
      return result;
    },
    []
  );
}

export const prepareUsersToOptions = (
  options: Filters.UserFilterElement[],
  values: string[]
): UserCheckbox[] => {
  const isChecked = (value: string, checkedValues: string[]) =>
    checkedValues.findIndex((v) => v === value) !== -1;

  function iterator(opt: Filters.UserFilterElement): UserCheckbox {
    const result: UserCheckbox = {
      email: opt.email,
      name: opt.name,
      id: opt.id,
      role: opt.role,
      team: isNil(opt.team) ? [] : prepareUsersToOptions(opt.team, values),
      checked: isChecked(opt.email, values),
    };
    if (!isNil(result.team)) {
      result.team = prepareUsersToOptions(result.team, values);
    }
    return result;
  }

  return options.map(iterator);
};

type CompareChildrenCount = {
  children: CompareChildrenCount[];
  label: string;
};

const compareChildrenCount = <T>(a: T, b: T) => {
  const aa = a as unknown as CompareChildrenCount;
  const bb = b as unknown as CompareChildrenCount;

  if (
    aa.children &&
    bb.children &&
    aa.children.length > 0 &&
    bb.children.length > 0
  ) {
    return aa.label.localeCompare(bb.label);
  }

  if (aa.children && aa.children.length > 0) {
    return -1;
  }

  if (bb.children && bb.children.length > 0) {
    return 1;
  }
  return aa.label.localeCompare(bb.label);
};

export const translateUserRoleToFilter = (
  userRole: Filters.UserRoleFilterElement[]
): CustomCheckbox[] => {
  return userRole
    ? userRole
        .map((user) => ({
          value: user.id as string,
          label: user.name as string,
          children:
            user.children && user.children.length
              ? translateUserRoleToFilter(user.children)
              : undefined,
          checked: user.children && user.children.length ? undefined : true,
        }))
        .sort(compareChildrenCount)
    : [];
};

export const shouldRenderRiskScore = (
  activity_sync_info: Deals.ActivitySyncInfoRecord[] = [],
  score: Number | number = 0,
  isProgression: boolean = false,
  positiveIndicators: Deals.PositiveIndicator[] = [],
  riskFactors: Deals.RiskFactor[] = []
) => {
  // If deal has activity sync issues with status error then don't show the risk score
  if (
    !R.isEmpty(
      R.filter(
        (asi: Deals.ActivitySyncInfoRecord) => asi.status === 'error',
        activity_sync_info
      )
    )
  ) {
    return false;
  }
  // If deal has activity sync issues with status warning, and risk score is 100, don't show
  // risk score
  if (!R.isEmpty(activity_sync_info) && score == 100) {
    return false;
  }
  // Amit (03/24/2020): Show risk score 100 deals if they have some risk factors.
  // // if risk score is 100 don't show the risk score
  // if (score == 100) {
  //   return false;
  // }
  // dont show progression score of 50 (indicates no judgement)
  if (score == 50 && isProgression) {
    return false;
  }
  // if there are no risk factors or positive indicators, dont show risk score
  if (R.isEmpty(positiveIndicators) && R.isEmpty(riskFactors)) {
    return false;
  }
  // otherwise show risk score
  return true;
};

export const useStateWhile = <S>(
  baseState: S | (() => S)
): [S, (duringState: S, timeout: number) => void] => {
  const [timer, setTimer] = useState(0);
  const [state, setState] = useState(baseState);

  const startTimer = (duringState: S, timeout: number) => {
    if (timer) {
      clearTimeout(timer);
    }

    setState(duringState);

    setTimer(
      window.setTimeout(() => {
        setState(baseState);
      }, timeout)
    );
  };

  return [state, startTimer];
};

export const updateViewConfig = (
  viewConfig: { [id: string]: any }, // view configuration
  inputFields: string[], // settings from the backend
  type: string, // columns | filters
  param: string // sortable | hidden | editable
) => {
  if (inputFields.includes('__default__')) {
    DEFAULT_FIELDS_ACCOUNTS.forEach((f) => {
      if (viewConfig[type][f]) {
        viewConfig[type][f][param] = true;
      }
    });
  }

  inputFields.forEach((f) => {
    if (viewConfig[type][f]) {
      viewConfig[type][f][param] = true;
    }
  });
};

export const getDaysInMonth = (
  month: number,
  year: number = new Date().getFullYear()
): number => new Date(year, month, 0).getDate();

const DECIMALS_CONFIG = {
  D: 2,
  K: 2,
  M: 2,
  B: 2,
  T: 2,
};

export const formatAmount = (
  companyCurrency: string,
  value: number,
  positiveSign: boolean = false
): string =>
  formatMoneyAndShort(
    companyCurrency,
    value,
    shortNumberWithConfig(DECIMALS_CONFIG),
    positiveSign
  );

export const sortAlphabetically = (
  arrToSort: any[],
  elementStayFirst?: string
): ISelectOption[] => {
  return arrToSort.sort(function (a, b) {
    return a.value === elementStayFirst
      ? -1
      : b.value === elementStayFirst
      ? 1
      : a.text > b.text
      ? 1
      : a.text < b.text
      ? -1
      : 0;
  });
};

type IError =
  | Array<{ errorCode?: string; fields: string[]; message: string }>
  | string;

export const parseError = (error: IError): string => {
  let errorMessage = '';

  if (typeof error === 'string') {
    errorMessage = error;
  }

  if (Array.isArray(error)) {
    error.forEach((e) => {
      errorMessage +=
        e.errorCode && ERROR_MESSAGES[e.errorCode]
          ? `${e.fields.join(' ')} ${ERROR_MESSAGES[e.errorCode]}\n`
          : `${e.message}\n`;
    });
  }

  return errorMessage;
};

export const addUrlParams = (
  url: string,
  params: { [key: string]: string }
) => {
  const [base, search] = url.split('?');
  const paramsObj = new URLSearchParams(search);

  for (let key in params) {
    if (params[key]) {
      paramsObj.set(key, params[key]);
    }
  }

  const newQueryString = paramsObj.toString();
  return newQueryString ? `${base}?${newQueryString}` : base;
};

export const getRevBiNavigationLink = (mainName: string, subName: string) =>
  `/${mainName}/rev-bi/dashboard/${subName}`;

export const getLandingPageScheme = (defaultLanding: string) => {
  // main navigations
  if (navigationSchemes[defaultLanding]) {
    return navigationSchemes[defaultLanding].scheme;
  } else {
    // sub navigations
    const [mainNavName, subNavName] = defaultLanding.split('__');
    const subNavigation = navigationSchemes[mainNavName] as INavigationScheme;
    // check if match scheme
    return subNavigation && subNavigation[defaultLanding]
      ? subNavigation[defaultLanding].scheme
      : getRevBiNavigationLink(mainNavName, subNavName);
  }
};

export function getProbabilityPercentage(probability: number): string {
  const percentage = (probability * 100).toFixed(0);
  return `${percentage}%`;
}

export const replaceNewLinesToHTML = (content: string) =>
  content.replace(/\n/g, '<br />');
