import clx from 'classnames';
import { css } from 'emotion';
import moment from 'moment';
import React from 'react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuIcon from 'components/UI/BuIcon';
import Tooltip from 'components/UI/common/Tooltip';

const COMPONENT_TESTING_NAME = 'RefreshAction';

const BUTTON_SIZE = 30;

const styles = {
  box: css`
    margin: 0 10px;
    display: flex;
    align-items: center;
  `,
  lastUpdate: css`
    font-family: var(--bu-font-regular);
    cursor: default;
    font-size: 12px;
    color: var(--bu-gray-900);
    margin-left: 10px;
    &.redAlert {
      color: var(--bu-red-400) !important;
    }
  `,
  button: css`
    cursor: pointer;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${BUTTON_SIZE}px;
    width: ${BUTTON_SIZE}px;
    background: transparent;
    border: none;

    &:hover {
      i {
        color: var(--bu-primary-500) !important;
      }
    }

    &.load {
      pointer-events: none;
      i {
        animation: spin 3s linear 0s infinite;
        display: inline-block;
      }
      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }
    &:disabled {
      background-color: var(--bu-gray-300);
      pointer-events: none;
    }
  `,
};

export type IBuButtonRefreshProps = {
  onClick: () => void;
  cacheDate: string;
  status: boolean;
  message?: string;
  disabled?: boolean;
  showLastUpdatedMessage?: boolean;
  warningElapsedUpdateTimeSeconds?: number;
} & Common.DataTestingType;

const BuButtonRefresh: React.FC<IBuButtonRefreshProps> = ({
  onClick,
  cacheDate,
  status,
  message = 'Update data',
  testingLabel = '',
  showLastUpdatedMessage = true,
  disabled = false,
  warningElapsedUpdateTimeSeconds,
}) => {
  const componentDataTestingKey = testingLabel
    ? `${testingLabel}-${COMPONENT_TESTING_NAME}`
    : COMPONENT_TESTING_NAME;
  const now = moment();
  const since = cacheDate && moment(cacheDate);
  const isSameDay = since && now.isSame(since, 'day');
  const sinceDay =
    !isSameDay && since ? `on ${since.format('MMM DD')}` : 'Today';
  const time = since
    ? since.format('h.mmA').toLocaleLowerCase()
    : now.format('h.mmA').toLocaleLowerCase();
  const lastUpdatedMessage = `Updated ${sinceDay} at ${time}`;
  const displayLasUpdatedMessageAsTooltip = !showLastUpdatedMessage;

  const duration = moment.duration(now.diff(since));
  const redAlert = warningElapsedUpdateTimeSeconds
    ? duration.asSeconds() > warningElapsedUpdateTimeSeconds
    : false;

  return (
    <div className={styles.box} data-testing={componentDataTestingKey}>
      <Tooltip
        position="bottom right"
        tooltip={
          displayLasUpdatedMessageAsTooltip ? lastUpdatedMessage : message
        }
      >
        <button
          onClick={onClick}
          className={clx(styles.button, { load: status })}
          type="button"
          data-testing={`${componentDataTestingKey}-Btn`}
          disabled={disabled}
        >
          <BuIcon
            name={BoostUpIcons.RefreshIcon}
            color="var(--bu-primary-700)"
          />
        </button>
      </Tooltip>
      <p
        data-testing={`${componentDataTestingKey}-Date`}
        className={clx(styles.lastUpdate, { redAlert: redAlert })}
      >
        {showLastUpdatedMessage && lastUpdatedMessage}
      </p>
    </div>
  );
};

export default BuButtonRefresh;
