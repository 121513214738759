import classNames from 'classnames';
import { css } from 'emotion';
import { isNil } from 'ramda';
import React from 'react';
import { useSelector } from 'react-redux';

import TooltipWrapper from 'components/UI/common/TypedTable/renderers/common/TooltipWrapper';
import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuIcon from 'components/UI/BuIcon';
import { formatAmount } from 'common/helpers';
import { shortNumberWithConfig } from 'common/numbers';
import { ColumnTypes } from 'components/UI/common/TypedTable/renderers';
import * as selectors from 'selectors';
import { ValueProp } from 'components/UI/common/TypedTable/TypedTable';
import { Delta } from 'components/UI/BuMetricsPanel/StyledComponents';

const fontColor: Record<string, string> = {
  green: 'var(--bu-white)',
  grey: 'var(--bu-gray-600)',
  red: 'var(--bu-white)',
};

const bgColor: Record<string, string> = {
  green: 'var(--bu-green-500)',
  grey: 'var(--bu-table-border-color)',
  red: 'var(--bu-red-400)',
};

const badge = (color: string, onClick?: () => void) => css`
  margin-top: 5px;
  font-family: var(--bu-font-medium);
  cursor: ${onClick ? 'pointer' : 'default'};
  background-color: ${bgColor[color]} !important;
  color: ${fontColor[color]} !important;

  &.arrow-badge {
    cursor: ${onClick ? 'pointer' : 'default'};
    background-color: ${bgColor[color]} !important;
    color: ${fontColor[color]} !important;
    align-self: center;
    text-align: center;
    line-height: 1;
    font-size: 6px;
    padding: 5px;
    border-radius: 4px;
  }

  &.badge {
    height: 16px;
    width: fit-content;
    border-radius: 7px;
    padding: 1.5px 7px;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 17px;
    margin-left: auto;
  }
`;

const textBold = css`
  font-family: var(--bu-font-medium);
`;

const arrowBadgePlaceholder = css`
  width: 16px;
`;

const tooltipWrapper = css`
  min-width: 300px;

  div > span {
    margin-left: 0;
  }
`;

const DECIMALS_CONFIG = {
  D: 0,
  K: 0,
  M: 1,
  B: 1,
  T: 1,
};

const getFormattedAmount = (
  value: number,
  valueType: ColumnTypes,
  companyCurrencyCode: string
) => {
  switch (valueType) {
    case ColumnTypes.TEXT:
      return shortNumberWithConfig(DECIMALS_CONFIG)(value);
    default:
      return formatAmount(companyCurrencyCode, value, true);
  }
};

interface BadgeProps {
  value?: number | string;
  valueType?: ColumnTypes;
  onClick?: () => void;
  isArrowBadge?: boolean;
  hasConfigTooltip?: boolean;
  relativeFieldsSubRawValue?: number[];
  configFormatter?: (...values: ValueProp[]) => React.ReactNode;
}

const Badge = ({
  value: deltaValue,
  valueType = ColumnTypes.MONEY,
  onClick,
  isArrowBadge = false,
  hasConfigTooltip,
  relativeFieldsSubRawValue = [],
  configFormatter = () => {},
}: BadgeProps) => {
  const companyCurrencyCode = useSelector(selectors.getUserLocalCurrency);
  const [currentValue, prevValue] = relativeFieldsSubRawValue;

  let color = 'grey';

  if (
    isNaN(Number(deltaValue)) ||
    isNil(deltaValue) ||
    Number(deltaValue) === 0
  ) {
    return <div className={arrowBadgePlaceholder}></div>;
  }

  if (Number(deltaValue) > 0) {
    color = 'green';
  } else if (Number(deltaValue) < 0) {
    color = 'red';
  }

  const formattedDeltaAmount = getFormattedAmount(
    deltaValue as number,
    valueType,
    companyCurrencyCode
  );

  const formattedDeltaAmountPopup = configFormatter(
    Math.abs(deltaValue as number)
  );
  const formattedCurrentAmount = configFormatter(currentValue);
  const formattedPrevAmount = configFormatter(prevValue);

  return (
    <TooltipWrapper
      tooltip={
        hasConfigTooltip ? null : (
          <div className={tooltipWrapper}>
            Changed from <span className={textBold}>{formattedPrevAmount}</span>{' '}
            to <span className={textBold}>{formattedCurrentAmount}</span>{' '}
            <div>
              <Delta isPositive={Number(deltaValue) > 0}>
                {Number(deltaValue) > 0 ? '+' : '-'} {formattedDeltaAmountPopup}
              </Delta>
            </div>
          </div>
        )
      }
      position="top center"
    >
      <div
        onClick={onClick}
        className={classNames(
          'amount-badge',
          badge(color, onClick),
          isArrowBadge ? 'arrow-badge' : 'badge'
        )}
      >
        {isArrowBadge ? (
          Number(deltaValue) > 0 ? (
            <BuIcon name={BoostUpIcons.ChangeUp} />
          ) : (
            <BuIcon name={BoostUpIcons.ChangeDown} />
          )
        ) : (
          formattedDeltaAmount
        )}
      </div>
    </TooltipWrapper>
  );
};

export default Badge;
