import { reducerWithInitialState } from 'typescript-fsa-reducers';

import * as metricsActions from 'actions/revbi/metrics';
import { FORECAST_SUBMISSION_OBJECTS } from 'components/dashboard/Metrics/constants';
import { parseWidgetList } from 'components/dashboard/Metrics/metrics.helpers';
import {
  BIMetricSimple,
  BIMetricFormula,
  DateFilterOptionsResponse,
  BIWidget,
} from 'components/dashboard/Metrics/metrics.types';

export type RevBIMetricsReduxStore = {
  forecastPeriodTimeOptions: {
    quarter: {
      options: Filters.DateFilterElement[];
      status: string;
    };
    month: {
      options: Filters.DateFilterElement[];
      status: string;
    };
    week: {
      options: Filters.DateFilterElement[];
      status: string;
    };
  };
  forecastSubmissionMetricsList: {
    list: (BIMetricSimple | BIMetricFormula)[];
    status: string;
  };
  nonForecastSubmissionMetricsList: {
    list: (BIMetricSimple | BIMetricFormula)[];
    status: string;
  };
  metricsList: {
    list: (BIMetricSimple | BIMetricFormula)[];
    status: string;
  };
  metricsTSList: {
    list: (BIMetricSimple | BIMetricFormula)[];
    status: string;
  };
  forecastSubmissionObjectList: {
    list: string[];
    status: string;
  };
  nonForecastSubmissionObjectList: {
    list: string[];
    status: string;
  };
  objectList: {
    list: string[];
    status: string;
  };
  objectListHistory: {
    list: string[];
    status: string;
  };
  timeOptions: {
    options: DateFilterOptionsResponse;
    status: string;
  };
  widgetsList: {
    list: BIWidget[];
    status: string;
  };
};

const initialState: RevBIMetricsReduxStore = {
  forecastPeriodTimeOptions: {
    quarter: {
      options: [],
      status: 'notAsked',
    },
    month: {
      options: [],
      status: 'notAsked',
    },
    week: {
      options: [],
      status: 'notAsked',
    },
  },
  forecastSubmissionMetricsList: {
    list: [],
    status: 'notAsked',
  },
  nonForecastSubmissionMetricsList: {
    list: [],
    status: 'notAsked',
  },
  metricsList: {
    list: [],
    status: 'notAsked',
  },
  metricsTSList: {
    list: [],
    status: 'notAsked',
  },
  forecastSubmissionObjectList: {
    list: [],
    status: 'notAsked',
  },
  nonForecastSubmissionObjectList: {
    list: [],
    status: 'notAsked',
  },
  objectList: {
    list: [],
    status: 'notAsked',
  },
  objectListHistory: {
    list: [],
    status: 'notAsked',
  },
  timeOptions: {
    options: {
      predefined: [],
      fiscal_year: [],
    },
    status: 'notAsked',
  },
  widgetsList: {
    list: [],
    status: 'notAsked',
  },
};

export const metricsReducer = reducerWithInitialState<RevBIMetricsReduxStore>(
  initialState
)
  .case(metricsActions.fetchAllMetricsSuccess, (state, payload) => ({
    ...state,
    metricsList: {
      status: 'success',
      list: payload,
    },
    forecastSubmissionMetricsList: {
      status: 'success',
      list: payload.filter(
        (m) => (m as BIMetricSimple)?.object === 'forecast_submission'
      ),
    },
    nonForecastSubmissionMetricsList: {
      status: 'success',
      list: payload.filter(
        (m) => (m as BIMetricSimple)?.object !== 'forecast_submission'
      ),
    },
  }))
  .case(metricsActions.fetchAllMetricsLoading, (state) => ({
    ...state,
    metricsList: {
      ...state.metricsList,
      status: 'loading',
    },
    forecastSubmissionMetricsList: {
      ...state.forecastSubmissionMetricsList,
      status: 'loading',
    },
    nonForecastSubmissionMetricsList: {
      ...state.nonForecastSubmissionMetricsList,
      status: 'loading',
    },
  }))
  .case(metricsActions.fetchAllTSMetricsSuccess, (state, payload) => ({
    ...state,
    metricsTSList: {
      status: 'success',
      list: payload,
    },
  }))
  .case(metricsActions.fetchAllTSMetricsLoading, (state) => ({
    ...state,
    metricsTSList: {
      ...state.metricsList,
      status: 'loading',
    },
  }))
  .case(metricsActions.fetchObjectListSuccess, (state, payload) => ({
    ...state,
    objectList: {
      list: payload,
      status: 'success',
    },
    forecastSubmissionObjectList: {
      list: payload.filter((o) => FORECAST_SUBMISSION_OBJECTS.includes(o)),
      status: 'success',
    },
    nonForecastSubmissionObjectList: {
      list: payload.filter((o) => !FORECAST_SUBMISSION_OBJECTS.includes(o)),
      status: 'success',
    },
  }))
  .case(metricsActions.fetchObjectListLoading, (state) => ({
    ...state,
    objectList: {
      ...state.objectList,
      status: 'loading',
    },
    forecastSubmissionObjectList: {
      ...state.objectList,
      status: 'loading',
    },
    nonForecastSubmissionObjectList: {
      ...state.objectList,
      status: 'loading',
    },
  }))
  .case(metricsActions.fetchTimeSeriesObjectListSuccess, (state, payload) => ({
    ...state,
    objectListHistory: {
      list: payload,
      status: 'success',
    },
  }))
  .case(metricsActions.fetchTimeSeriesObjectListLoading, (state) => ({
    ...state,
    objectListHistory: {
      ...state.objectList,
      status: 'loading',
    },
  }))
  .case(metricsActions.removeMetric, (state, payload) => ({
    ...state,
    forecastSubmissionMetricsList: {
      ...state.forecastSubmissionMetricsList,
      list: state.forecastSubmissionMetricsList.list.filter(
        (metric) => !payload?.ids?.includes(metric?._id)
      ),
    },
    nonForecastSubmissionMetricsList: {
      ...state.nonForecastSubmissionMetricsList,
      list: state.nonForecastSubmissionMetricsList.list.filter(
        (metric) => !payload?.ids?.includes(metric?._id)
      ),
    },
    metricsList: {
      ...state.metricsList,
      list: state.metricsList.list.filter(
        (metric) => !payload?.ids?.includes(metric?._id)
      ),
    },
    metricsTSList: {
      ...state.metricsTSList,
      list: state.metricsTSList.list.filter(
        (metric) => !payload?.ids?.includes(metric?._id)
      ),
    },
  }))
  .case(metricsActions.addMetric, (state, payload) => ({
    ...state,
    metricsList: {
      ...state.metricsList,
      list: [payload, ...state.metricsList.list],
    },
  }))
  .case(metricsActions.addTSMetric, (state, payload) => ({
    ...state,
    metricsTSList: {
      ...state.metricsTSList,
      list: [payload, ...state.metricsTSList.list],
    },
  }))
  .case(metricsActions.fetchTimeOptionsSuccess, (state, payload) => ({
    ...state,
    timeOptions: {
      options: payload,
      status: 'success',
    },
  }))
  .case(metricsActions.fetchTimeOptionsLoading, (state) => ({
    ...state,
    timeOptions: {
      ...state.timeOptions,
      status: 'loading',
    },
  }))
  .case(metricsActions.fetchAllWidgetsSuccess, (state, payload) => ({
    ...state,
    widgetsList: {
      list: parseWidgetList(payload),
      status: 'success',
    },
  }))
  .case(metricsActions.fetchAllWidgetsLoading, (state) => ({
    ...state,
    widgetsList: {
      ...state.widgetsList,
      status: 'loading',
    },
  }))
  .case(metricsActions.removeWidget, (state, payload) => ({
    ...state,
    widgetsList: {
      ...state.widgetsList,
      list: state.widgetsList.list.filter(
        (widget) => payload?.id !== widget?._id
      ),
    },
  }))
  .case(metricsActions.addWidget, (state, payload) => ({
    ...state,
    widgetsList: {
      ...state.widgetsList,
      list: [payload, ...state.widgetsList.list],
    },
  }))
  .case(metricsActions.updateWidget, (state, payload) => ({
    ...state,
    widgetsList: {
      ...state.widgetsList,
      list: state.widgetsList.list.map((w) => {
        if (w._id === payload._id) {
          return payload;
        }
        return w;
      }),
    },
  }))
  .case(
    metricsActions.fetchQuarterForecastPeriodTimeOptionsSuccess,
    (state, payload) => ({
      ...state,
      forecastPeriodTimeOptions: {
        ...state.forecastPeriodTimeOptions,
        quarter: {
          options: payload,
          status: 'success',
        },
      },
    })
  )
  .case(
    metricsActions.fetchQuarterForecastPeriodTimeOptionsLoading,
    (state) => ({
      ...state,
      forecastPeriodTimeOptions: {
        ...state.forecastPeriodTimeOptions,
        quarter: {
          ...state.forecastPeriodTimeOptions.quarter,
          status: 'loading',
        },
      },
    })
  )
  .case(
    metricsActions.fetchMonthForecastPeriodTimeOptionsSuccess,
    (state, payload) => ({
      ...state,
      forecastPeriodTimeOptions: {
        ...state.forecastPeriodTimeOptions,
        month: {
          options: payload,
          status: 'success',
        },
      },
    })
  )
  .case(metricsActions.fetchMonthForecastPeriodTimeOptionsLoading, (state) => ({
    ...state,
    forecastPeriodTimeOptions: {
      ...state.forecastPeriodTimeOptions,
      month: {
        ...state.forecastPeriodTimeOptions.month,
        status: 'loading',
      },
    },
  }))
  .case(
    metricsActions.fetchWeekForecastPeriodTimeOptionsSuccess,
    (state, payload) => ({
      ...state,
      forecastPeriodTimeOptions: {
        ...state.forecastPeriodTimeOptions,
        week: {
          options: payload,
          status: 'success',
        },
      },
    })
  )
  .case(metricsActions.fetchWeekForecastPeriodTimeOptionsLoading, (state) => ({
    ...state,
    forecastPeriodTimeOptions: {
      ...state.forecastPeriodTimeOptions,
      week: {
        ...state.forecastPeriodTimeOptions.week,
        status: 'loading',
      },
    },
  }));
