import { debounce } from 'lodash';
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import BuButton from 'components/UI/BuButton';
import BuInput from 'components/UI/BuInput';
import { MetricDefinitionInputs } from 'components/dashboard/Metrics/Create/MetricDefinitionInputs/MetricDefinitionInputs';
import { inputsContainer } from 'components/dashboard/Metrics/Create/MetricDefinitionInputs/styles';
import {
  actionButtonsContainer,
  breadcrumbs,
  metricsCreateContainer,
} from 'components/dashboard/Metrics/Create/WidgetCreate/WidgetCreateOptions/MetricDefinition/styles';
import {
  SIMPLE_HISTORICAL_METRIC_NEWBORN_MOCK,
  SIMPLE_METRIC_NEWBORN_MOCK,
} from 'components/dashboard/Metrics/Create/constants';
import {
  isBIMetricFormula,
  isBIMetricSimple,
  isFormulaMetricCanPreview,
  isFormulaMetricValid,
  isMetricFiltersValid,
  isSimpleMetricCanPreview,
  isSimpleMetricValid,
} from 'components/dashboard/Metrics/Create/utils';
import { AnalysisType } from 'components/dashboard/Metrics/constants';
import { MetricCreateSubTitle } from 'components/dashboard/Metrics/metrics.styles';
import {
  BIMetricCreated,
  BIMetricUnion,
} from 'components/dashboard/Metrics/metrics.types';
import MetricDescriptionInput from '../../../MetricDefinitionInputs/MetricDescriptionInput';

interface Props {
  readonly analysisType: AnalysisType;
  readonly isForecastSubmissionWidget: boolean | null;
  readonly metric: BIMetricCreated | undefined;
  setIsCreateOrEditMetricMode: Dispatch<SetStateAction<boolean>>;
  onSaveMetric: (metric: BIMetricUnion) => void;
  manageMetricVisualization: (action: string, metric?: BIMetricCreated) => void;
}

const getInitialState = (
  metric: BIMetricCreated | undefined,
  analysisType: AnalysisType
): BIMetricUnion => {
  if (metric !== undefined) {
    return metric;
  }

  if (analysisType === AnalysisType.LIVE) {
    return SIMPLE_METRIC_NEWBORN_MOCK;
  }
  return SIMPLE_HISTORICAL_METRIC_NEWBORN_MOCK;
};

export const MetricDefinition: React.FC<Props> = ({
  analysisType,
  isForecastSubmissionWidget,
  metric,
  setIsCreateOrEditMetricMode,
  onSaveMetric,
  manageMetricVisualization,
}) => {
  const [metricName, setMetricName] = useState(metric?.name || '');
  // TODO: review with the guys if we can merge these 4 Types in only 2
  const isEditing = metric !== undefined;

  const [newMetric, setNewMetric] = useState<BIMetricUnion>(
    getInitialState(metric, analysisType)
  );
  const [isComplete, setIsComplete] = useState<boolean>(false);

  const canSave = useMemo(
    () =>
      isComplete &&
      newMetric !== null &&
      isMetricFiltersValid(newMetric) &&
      ((isBIMetricSimple(newMetric) && isSimpleMetricValid(newMetric)) ||
        (isBIMetricFormula(newMetric) && isFormulaMetricValid(newMetric))),
    [JSON.stringify(newMetric), isComplete]
  );

  /**
   * this should be triggered also when the metric is definition is complete.
   */
  useEffect(() => {
    if (
      (isBIMetricSimple(newMetric) && isSimpleMetricCanPreview(newMetric)) ||
      (isBIMetricFormula(newMetric) && isFormulaMetricCanPreview(newMetric))
    ) {
      manageMetricVisualization('preview', newMetric as BIMetricCreated);
    }
  }, [JSON.stringify(newMetric)]);

  const handleBackToMetrics = (): void => {
    if (isEditing) {
      manageMetricVisualization('undo');
    } else {
      manageMetricVisualization('remove');
    }

    setIsCreateOrEditMetricMode(false);
  };

  const triggerChange = useCallback(
    debounce(
      (value: string) => setNewMetric((prev) => ({ ...prev, name: value })),
      750
    ),
    [metric]
  );

  const handleDescriptionChange = (value: string | undefined): void => {
    setNewMetric((prev) => ({ ...prev, name_identifier: value }));
  };

  const handleChangeMetricName = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    triggerChange(e.target.value);
    setMetricName(e.target.value);
  };

  const handleChangeMetricDefinitionInputs = (
    complete: boolean,
    metricInputs: Partial<BIMetricUnion>
  ): void => {
    // complete is true when all mandatory inputs are ready.
    if (complete) {
      setIsComplete(true);
      setNewMetric((prev) => ({ ...prev, ...metricInputs }));
    } else {
      setIsComplete(false);
    }
  };

  return (
    <>
      <div className={breadcrumbs}>
        <a onClick={handleBackToMetrics}>Metrics List</a> /{' '}
        <span>{isEditing ? 'Edit metric' : 'Create new metric'}</span>
      </div>
      <div className={metricsCreateContainer}>
        <div className={inputsContainer}>
          <MetricCreateSubTitle>Metric name</MetricCreateSubTitle>
          <BuInput
            placeholder="Enter a metric Name"
            value={metricName}
            testingLabel="metric-name"
            type="text"
            onChange={handleChangeMetricName}
          />
        </div>

        <MetricDescriptionInput
          onMetricDescriptionChange={handleDescriptionChange}
          metricDescription={newMetric.name_identifier}
        />
        <MetricDefinitionInputs
          key={(newMetric as BIMetricCreated)._id ?? 'new'}
          isForecastSubmissionWidget={isForecastSubmissionWidget}
          metric={newMetric}
          onChangeMetricDefinitionInputs={handleChangeMetricDefinitionInputs}
        />
        <div className={actionButtonsContainer}>
          <BuButton secondary onClick={handleBackToMetrics}>
            Cancel
          </BuButton>
          <BuButton onClick={() => onSaveMetric(newMetric)} disabled={!canSave}>
            Save Metric
          </BuButton>
        </div>
      </div>
    </>
  );
};
