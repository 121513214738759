import React, { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

import BuSelect from 'components/UI/BuSelect';
import { ISelectOption } from 'components/UI/BuSelect/types';
import { ForecastOption } from 'components/dashboard/Metrics/Create/MetricDefinitionInputs/SimpleMetricsInputs/types';
import { inputsContainer } from 'components/dashboard/Metrics/Create/MetricDefinitionInputs/styles';
import { MetricCreateSubTitle } from 'components/dashboard/Metrics/metrics.styles';
import { QueryStatus, fetchApi } from 'utils/network';

interface Props {
  selectedForecastSubmissionType?: string;
  metricObject: string;
  updateForecastSubmissionType: (
    complete: boolean,
    forecastSubmissionType: ForecastOption
  ) => void;
}

export const ForecastSubmissionType: React.FC<Props> = ({
  selectedForecastSubmissionType,
  metricObject,
  updateForecastSubmissionType,
}) => {
  const [forecastSubmissionTypes, setForecastSubmissionTypes] = useState<
    ForecastOption[]
  >([]);
  const [forecastSubmissionTypesStatus, setForecastSubmissionTypesStatus] =
    useState<QueryStatus>('notAsked');

  useEffect(() => {
    const abortControllerForecastSubmissionType = new AbortController();

    fetchApi<void, ForecastOption[]>({
      url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/forecast_submission/get_type_options`,
      queryMethod: 'get',
      setData: (result) => {
        setForecastSubmissionTypes(result);
        if (selectedForecastSubmissionType) {
          const type = result.find(
            (e) => e.forecast_submission_type === selectedForecastSubmissionType
          );

          if (type) {
            updateForecastSubmissionType(true, type);
          }
        }
      },
      setError: (error: string | null) => {
        setForecastSubmissionTypes([]);
        toast.error(
          `Failed to fetch forecast submission type values: ${error}`
        );
      },
      setStatus: setForecastSubmissionTypesStatus,
      signal: abortControllerForecastSubmissionType.signal,
    });

    return () => {
      abortControllerForecastSubmissionType.abort();
      setForecastSubmissionTypes([]);
    };
  }, [metricObject]);

  const handleChangeForecastSubmissionType = (values: string[]): void => {
    const [value] = values;
    const type = forecastSubmissionTypes.find(
      (e) => e.forecast_submission_type === value
    );

    if (type) {
      updateForecastSubmissionType(true, type);
    }
  };

  const options: ISelectOption[] = useMemo(
    () =>
      forecastSubmissionTypes.map((value: ForecastOption) => ({
        text: value.label,
        value: value.forecast_submission_type,
      })),
    [forecastSubmissionTypes.length]
  );

  return (
    <div className={inputsContainer} data-testing="forecast-submission-type">
      <MetricCreateSubTitle>Forecast Submission Type</MetricCreateSubTitle>
      <BuSelect
        fullWidth
        isLargePlaceholder
        secondary
        placeholder="Select a submission type"
        defaults={[selectedForecastSubmissionType ?? '']}
        disabled={
          !forecastSubmissionTypes.length ||
          forecastSubmissionTypesStatus !== 'success'
        }
        options={options}
        onChange={handleChangeForecastSubmissionType}
        testingLabel="forecast-submission-type-select"
      />
    </div>
  );
};
