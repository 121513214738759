import * as momentTableStyles from '../MomentsTable/styles';
import { css } from 'emotion';

export const whiteWrapper = css`
  padding: 30px;
  background: white;
  margin-bottom: 20px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
`;

export const apiError = css`
  padding: 20px 5px;
  background: white;
  margin-bottom: 20px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
`;

export const wrapper = css`
  margin-bottom: 20px;
`;

export const timespanSelector = css`
  display: flex;
  justify-content: flex-end;
  white-space: nowrap;
`;

export const buttons_box_content = css`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-right: 10px;
  align-items: center;
`;

export const section_header = css`
  color: var(--bu-gray-900);
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

export const buttons_box = css`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const btn_tab = css`
  display: inline-block;
  padding: 5px 10px;
  margin-right: 15px;
  align-items: center;
  cursor: pointer;
  font-family: var(--bu-font-regular);
  font-size: 13px;
  font-weight: 300;
  color: #777777;
  border-radius: 2px;
  background-color: #f4f7f8;
  outline: 0;
  border: none;
  transition: 0.4s;
  width: 100%;
  height: 30px;
  max-width: 220px;
  min-width: 150px;

  &.active {
    color: var(--bu-white);
    background-color: #4a4a4a;
  }

  &:hover {
    color: var(--bu-white);
    background-color: #4a4a4a;
  }
`;

export const { table } = momentTableStyles;
