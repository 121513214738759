import { useRecalculateManageForecastModal } from './ManageForecastModal.helper';
import styled from '@emotion/styled';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { pick } from 'ramda';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from 'semantic-ui-react';

import { actions } from 'actions';
import { refreshDeals } from 'actions/dealsActions';
import InfoIcon from 'assets/fonts/boostup-icons/badge_info_outline.svg';
import { CHANGE_INTERVAL_OPTIONS, FILTER_NAME } from 'common/constants';
import DealsTable from 'components/UI/DealsFlatTableConfig/DealsTable.container';
import { QueryParams } from 'components/UI/DealsFlatTableTS/Table/types';
import OpenFiltersPanel from 'components/UI/OpenFiltersPanel';
import RecalculateSection from 'components/UI/RecalculateSection';
import { DisplayedColumns } from 'components/UI/TableConfig/TableControls';
import TooltipWrapper from 'components/UI/common/TypedTable/renderers/common/TooltipWrapper';
import Badge from 'components/dashboard/Forecast/Dashboard/Badge';
import { useLocalStorage } from 'components/hooks/useLocalStorage';
import * as styles from 'components/modals/ManageForecastModal/styles';
import {
  getInheritedFilterValuesForModal,
  haveFiltersConfigurated,
} from 'reducers/filters/helpers';
import { IReduxState } from 'reducers/types';
import {
  getFiltersForAPI,
  getIsFiltersOnModalsEnabled,
  getIsRealTimeEnabled,
  getUser,
} from 'selectors';

const TABS = ['current deals', 'changed deals'];

const TableContainer = styled.div<{ showChangedDeals?: boolean }>`
  min-height: 40vh;

  .container .container-dashboard {
    height: ${({ showChangedDeals }) =>
      showChangedDeals ? 'calc(70vh - 43px)' : '70vh'};
  }
`;

const RECALCULATE_BUTTON_COPY =
  'Changes were made to opportunities and these may affect column total value and deal count. Rollups data will be refreshed upon closing this popup or switching tabs.';

const ManageForecastModal = () => {
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const filters = useSelector((state: IReduxState) =>
    getFiltersForAPI(state, FILTER_NAME.ForecastOpportunitiesModal)
  );

  const isRealTimeEnabled = useSelector(getIsRealTimeEnabled);

  const {
    modalProps,
    hasToRecalculate,
    someDealUpdateIsLoading,
    onRecalculate,
  } = useRecalculateManageForecastModal();

  useEffect(() => {
    if (modalProps.isRecalculating) {
      dispatch(refreshDeals());
    }
  }, [modalProps.isRecalculating]);

  const isFiltersOnModalsEnabled = useSelector(getIsFiltersOnModalsEnabled);
  const [sortColumnFromLocalStorage, setSortColumnToLocalStorage] =
    useLocalStorage(
      `${modalProps.localStorageKeyPrefix}SortColumn:${user.email}`,
      ''
    );

  const showChangedDeals =
    !modalProps.context?.includes('Booked') && !!modalProps.delta;
  const [tab, setTab] = useState(
    !showChangedDeals ? TABS[0] : modalProps.subtab
  );

  const onTabClick = (tab: string) => {
    setTab(tab);
    if (hasToRecalculate) {
      onRecalculate();
    }
  };

  const rawFilters: QueryParams = Object.assign({}, modalProps);
  const updatedFilters = pick(
    [
      'managers',
      'business_type_name',
      'split_view',
      'change_interval',
      'visibility',
      'include_disabled',
      'amount_field',
    ],
    rawFilters
  );

  const rollupsRecalculating = modalProps.isRecalculating;

  const modalFilters = useSelector((state) =>
    getFiltersForAPI(
      state as IReduxState,
      FILTER_NAME.ForecastOpportunitiesModal
    )
  );

  const filtersForModal = useMemo(
    () =>
      modalProps.filtersForModalFilterPanel
        ? getInheritedFilterValuesForModal(
            modalFilters,
            modalProps.filtersForModalFilterPanel
          )
        : [],
    [JSON.stringify(filters), JSON.stringify(rawFilters)]
  );

  useEffect(() => {
    dispatch(
      actions.ui.filters.setForecastModalFilters({
        filters: filtersForModal,
        tab: FILTER_NAME.ForecastOpportunitiesModal,
      })
    );
  }, [JSON.stringify(filtersForModal)]);

  if (isEmpty(modalProps)) {
    return <Loader active />;
  }

  const amountFieldName = updatedFilters.amount_field?.split('.').pop();

  const isChangedDealsTab = tab !== TABS[0];

  const thereAreFiltersForModal = filtersForModal.length > 0;

  const sharedFilters = {
    ...filters,
    ...updatedFilters,
    ...(thereAreFiltersForModal ? modalFilters : {}),
  } as QueryParams;

  const changedDealsFilters = {
    ...sharedFilters,
    current_ids: modalProps.dealIds,
    prev_ids: modalProps.prevDealsIds,
    only_with_changes: true,
    table_id: modalProps.table_id,
    column_name: modalProps.column_name,
    skip_business_validation: true,
    now: modalProps.now,
  };

  const currentDealsFilters = {
    ...sharedFilters,
    ids: modalProps.dealIds,
    only_with_changes: false,
    page_size: 50,
    sort: sortColumnFromLocalStorage,
  };

  const changeInterval = updatedFilters.change_interval
    ? updatedFilters.change_interval[0] || ''
    : '';
  const timeInterval = changeInterval
    ? CHANGE_INTERVAL_OPTIONS[changeInterval]
    : '';

  const getAmountFieldHighlightIcon = (
    displayedColumns: DisplayedColumns
  ): JSX.Element => {
    const amountFieldColumnName = displayedColumns?.find(
      (column) =>
        column.field &&
        amountFieldName &&
        column.field.indexOf(amountFieldName) !== -1
    )?.label;

    if (
      updatedFilters.amount_field &&
      (amountFieldColumnName || rawFilters.opportunity_column_name)
    ) {
      return (
        <TooltipWrapper
          position="top center"
          tooltip={
            <span>
              This metric's value and Delta are mapped to the custom field{' '}
              <span style={{ fontWeight: 'bold' }}>
                {amountFieldColumnName || rawFilters.opportunity_column_name}
              </span>
              .
              {!amountFieldColumnName &&
                rawFilters.opportunity_column_name &&
                ' According to your company account settings, this column has been hidden in this table.'}
            </span>
          }
        >
          <img
            className={classNames(styles.amountFieldHighlightIcon, 'ui')}
            src={InfoIcon}
            alt="info"
          />
        </TooltipWrapper>
      );
    }

    return <></>;
  };

  const showFilters =
    isFiltersOnModalsEnabled && haveFiltersConfigurated(modalFilters || {});

  return (
    <div>
      {showFilters && (
        <OpenFiltersPanel
          tab={FILTER_NAME.ForecastOpportunitiesModal}
          isModal
        />
      )}

      {showChangedDeals && (
        <div className={classNames('modal-tab-container', styles.tabContainer)}>
          {TABS.map((t) => (
            <TooltipWrapper
              tooltip={
                <p className={styles.tabTooltip}>
                  Changes were made to opportunities and these may affect column
                  total value and deal count. Rollups data will be refreshed
                  upon switching tabs
                </p>
              }
              disable={!hasToRecalculate}
            >
              <div
                key={t}
                onClick={() => onTabClick(t)}
                className={styles.tab(tab === t)}
              >
                {t}
              </div>
            </TooltipWrapper>
          ))}
        </div>
      )}

      <TableContainer
        className={styles.root}
        showChangedDeals={showChangedDeals}
      >
        <div
          className={classNames(
            !isChangedDealsTab && styles.hiddenTableContainer
          )}
        >
          {showChangedDeals && (
            <DealsTable
              apiUrl="/api/data/deals/changed/"
              inModal
              title={modalProps.context}
              renderTitleExtra={(displayedColumns) => (
                <>
                  <div className={classNames(styles.badgeContainer)}>
                    <Badge value={modalProps.delta} />
                  </div>

                  {getAmountFieldHighlightIcon(displayedColumns)}

                  {timeInterval && (
                    <div className={classNames(styles.timeIntervalContainer)}>
                      Changes since {timeInterval}
                    </div>
                  )}

                  {hasToRecalculate && (
                    <RecalculateSection
                      tooltipMessage={RECALCULATE_BUTTON_COPY}
                      onRecalculate={onRecalculate}
                      recalculateDisabled={someDealUpdateIsLoading}
                    />
                  )}
                </>
              )}
              dataType="Deals"
              initialRequest={{
                ...currentDealsFilters,
                ...changedDealsFilters,
              }}
              forecastTable
              persistName={`deals_modal_${modalProps.context}`}
              tableConfigCollection="opportunity"
              tableConfigName={'DealsDelta'}
              amountFieldName={amountFieldName}
              localStorageSortColumn={sortColumnFromLocalStorage}
              onSortChangePersistLocally={setSortColumnToLocalStorage}
              forceBasicFilters
              showTotalAmount={false}
              isTableConfigurationLoading={rollupsRecalculating}
              isRealTimeEnabledForTable={isRealTimeEnabled}
              extendedCSVDownload={false}
            />
          )}
        </div>

        <div
          className={classNames(
            isChangedDealsTab && styles.hiddenTableContainer
          )}
        >
          <DealsTable
            inModal
            title={modalProps.context}
            renderTitleExtra={(displayedColumns) => (
              <>
                {getAmountFieldHighlightIcon(displayedColumns)}

                {hasToRecalculate && (
                  <RecalculateSection
                    tooltipMessage={RECALCULATE_BUTTON_COPY}
                    onRecalculate={onRecalculate}
                    recalculateDisabled={someDealUpdateIsLoading}
                  />
                )}
              </>
            )}
            dataType="Deals"
            tableConfigCollection="opportunity"
            tableConfigName="Opportunities"
            amountFieldName={amountFieldName}
            persistName={`deals_modal_${modalProps.context}`}
            initialRequest={currentDealsFilters}
            forecastTable
            localStorageSortColumn={sortColumnFromLocalStorage}
            onSortChangePersistLocally={setSortColumnToLocalStorage}
            forceBasicFilters
            isTableConfigurationLoading={rollupsRecalculating}
            isRealTimeEnabledForTable={isRealTimeEnabled}
          />
        </div>
      </TableContainer>
    </div>
  );
};

export default ManageForecastModal;
