import {
  AggregationDropdownContainer,
  ColumnDropdownContainer,
  OptionGroup,
} from './styles';
import React, { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

import { sortAlphabetically } from 'common/helpers';
import BuSelect from 'components/UI/BuSelect';
import { ISelectOption } from 'components/UI/BuSelect/types';
import {
  AGGREGATION_OPTIONS,
  AnalysisType,
} from 'components/dashboard/Metrics/constants';
import { AggregationFunction } from 'components/dashboard/Metrics/enums';
import {
  MetricCreateSubTitle,
  FlexRow,
} from 'components/dashboard/Metrics/metrics.styles';
import {
  BIMetricSimple,
  BIMetricSimpleNewborn,
  BIColumnListItem,
} from 'components/dashboard/Metrics/metrics.types';
import { QueryStatus, fetchApi } from 'utils/network';

interface Props {
  readonly aggregationFunction: AggregationFunction;
  readonly analysisType: AnalysisType;
  readonly metricColumn: BIColumnListItem | undefined | null;
  readonly metricObject: string;
  readonly updatePropertyMetric: (
    complete: boolean,
    metric: Partial<BIMetricSimple | BIMetricSimpleNewborn>
  ) => void;
}

export const MetricCreateSelectField: React.FC<Props> = ({
  aggregationFunction,
  analysisType,
  metricColumn,
  metricObject,
  updatePropertyMetric,
}) => {
  const [aggregation, setAggregation] = useState<AggregationFunction>();
  const [column, setColumn] = useState<BIColumnListItem>();
  const [columnList, setColumnList] = useState<BIColumnListItem[]>([]);
  const [columnsStatus, setColumnsStatus] = useState<QueryStatus>('notAsked');

  const columnOptions: ISelectOption[] = useMemo(() => {
    const types = aggregation === 'count' ? ['text'] : ['money', 'number'];

    return sortAlphabetically(
      columnList
        .filter((column) => types.includes(column.type))
        .map((column) => ({
          text: column.label,
          value: column.name,
        }))
    );
  }, [aggregation, columnList]);

  const handleChangeAggregation = (values: string[]): void => {
    setAggregation(values[0] as AggregationFunction);
    setColumn(undefined);
  };

  const handleChangeColumn = (values: string[]): void => {
    const selectedColumn = columnList.find(
      (oppColumn) => values[0] === oppColumn.name
    );
    setColumn(selectedColumn);
  };

  useEffect(() => {
    aggregationFunction && setAggregation(aggregationFunction);
  }, [aggregationFunction]);

  useEffect(() => {
    metricColumn && setColumn(metricColumn);
  }, [JSON.stringify(metricColumn)]);

  useEffect(() => {
    const abortController = new AbortController();

    if (analysisType === AnalysisType.LIVE) {
      fetchApi({
        url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/external/get_column_fields/${metricObject}`,
        queryMethod: 'get',
        setData: (fields: BIColumnListItem[]) => {
          setColumnList(fields);
        },
        setError: (error: string | null) => {
          setColumnList([]);
          toast.error(`Failed to load column fields: ${error}`);
        },
        setStatus: setColumnsStatus,
        signal: abortController.signal,
      });
    }

    if (analysisType === AnalysisType.HISTORICAL) {
      fetchApi({
        url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/external/get_column_fields_filter_history`,
        queryMethod: 'get',
        setData: (fields: BIColumnListItem[]) => {
          setColumnList(fields);
        },
        setError: (error: string | null) => {
          setColumnList([]);
          toast.error(`Failed to load historical column fields: ${error}`);
        },
        setStatus: setColumnsStatus,
        signal: abortController.signal,
      });
    }

    return () => {
      setColumnList([]);
      abortController.abort();
    };
  }, [metricObject, analysisType]);

  /**
   * when the section is complete and valid it triggers the change to its container.
   */
  useEffect(() => {
    const complete = !!(aggregation && column?.name);
    const metricSection = {
      aggregation_function: aggregation,
      column,
    };
    updatePropertyMetric(complete, metricSection);
  }, [aggregation, column]);

  return (
    <div data-testing="property-section">
      <MetricCreateSubTitle>Property</MetricCreateSubTitle>
      <OptionGroup>
        <FlexRow>
          <AggregationDropdownContainer>
            <BuSelect
              options={AGGREGATION_OPTIONS}
              onChange={handleChangeAggregation}
              secondary
              defaults={[aggregation ?? '']}
              fullWidth
              testingLabel="operator"
            />
          </AggregationDropdownContainer>
          <ColumnDropdownContainer>
            <BuSelect
              fullWidth
              isLargePlaceholder
              searchable
              secondary
              options={columnOptions}
              onChange={handleChangeColumn}
              placeholder="Select a column"
              defaults={[column ? column.name : '']}
              className="select"
              testingLabel="operator-column"
              disabled={!columnOptions.length || columnsStatus !== 'success'}
            />
          </ColumnDropdownContainer>
        </FlexRow>
      </OptionGroup>
    </div>
  );
};
