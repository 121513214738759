import { ColumnTypes } from '..';
import {
  IDataCellProps,
  ValueProp,
  ValueType,
  TypedTableCellConfig,
  IColumn,
  IRow,
  IRowWithChangesSince,
} from '../../TypedTable';
import MultiCurrencyIconTooltip from './MultiCurrencyIconTooltip';
import classNames from 'classnames';
import { css } from 'emotion';
import isNumber from 'lodash/isNumber';
import moment from 'moment';
import * as R from 'ramda';
import React, { PropsWithChildren, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Popup } from 'semantic-ui-react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuIcon from 'components/UI/BuIcon';
import styles from 'components/UI/DealsFlatTableTS/Table/styles';
import {
  DropDownCellConfig,
  isOptionsFunction,
} from 'components/UI/common/TypedTable/renderers/DropDownCell';
import { conditionSubTitle } from 'components/dashboard/Metrics/Create/Conditions/styles';
import * as selectors from 'selectors';

export interface IDeltaValueType {
  value: null | ValueType;
  prev_value: null | ValueType;
  updated_at: string;
  latest_updated_at: null | ValueType;
  latest_value: null | ValueType;
}

export const getValueFromDelta = (field: ValueProp): null | ValueType => {
  if (field instanceof Object) {
    return (field as IDeltaValueType).value;
  }

  return field;
};

const deltaContainer = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;

  &.icon {
    font-size: 0;
  }

  .children {
    width: 95%;

    &.has-indicator {
      width: 84%;
    }
  }
`;

const popupWidth = css`
  min-width: 300px;
  max-height: 280px;
`;

const arrowWrapper = css`
  align-self: center;
  text-align: center;
  line-height: 1;
  font-size: 6px;
  padding: 5px;
  border-radius: 4px;

  &.up {
    background-color: var(--bu-green-500);
    color: var(--bu-white);
  }

  &.down {
    background-color: var(--bu-red-400);
    color: var(--bu-white);
  }
`;

const deltaIconSpaceHolder = css`
  display: inline-block;
  width: 20px;

  &.has-multicurrency-icon {
    width: 23px;
  }
`;

const multicurrencyPlaceholder = css`
  width: 5%;
  padding: 5px 0;
  margin-right: 13px;

  &::after {
    content: '';
  }
`;

const defaultFormatter = (value: ValueProp) => `${value}`;

interface IDeltaValueProps extends IDataCellProps {
  formatter?: TypedTableCellConfig['formatter'];
  formatterExtraOptions?: ValueProp[];
  row: IRowWithChangesSince;
}

const dateToNumber = (s: string): number => new Date(s).getTime();
const getArrIndex = (c: string, arr: string[] = []): number => arr.indexOf(c);

enum Indicator {
  UP = 'up',
  DOWN = 'down',
}
const getIndicatorType = ({
  cell,
  column,
  row,
  rows,
}: {
  cell: IDeltaValueType;
  column: IColumn;
  row: IRow;
  rows: IRow[];
}) => {
  if (!R.isNil(cell.value) && !R.isNil(cell.prev_value)) {
    if (isNumber(cell.value)) {
      if (cell.value < cell.prev_value) {
        return Indicator.DOWN;
      } else if (cell.value > cell.prev_value) {
        return Indicator.UP;
      }
    } else if (column.type === ColumnTypes.DATE) {
      const value = dateToNumber(cell.value as string);
      const prevValue = dateToNumber(cell.prev_value as string);

      if (value < prevValue) {
        return Indicator.UP;
      } else if (value > prevValue) {
        return Indicator.DOWN;
      }
    } else if (column.type === ColumnTypes.DROPDOWN) {
      const config = column.config as DropDownCellConfig;
      const options = (
        (config.options &&
          (isOptionsFunction(config.options)
            ? config.options({ column, row, rows })
            : config.options)) ||
        []
      ).map((item) => item.text);
      const value = getArrIndex(cell.value as string, options as string[]);
      const prevValue = getArrIndex(
        cell.prev_value as string,
        options as string[]
      );

      if (value < prevValue) {
        return Indicator.DOWN;
      } else if (value > prevValue) {
        return Indicator.UP;
      }
    } else {
      return Indicator.UP;
    }
  }

  return null;
};

const DeltaValueCell = ({
  column,
  row,
  rows,
  children,
  formatter = defaultFormatter,
  formatterExtraOptions = [],
}: PropsWithChildren<IDeltaValueProps>) => {
  const cell = R.path(column.field.split('.'), row) as IDeltaValueType;

  /* TODO: get currency from the column config */
  const userCurrency = useSelector(selectors.getUserLocalCurrency);

  if (!column.delta) {
    return <div>{children}</div>;
  }

  const showMulticurrencyPlaceholder = useMemo(() => {
    if (!column.config.isMoney || !column.config.isMulticurrency) return false;

    return rows.some(
      (row) =>
        (row.crm_metadata as any).currency &&
        (row.crm_metadata as any).currency !== userCurrency
    );
  }, [column, rows]);

  if (!(cell instanceof Object)) {
    return (
      <div style={{ height: 28, width: '90%' }}>
        {children}
        <div
          className={classNames({
            [deltaIconSpaceHolder]: column.type !== 'score',
            'has-multicurrency-icon': showMulticurrencyPlaceholder,
          })}
        />
      </div>
    );
  }

  const indicatorType = getIndicatorType({ cell, column, row, rows });

  const trigger =
    cell.prev_value !== null && indicatorType !== null ? (
      <div className={classNames(styles.pointer, arrowWrapper, indicatorType)}>
        {indicatorType === Indicator.UP ? (
          <BuIcon name={BoostUpIcons.ChangeUp} />
        ) : (
          <BuIcon name={BoostUpIcons.ChangeDown} />
        )}
      </div>
    ) : (
      <div
        className={classNames({
          [deltaIconSpaceHolder]: column.type !== 'score',
          'has-multicurrency-icon': showMulticurrencyPlaceholder,
        })}
      />
    );

  const changesSinceCopy = row.changesSinceCopy;
  const changesSinceDate = row.changesSinceDate;
  const currentValue = cell.value;
  const previousChangeBasedOnChangesSince = cell.prev_value;
  const latestChangeDate = cell.latest_updated_at;
  const latestUpdateFrom = cell.latest_value;

  return (
    <div className={deltaContainer}>
      {column.config.isMoney &&
      column.config.isMulticurrency &&
      row.crm_metadata &&
      (row.crm_metadata as any).currency &&
      (row.crm_metadata as any).currency !== userCurrency ? (
        <MultiCurrencyIconTooltip column={column} row={row} />
      ) : showMulticurrencyPlaceholder ? (
        <div className={multicurrencyPlaceholder}></div>
      ) : null}
      <div
        className={classNames('children', {
          'has-indicator': indicatorType != null,
        })}
      >
        {children}
      </div>
      {indicatorType != null ? (
        <Popup
          trigger={trigger}
          position={column.config.popupPosition || 'bottom center'}
          mouseEnterDelay={500}
          offset={[0, 0]}
        >
          <div className={classNames(styles.popupText, popupWidth)}>
            <div>
              Changed from{' '}
              <span className={styles.popupTextBold}>
                {formatter(
                  previousChangeBasedOnChangesSince,
                  ...formatterExtraOptions
                )}
              </span>{' '}
              to{' '}
              <span className={styles.popupTextBold}>
                {formatter(currentValue, ...formatterExtraOptions)}
              </span>
            </div>
            <div>
              Delta is based on Changes Since {changesSinceCopy} (
              <span className={styles.popupTextBold}>{changesSinceDate})</span>
            </div>
            {latestChangeDate && (
              <div>
                Latest Change: on{' '}
                <span className={styles.popupTextBoldNoUppercase}>
                  {moment(latestChangeDate as string).format("MMM Do 'YY")}
                </span>
                , previous value was{' '}
                <span className={styles.popupTextBold}>
                  {formatter(latestUpdateFrom, ...formatterExtraOptions)}
                </span>
              </div>
            )}
          </div>
        </Popup>
      ) : (
        <div
          className={classNames({
            [deltaIconSpaceHolder]: column.type !== 'score',
            'has-multicurrency-icon': showMulticurrencyPlaceholder,
          })}
        />
      )}
    </div>
  );
};

export default DeltaValueCell;
