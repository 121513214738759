import React, { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

import BuSelect from 'components/UI/BuSelect';
import { BUSINESS_TYPES_FIELD_NAMES } from 'components/dashboard/Metrics/constants';
import {
  BIColumnListItem,
  BIMetricsFilter,
  BIPicklistOption,
} from 'components/dashboard/Metrics/metrics.types';
import { fetchApi } from 'utils/network';

interface Props {
  templateFilter?: BIMetricsFilter;
  widgetFilterLabel: string;
  widgetFilterName: string;
  widgetFilterType: string;
  updateFilters: (
    emptyFilters: boolean,
    operator: string,
    value: string | string[] | number
  ) => void;
}

const ALL_VALUE = '__all__';

export const SingleSelectFilter: React.FC<Props> = ({
  templateFilter,
  widgetFilterLabel,
  widgetFilterName,
  widgetFilterType,
  updateFilters,
}) => {
  const [picklistValues, setPicklistValues] = useState<BIPicklistOption[]>([]);

  useEffect(() => {
    fetchApi<BIColumnListItem, BIPicklistOption[]>({
      url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/company_settings/get_column_values`,
      queryMethod: 'post',
      queryParams: {
        label: widgetFilterLabel,
        name: widgetFilterName,
        type: widgetFilterType,
      },
      setData: (result: BIPicklistOption[]) => {
        const allIndex = result.findIndex(
          (v) => v.value.toLocaleLowerCase() === 'all'
        );

        if (allIndex !== -1) {
          // Special case for companies like Redsift which have 'All' business type
          result[allIndex] = {
            ...result[allIndex],
            value: '-ALL-',
            display_name: '-ALL-',
          };
        }

        setPicklistValues(
          // Filter to remove duplicates which are causing issues
          result.filter(
            (v, i, a) => a.findIndex((v2) => v2.value === v.value) === i
          )
        );
      },
      setError: (error: string | null) => {
        toast.error(`Failed to load picklist values: ${error}`);
      },
    });

    return () => {
      setPicklistValues([]);
    };
  }, []);

  const selectorOptions = useMemo(
    () =>
      picklistValues.map((value: BIPicklistOption) => ({
        text: value.display_name,
        value: value.value,
      })),
    [picklistValues]
  );

  const defaultsValues = useMemo(() => {
    return templateFilter
      ? // Special case for companies like Redsift which have 'All' business type
        (templateFilter.value as string[])[0] === 'All'
        ? ['-ALL-']
        : [(templateFilter.value as string[])[0]]
      : [ALL_VALUE];
  }, [templateFilter]);

  const handleSingleSelectValueChange = (values: string[]): void => {
    updateFilters(
      values[0] === ALL_VALUE,
      'in',
      values[0] === '-ALL-' ? ['All'] : values // Special case for companies like Redsift which have 'All' business type
    );
  };

  return (
    <BuSelect
      secondary
      defaults={defaultsValues}
      options={selectorOptions}
      inlineLabel={
        BUSINESS_TYPES_FIELD_NAMES.has(widgetFilterName)
          ? 'Business Type'
          : widgetFilterLabel
      }
      onChange={handleSingleSelectValueChange}
      testingLabel={`SingleSelectFilter`}
    />
  );
};
