import { css } from 'emotion';

export const metrics = css`
  display: block;
`;

export const metrics_main = css`
  margin-top: 20px;
  z-index: 1;
  position: relative;
`;

export const totalPanelWrapper = css`
  display: flex;
  flex-direction: column;
  -webkit-transition: 0.35s;
  -o-transition: 0.35s;
  transition: 0.35s;
  visibility: visible;
  overflow: hidden;
  max-height: 100vh;

  &.hidden_panel {
    visibility: hidden;
    max-height: 0;
  }
`;

export const totalPanelRow = css`
  display: grid;
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-top: 10px;
  z-index: 0;
  overflow-x: visible;
  scroll-behavior: smooth;

  &:first-child {
    margin-top: 0;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
    width: 0;
  }
`;

export const totalPanel = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 67px;
  transition: all 0.2s ease-out;
  font-family: var(--bu-font-regular);
  border: 1px solid var(--bu-gray-400);
  border-radius: var(--bu-control-border-radius);
  text-align: left;
  font-size: 14px;
  outline: none;
  margin-bottom: 8px;
  margin-right: 8px;
  cursor: pointer;
  background-color: var(--bu-white);

  &.not_clickable {
    cursor: default;
  }

  &:nth-child(6n) {
    margin-right: 0;
  }

  &.is_selected {
    border: 1px solid var(--bu-primary-500);

    .total-currency {
      color: var(--bu-primary-700);
    }
  }

  &.not_selected {
    .total-title,
    .total-footer,
    .total-currency,
    i {
      color: var(--bu-gray-500) !important;
    }
  }

  &:focus {
    outline: none;
  }

  &:hover:not(.is_selected, .not_clickable) {
    background-color: var(--bu-gray-200);
  }
`;

export const totalBox = css`
  padding: 12px 20px;
`;

export const details = css`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: var(--bu-gray-700);
`;

export const highRisk = css`
  color: red;
`;

export const totalFooter = css`
  display: flex;
  align-items: center;
  justify-content: left;
  flex-wrap: wrap;
`;

export const totalTitle = css`
  color: var(--bu-gray-900);
  font-family: var(--bu-font-regular);
  font-weight: normal;
  white-space: nowrap;
  line-height: 1.1;
  margin-bottom: 4px;
  display: flex;
  font-size: 13px;

  i {
    margin-left: 8px;
  }
`;

export const truncateTitle = css`
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;

  @media (min-width: 1000px) and (max-width: 1099px) {
    max-width: 45px;
  }

  @media (min-width: 1100px) and (max-width: 1199px) {
    max-width: 65px;
  }

  @media (min-width: 1200px) and (max-width: 1400px) {
    max-width: 100px;
  }
`;

export const totalCurrency = css`
  color: var(--bu-gray-900);
  font-family: var(--bu-font-medium);
  font-size: 18px;
  letter-spacing: 0;
  line-height: 20px;
`;

export const changedValue = (type: string | any) => css`
  color: ${type === 'up' ? 'var(--bu-green-500)' : 'var(--bu-red-500)'};
  background-color: ${type === 'up' ? '#EAFAF0' : '#FFEBEB'};
  font-family: var(--bu-font-regular);
  font-size: 12px;
  letter-spacing: 0;
  margin-left: 2px;
  padding: 2px 5px 2px 0px;
  border-radius: 2px;

  &:before {
    content: '';
    display: inline-block;
    width: 0;
    height: 0;
    vertical-align: middle;
    margin: 0 3px;
  }
`;
