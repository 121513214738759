import styled from '@emotion/styled';

export const WidgetPreviewColumn = styled.div({
  flexGrow: 1,
  background: 'var(--bu-gray-200)',
  alignItems: 'center',
  padding: '24px 48px',
  overflow: 'auto',
});

export const WidgetPreviewContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '32px',
  marginBottom: '48px',
});
