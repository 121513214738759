import * as t from 'actions/actionTypes';
import { ViewBy } from 'common/enums/filters';
import { PersistedTableItem } from 'components/UI/DealsFlatTableTS/Table/types';
import { WidgetStateRecord } from 'components/chart-dashboards/Widget/types';
import { PersistQueryParams, ModalsScheme } from 'navigation/types';
import { UserPreferences } from 'sagas/preferences';
import { actionCreator } from 'utils/factories';

export const modal = {
  persist: actionCreator<PersistQueryParams>(t.MODAL + t.PERSIST),
  remove: actionCreator<{ scheme: ModalsScheme }>(t.MODAL + t.REMOVE),
};

export const preferences = {
  user: {
    get: actionCreator('PREFERENCES_USER_GET'),
    loaded: actionCreator<UserPreferences>('PREFERENCES_USER_LOADED'),
    post: actionCreator('PREFERENCES_USER_POST'),
  },
};

export const appState = {
  persistMetricsStatus: actionCreator<{ path: string; expanded: boolean }>(
    t.SET + t.HIDE + t.METRICS + t.STATUS
  ),
  resizeColumn: actionCreator<{
    path: string;
    column: { width: string; id: string };
  }>(t.TABLE + t.SET + t.SAVE_PAGE_SIZE_OF_TABLE),
  setShowOrHideColumns: actionCreator<{
    path: string;
    column: { label: string; id: string; show: boolean };
  }>(t.TABLE + t.SET + t.SHOW_OR_HIDE_TABLE_COLUMN),
  replaceShowOrHideColumns: actionCreator<{
    path: string;
    columns: { label: string; id: string; show: boolean }[];
  }>(t.TABLE + t.SET + t.ALL + t.SHOW_OR_HIDE_TABLE_COLUMN),
  setSequenceColumns: actionCreator<{
    path: string;
    columns: string[];
  }>(t.TABLE + t.SET + t.SEQUENCE + t.UPDATE),
};

export const table = {
  persist: actionCreator<PersistedTableItem>(t.TABLE + t.PERSIST),
  reset: actionCreator(t.TABLE + t.RESET),
};

export const filters = {
  clearAll: actionCreator<Filters.ClearFiltersParams>(
    t.FILTER + t.CLEAR + t.ALL
  ),
  init: actionCreator<Filters.PersistUserParams>('FILTERS_INIT'),
  loaded: actionCreator<Filters.FiltersLoadedParams>('FILTERS_LOADED'),
  reloadRollUpsFilters: actionCreator<{
    rollUps: Filters.FiltersLoadedParams['rollUps'];
    state: Filters.AddTabParams['filters'];
  }>('RELOAD_ROLL_UPS_FILTERS'),
  addForecastDealsCustomFiltersTab:
    actionCreator<Filters.AddTabParams>('ADD_TAB'),
  persist: actionCreator<Filters.PersistFiltersParams>(t.FILTER + t.PERSIST),
  setForecastModalFilters: actionCreator<Filters.ForecastModalFilterParams>(
    'SET_FORECAST_MODAL_FILTERS'
  ),
  updateSortBy: actionCreator<Partial<Filters.FiltersActivitySortBy>>(
    t.SORTING + t.UPDATE
  ),
  updateViewBy: actionCreator<ViewBy>(t.VIEW_BY + t.UPDATE),
  onAddCustomView: actionCreator<Filters.AddCustomViewMeta>(
    t.FILTER + t.CUSTOM_VIEWS + t.CREATE + t.SUCCESS
  ),
  onUpdateCustomView: actionCreator<Filters.AddCustomViewMeta>(
    t.FILTER + t.CUSTOM_VIEWS + t.UPDATE + t.SUCCESS
  ),
  renameCustomView: actionCreator<Filters.RenameCustomView>(
    t.FILTER + t.CUSTOM_VIEWS + t.RENAME + t.SUCCESS
  ),
  pinCustomView: actionCreator<Filters.PinCustomViewMeta>(
    t.FILTER + t.CUSTOM_VIEWS + t.PIN + t.SUCCESS
  ),
  onRemoveCustomView: actionCreator<Filters.RemoveCustomViewMeta>(
    t.FILTER + t.CUSTOM_VIEWS + t.REMOVE + t.SUCCESS
  ),
  setActiveProfile: actionCreator<Filters.SetActiveProfile>(
    t.FILTER + t.CUSTOM_VIEWS + t.SET + t.ACTIVE
  ),
  setBusinessType: actionCreator<Filters.SetBusinessTypeCreator>(
    t.FILTER + t.PERSIST + t.BUSINESS_TYPES
  ),
  updateProfileFilters: actionCreator<Filters.InjectFilters>(
    t.FILTERS + t.PROFILE + t.UPDATE
  ),
};

export const filtersSettings = {
  persistPageDefaults: actionCreator<Filters.PersistSettingsFiltersParams>(
    t.FILTER + t.PERSIST + '_PAGE_DEFAULTS'
  ),
  persistProfileFilters: actionCreator<Filters.PersistSettingsFiltersParams>(
    t.FILTER + t.PERSIST + '_PROFILE_DEFAULTS'
  ),
  persistGlobalDefaults: actionCreator<Filters.PersistSettingsFiltersParams>(
    t.FILTER + t.PERSIST + '_GLOBAL_DEFAULTS'
  ),
  resetToDefaults: actionCreator<Filters.ClearFiltersParams>(
    t.FILTER + t.CLEAR + '_TO_DEFAULTS'
  ),
  profileFiltersLoaded: actionCreator<{
    filtersState: Filters.BackendFiltersState;
    profileId: string;
  }>(t.FILTER + '_FOR_PROFILE_LOADED'),
  setExplicitly: actionCreator<Filters.SetExplicitlyParams>(
    t.FILTER + '_SET_EXPLICITLY'
  ),
};

export const widget = {
  persist: actionCreator<WidgetStateRecord & { id: string }>(
    t.WIDGETS + t.PERSIST
  ),
  sortWidget: actionCreator<{ title: string; value: string }>(
    t.WIDGETS + t.SORTING
  ),
};

export const company = {
  settingsGetSuccess: actionCreator<any>(
    t.COMPANY_SETTINGS + t.GET + t.SUCCESS
  ),
};

export const manageForecast = {
  persist: actionCreator<{ settingsId: string; amount: number; notes: string }>(
    t.FORECAST + t.MANAGE + t.PERSIST
  ),
  clear: actionCreator<{ settingsId: string }>(t.FORECAST + t.MANAGE + t.CLEAR),
  setHistoryPanel: actionCreator<{
    settingsId: string;
  } | null>(t.FORECAST + t.SET + '_HISTORY_PANEL'),
  setOpenedSettings: actionCreator<string[]>(
    t.FORECAST + t.SET + '_OPENED_SETTINGS'
  ),
  setActiveSettingsId: actionCreator<string>(
    t.FORECAST + t.SET + '_ACTIVE_SETTINGS_ID'
  ),
  setActiveBusinessType: actionCreator<string | null>(
    t.FORECAST + t.SET + '_ACTIVE_BUSINESS_TYPE'
  ),
  clearOnRouteChange: actionCreator<void>(
    t.FORECAST + '_CLEAR_ON_ROUTE_CHANGE'
  ),
  resetSubmissionSettings: actionCreator<void>(
    t.FORECAST + '_SUBMISSION_SETTINGS_RESET'
  ),
};

export const forecastWidgetAllChangedDate = {
  changeDateSince: actionCreator<{ [key: string]: string[] }>(
    t.WIDGETS + t.CHANGE + t.DATE
  ),
};

export const hotjarService = {
  toggleHotjarService: actionCreator<boolean>(t.TOGGLE + t.HOTJAR),
  setShowNotification: actionCreator(t.MODAL + t.TOGGLE + t.HOTJAR),
};

export const routes = {
  clear: actionCreator<void>(t.ROUTER_CONFIG + t.CLEAR),
};
