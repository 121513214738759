import classNames from 'classnames';
import { css } from 'emotion';
import debounce from 'lodash/debounce';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Loader, Menu } from 'semantic-ui-react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import { formatMoney } from 'common/numbers';
import BuButton, { BuControlSize } from 'components/UI/BuButton';
import BuButtonRefresh from 'components/UI/BuButtonRefresh';
import BuIcon from 'components/UI/BuIcon';
import BuToggle from 'components/UI/BuToggle';
import { DownloadButtonProps } from 'components/UI/DownloadButton/types';
import DownloadDropdown from 'components/UI/DownloadDropdown';
import Search from 'components/UI/Search';
import OppSplitViewToggle from 'components/UI/TableConfig/OppSplitViewToggle';
import { TableOwnProps } from 'components/UI/TableConfig/types';
import { DraggableColumnsControl } from 'components/UI/common/DraggableColumnsControl/DraggalbeColumnControl';
import Pagination from 'components/UI/common/Pagination/Pagination';
import { IRow } from 'components/UI/common/TypedTable/TypedTable';
import * as s from 'components/dashboard/Accounts/styles';
import * as selectors from 'selectors';
import { downloadFile, useDownloadFile } from 'utils/network';

const resetMarginTop = css`
  &.ui.menu.fluid {
    margin-top: 10px;
  }
`;

const resetMarginBottom = css`
  &.ui.menu.fluid {
    margin-bottom: 10px;
  }
`;

const moreSpace = css`
  padding-left: 20px;
  padding-right: 20px;
`;

const controlsBox = css`
  .ui.text.menu &.item {
    display: flex;
    gap: 5px;

    padding: 0;
  }
`;

const downloadCSVButton = css`
  border: none;
  padding: 0 !important;

  i {
    font-size: 18px !important;
  }

  &:hover {
    background-color: var(--bu-white) !important;
  }

  .bu-down,
  .bu-up {
    display: none;
  }
`;

const tgStyle = css`
  margin-right: 10px;
`;

type PaginationProps<T extends IRow> = Pick<
  TableOwnProps,
  'rowsPerPage' | 'currentPage' | 'totalCount' | 'onPaginationChange'
> &
  Record<'data', T[]>;
export const useLocalPagination = <T extends IRow>(
  data: T[],
  initPageSize: number = 10
): PaginationProps<T> => {
  const [currentPage, setCurrentPage] = useState<number>(1);

  useEffect(() => setCurrentPage(1), [data]);

  const paginatedData = useMemo(
    () =>
      data.slice(initPageSize * (currentPage - 1), initPageSize * currentPage),
    [data, currentPage]
  );

  return {
    rowsPerPage: initPageSize,
    currentPage,
    totalCount: data.length,
    onPaginationChange: (nextPage: number) => setCurrentPage(nextPage),
    data: paginatedData,
  };
};

export type IButtonProps = {
  key: string;
  text: string;
  onClick(): void;
};

export type IDropdown = {
  id: number;
  key: number;
  onClick: Function;
  text: string;
  value: string;
};

export type DisplayedColumns =
  | {
      label: string;
      id: string;
      show: boolean;
      fixed_order?: boolean;
      field: string;
      advance?: boolean;
    }[]
  | undefined;

export interface IProps {
  title?: string | React.ReactNode;
  isModal?: boolean;
  totalAmount?: number;
  showTotalAmount?: boolean;
  dataType?: string;
  onPageChange?: (pageNumber: number, pageSize: number) => void;
  onRefresh: () => void;
  hidePagination?: boolean;
  totalCount?: number;
  pageNumber?: number;
  pageSize?: number;
  paginationOptions?: number[];
  includeAllDealsCheckbox?: any;
  hideSearch?: boolean;
  defaultSearchText?: string;
  searchPlaceholder?: string;
  onSearch?: (searchText: string) => void;
  fullscreen?: boolean;
  downloadButton?: DownloadButtonProps;
  controls?: React.ReactNode;
  leftControls?: React.ReactNode;

  renderTitleExtra?: (displayedColumns: DisplayedColumns) => JSX.Element;
  selectedRowsCount?: false | number;
  selectedRowsButtons?: IButtonProps[];
  showColumnsVisibilityToggle?: boolean;
  displayedColumns?: DisplayedColumns;
  isIncludedDealsTable?: boolean;

  filterIncluded?: string;
  filterIncludedOnChange?: () => void;
  showOppSplitToggle?: boolean;
  isTableDataLoading?: boolean;
  hasRefreshButton?: boolean;
  showTotalResultsNextToPagination?: boolean;
}

const TableControls: React.FC<IProps> = ({
  controls,
  leftControls,
  dataType,
  defaultSearchText = '',
  displayedColumns,
  downloadButton,
  fullscreen = false,
  hidePagination = false,
  hideSearch = false,
  includeAllDealsCheckbox,
  isIncludedDealsTable = false,
  isModal = false,
  onPageChange = () => {},
  onSearch = () => {},
  onRefresh,
  pageNumber = 1,
  pageSize = 50,
  paginationOptions,
  renderTitleExtra,
  selectedRowsButtons = [],
  selectedRowsCount = false,
  showColumnsVisibilityToggle = false,
  filterIncluded,
  filterIncludedOnChange,
  title,
  totalAmount,
  showTotalAmount,
  totalCount = 0,
  showOppSplitToggle = false,
  isTableDataLoading = false,
  hasRefreshButton = false,
  showTotalResultsNextToPagination,
}: IProps) => {
  const [pageCount, setPageCount] = useState(0);
  const isIncludedDeals = !!includeAllDealsCheckbox;
  const [searchValue, setSearchValue] = useState(defaultSearchText);
  const companyCurrencyCode = useSelector(selectors.getUserLocalCurrency);
  const activeBusinessType = useSelector(
    selectors.getForecastActiveBusinessType
  );
  const [downloadIsPending, setDownloadIsPending] = useState(false);

  useEffect(() => {
    setPageCount(Math.ceil(totalCount / pageSize));
  }, [totalCount, pageSize]);

  const triggerDownload = (
    onlyVisible: boolean,
    addDeltas: boolean,
    addMulticurrency: boolean
  ) => {
    setDownloadIsPending(true);
    const visibleLabels = displayedColumns
      ?.filter((x) => x.show)
      .map((x) => `${x.label}${x.advance ? '*' : ''}`);

    downloadFile({
      fileType: downloadButton?.fileType,
      queryMethod: downloadButton?.queryMethod,
      queryParams: JSON.stringify({
        ...JSON.parse(downloadButton?.serializedQueryParams || '{}'),
        visible_columns: onlyVisible ? visibleLabels : undefined,
        add_deltas: addDeltas,
        add_multicurrency: addMulticurrency,
      }),
      url: downloadButton?.url || '',
      setStatus: (status) => setDownloadIsPending(status === 'loading'),
    });
  };

  const onChangeCallback = useCallback(debounce(onSearch, 500), [onSearch]);

  const handleSearchChange = useCallback(
    (value: string) => {
      setSearchValue(value);
      onChangeCallback(value);
    },
    [setSearchValue, onChangeCallback]
  );

  const onToggle = useCallback(() => {
    if (filterIncludedOnChange) {
      filterIncludedOnChange();
    }
  }, [filterIncludedOnChange]);

  const renderTitle = () => (
    <Menu.Item fitted>
      <span className={s.header}>
        {isIncludedDeals && title}

        {!!selectedRowsCount &&
          !isIncludedDeals &&
          `${selectedRowsCount} Selected`}

        {!selectedRowsCount && !!totalCount && !isIncludedDeals && (
          <>
            {title}
            {!isIncludedDealsTable && (
              <span className="sub">
                ({totalCount}
                {!!dataType && ` ${dataType}`})
              </span>
            )}
          </>
        )}

        {!isTableDataLoading &&
          (showTotalAmount ||
            (showTotalAmount === undefined &&
              isModal &&
              !isIncludedDealsTable)) && (
            <span className={s.totalAmountStyle}>
              {formatMoney(companyCurrencyCode, totalAmount || 0, 0)}
            </span>
          )}
      </span>

      {renderTitleExtra && renderTitleExtra(displayedColumns)}

      {selectedRowsCount > 0 && selectedRowsButtons?.length > 0 && (
        <Menu.Menu>
          <Menu.Item fitted="vertically">
            {selectedRowsButtons.map((button) => (
              <BuButton
                secondary
                size={BuControlSize.SMALL}
                key={button.key}
                onClick={button.onClick}
              >
                {button.text}
              </BuButton>
            ))}
          </Menu.Item>
        </Menu.Menu>
      )}
    </Menu.Item>
  );

  return (
    <div className={classNames('table-controls', { [moreSpace]: fullscreen })}>
      <Menu
        fluid
        text
        className={classNames(resetMarginBottom, {
          [resetMarginTop]: fullscreen,
        })}
      >
        {!React.isValidElement(title) ? (
          renderTitle()
        ) : (
          <Menu.Item fitted>{title}</Menu.Item>
        )}

        {hasRefreshButton && (
          <Menu.Item>
            <BuButtonRefresh
              onClick={() => onRefresh()}
              cacheDate={''}
              status={isTableDataLoading}
            />
          </Menu.Item>
        )}

        <Menu.Menu position="right">
          {leftControls && <Menu.Item>{leftControls}</Menu.Item>}

          {showOppSplitToggle && (
            <OppSplitViewToggle activeBusinessType={activeBusinessType} />
          )}

          {isIncludedDealsTable && filterIncluded && (
            <Menu.Item fitted>
              <BuToggle
                checked={filterIncluded === 'on'}
                className={tgStyle}
                onChange={onToggle}
              >
                Show only included deals
              </BuToggle>
            </Menu.Item>
          )}

          {!hideSearch && (
            <Menu.Item fitted>
              <Search
                name="table-search"
                onChange={handleSearchChange}
                value={searchValue}
                size={BuControlSize.SMALL}
              />
            </Menu.Item>
          )}

          {showColumnsVisibilityToggle && (
            <Menu.Item>
              <DraggableColumnsControl
                displayedColumns={displayedColumns || []}
                isModal={isModal}
              />
            </Menu.Item>
          )}

          {downloadButton ? (
            <Menu.Item>
              {downloadButton.extendedOptions ? (
                <DownloadDropdown
                  isPending={downloadIsPending}
                  triggerDownload={triggerDownload}
                />
              ) : (
                <BuButton
                  icon
                  size={BuControlSize.SMALL}
                  onClick={() => triggerDownload(false, true, true)}
                  tooltip="Download CSV"
                  className={downloadCSVButton}
                >
                  {downloadIsPending ? (
                    <Loader size="tiny" active inline />
                  ) : (
                    <BuIcon
                      name={BoostUpIcons.Download}
                      color="var(--bu-primary-700)"
                    />
                  )}
                </BuButton>
              )}
            </Menu.Item>
          ) : null}

          {controls && (
            <Menu.Item className={controlsBox}>{controls}</Menu.Item>
          )}

          {!hidePagination && (
            <Menu.Item fitted>
              <Pagination
                totalPages={pageCount}
                pageNumber={pageNumber}
                rowPerPage={pageSize}
                onChange={onPageChange}
                totalCount={totalCount}
                showTotalResultsNextToPagination={
                  showTotalResultsNextToPagination
                }
                paginationOptions={paginationOptions}
              />
            </Menu.Item>
          )}
        </Menu.Menu>
      </Menu>
      {includeAllDealsCheckbox}
    </div>
  );
};

export default TableControls;
