import React, { useEffect, useState } from 'react';

import { Conditions } from 'components/dashboard/Metrics/Create/Conditions/Conditions';
import { AdvancedOptions } from 'components/dashboard/Metrics/Create/MetricDefinitionInputs/SimpleMetricsInputs/AdvancedOptions';
import { ForecastSubmissionOptions } from 'components/dashboard/Metrics/Create/MetricDefinitionInputs/SimpleMetricsInputs/ForecastSubmissionOptions/ForecastSubmissionOptions';
import { ForecastSubmissionType } from 'components/dashboard/Metrics/Create/MetricDefinitionInputs/SimpleMetricsInputs/ForecastSubmissionType';
import { MetricCreateSelectField } from 'components/dashboard/Metrics/Create/MetricDefinitionInputs/SimpleMetricsInputs/MetricCreateSelectField';
import { MetricObjectSelector } from 'components/dashboard/Metrics/Create/MetricDefinitionInputs/SimpleMetricsInputs/MetricObjectSelector';
import { TargetFields } from 'components/dashboard/Metrics/Create/MetricDefinitionInputs/SimpleMetricsInputs/TargetFields';
import { ForecastOption } from 'components/dashboard/Metrics/Create/MetricDefinitionInputs/SimpleMetricsInputs/types';
import {
  AnalysisType,
  FORECAST_AMOUNT_ACCURACY,
  FORECAST_SUBMISSION,
  FORECAST_SUBMISSION_OBJECTS,
  OPPORTUNITY,
  QUARTER,
  TARGET,
} from 'components/dashboard/Metrics/constants';
import { AggregationFunction } from 'components/dashboard/Metrics/enums';
import {
  BIMetricSimple,
  BIMetricSimpleNewborn,
  BIMetricsQueryFilter,
  BIMetricUnion,
} from 'components/dashboard/Metrics/metrics.types';

interface Props {
  isForecastSubmissionWidget: boolean | null;
  metric: BIMetricSimple;
  onCompleteMetricInputs: (
    complete: boolean,
    metricInputs: Partial<BIMetricSimple>
  ) => void;
}

export const SimpleMetricsInputs: React.FC<Props> = ({
  isForecastSubmissionWidget,
  metric,
  onCompleteMetricInputs,
}) => {
  const [localMetricObject, setLocalMetricObject] = useState<string>(
    metric.object || OPPORTUNITY
  );

  const [propertyMetric, setPropertyMetric] =
    useState<{ complete: boolean; metricProperty: Partial<BIMetricSimple> }>();

  const [targetInputs, setTargetInputs] = useState<Partial<BIMetricSimple>>({
    target_period: metric.target_period || undefined,
    target_type: metric.target_type || undefined,
  });

  const [filters, setFilters] = useState<{
    complete: boolean;
    filters: BIMetricsQueryFilter[];
  }>({ complete: !!metric?.filters, filters: metric?.filters ?? [] });

  const [advancedOptions, setAdvancedOptions] =
    useState<{
      complete: boolean;
      advancedOptions: Partial<BIMetricSimple>;
    }>();

  const [forecastSubmissionType, setForecastSubmissionType] =
    useState<{
      complete: boolean;
      forecastSubmissionType: ForecastOption;
    }>();
  const [forecastSubmissionMetric, setForecastSubmissionMetric] = useState<
    Partial<BIMetricSimple>
  >({
    forecast_submission_metric: metric.forecast_submission_metric || undefined,
  });
  const [forecastSubmissionOptions, setForecastSubmissionOptions] = useState<
    Partial<BIMetricSimple>
  >({
    forecast_submission_calculation:
      metric.forecast_submission_calculation || undefined,
    manager_aggregation_type: metric.manager_aggregation_type || undefined,
  });

  const isForecastSubmissionObject =
    FORECAST_SUBMISSION_OBJECTS.includes(localMetricObject);
  const isTargetObject = localMetricObject === TARGET;

  const handleChangeMetricObject = (value: string): void => {
    // changing object type require to clean up the query
    // because different object type allow different columns in the filter or formula
    if (
      value &&
      (value !== localMetricObject ||
        (value === FORECAST_SUBMISSION &&
          forecastSubmissionMetric.forecast_submission_metric !==
            FORECAST_SUBMISSION))
    ) {
      // We reset the conditions
      setFilters({ complete: true, filters: [] });

      const isForecast = FORECAST_SUBMISSION_OBJECTS.includes(value);

      if (isForecast) {
        // If it is a one of the objects of forecast submissions we put 'forecast_submission'
        setLocalMetricObject(FORECAST_SUBMISSION);
        setForecastSubmissionMetric({
          forecast_submission_metric: value,
        });

        /**
         * we should remove this because is only for this type
         */
        if (value !== FORECAST_AMOUNT_ACCURACY) {
          setForecastSubmissionOptions({
            forecast_submission_calculation: undefined,
          });
        }

        if (
          advancedOptions?.advancedOptions.manager_aggregation_type ===
            'direct_report_revenue' ||
          metric.manager_aggregation_type === 'direct_report_revenue'
        ) {
          setAdvancedOptions(
            (prev) =>
              prev && {
                ...prev,
                advancedOptions: {
                  ...prev.advancedOptions,
                  manager_aggregation_type: 'self_revenue',
                },
              }
          );
          setForecastSubmissionOptions((prev) => ({
            ...prev,
            manager_aggregation_type: 'self_revenue',
          }));
        }
      } else {
        setForecastSubmissionMetric({
          forecast_submission_metric: undefined,
        });
        setLocalMetricObject(value);
      }

      const isTarget = value === TARGET;
      if (isTarget) {
        setTargetInputs({
          target_type: 'ARR',
          target_period: QUARTER,
        });
      } else {
        setTargetInputs({
          target_type: undefined,
          target_period: undefined,
        });
      }

      setPropertyMetric(
        (prev) =>
          prev && {
            complete: false,
            metricProperty: {
              ...prev.metricProperty,
              ...((isTarget || isForecast) && {
                aggregation_function: undefined,
                column: undefined,
              }),
              ...(!isTarget &&
                !isForecast &&
                !prev.metricProperty.aggregation_function && {
                  aggregation_function: AggregationFunction.Sum,
                }),
            },
          }
      );
    }
  };

  const handleChangePropertyMetric = (
    complete: boolean,
    metricProperty: Partial<BIMetricUnion>
  ): void => {
    setPropertyMetric({ complete, metricProperty });
  };

  // this funtion makes me thinking we have to improve it...
  // probably wasn't a good idea to move it here...
  const handleChangeConditions = (
    complete: boolean,
    filters: BIMetricsQueryFilter[]
  ) => {
    setFilters({ complete, filters });
  };

  const handleChangeAdvancedOptions = (
    complete: boolean,
    advancedOptions: Partial<BIMetricSimple | BIMetricSimpleNewborn>
  ): void => {
    setAdvancedOptions({ complete, advancedOptions });
  };

  const handleChangeForecastSubmissionType = (
    complete: boolean,
    forecastSubmissionType: ForecastOption
  ) => {
    setForecastSubmissionType({ complete, forecastSubmissionType });
  };

  useEffect(() => {
    const isFSObject = FORECAST_SUBMISSION_OBJECTS.includes(localMetricObject);
    let inputsReady = false;
    let metricInputObj: Partial<BIMetricUnion> = {
      object: localMetricObject,
      filters: filters.filters,
      ...propertyMetric?.metricProperty,
      ...(isFSObject
        ? { ...forecastSubmissionOptions }
        : advancedOptions?.advancedOptions),
    };

    // if one of the mandatory section are incomplete should not notify nothing.
    if (isTargetObject && targetInputs) {
      metricInputObj = {
        ...metricInputObj,
        ...targetInputs,
        // remove not needed fields
        forecast_submission_calculation: undefined,
        forecast_submission_type: undefined,
        forecast_submission_type_period: undefined,
      };
      inputsReady = !!(targetInputs.target_period && targetInputs.target_type);
    } else if (
      isForecastSubmissionObject &&
      forecastSubmissionType?.forecastSubmissionType
        ?.forecast_submission_type &&
      forecastSubmissionMetric?.forecast_submission_metric
    ) {
      metricInputObj = {
        ...metricInputObj,
        forecast_submission_type:
          forecastSubmissionType?.forecastSubmissionType
            .forecast_submission_type,
        forecast_submission_type_period:
          forecastSubmissionType?.forecastSubmissionType.forecast_period,
        forecast_submission_metric:
          forecastSubmissionMetric.forecast_submission_metric,
        column: null,
        // remove not needed fields
        cumulative_sum_period: undefined,
        aggregation_function: undefined,
        is_cumulative_sum: false,
        target_period: undefined,
        target_type: undefined,
      };
      inputsReady = !!forecastSubmissionType?.forecastSubmissionType;
    } else if (!isTargetObject && !isForecastSubmissionObject) {
      metricInputObj = {
        ...metricInputObj,
        forecast_submission_calculation: undefined,
        forecast_submission_type: undefined,
        forecast_submission_metric: undefined,
        forecast_submission_type_period: undefined,
        target_period: undefined,
        target_type: undefined,
      };
      inputsReady = !!propertyMetric?.complete;
    }

    if (
      metric.analysis_type === AnalysisType.LIVE &&
      !isForecastSubmissionObject
    ) {
      inputsReady = inputsReady && !!advancedOptions?.complete;
    }

    inputsReady = inputsReady && !!localMetricObject && !!filters.complete;
    onCompleteMetricInputs(inputsReady, metricInputObj);
  }, [
    localMetricObject,
    targetInputs.target_period,
    targetInputs.target_type,
    propertyMetric,
    filters,
    advancedOptions,
    forecastSubmissionType,
    forecastSubmissionMetric,
    forecastSubmissionOptions,
  ]);

  return (
    <>
      <MetricObjectSelector
        analysisType={metric.analysis_type}
        isForecastSubmissionWidget={isForecastSubmissionWidget}
        metricObject={
          forecastSubmissionMetric.forecast_submission_metric ||
          localMetricObject
        }
        onChangeMetricObject={handleChangeMetricObject}
      />

      {!isForecastSubmissionWidget &&
        !isForecastSubmissionObject &&
        !isTargetObject && (
          <MetricCreateSelectField
            aggregationFunction={metric.aggregation_function}
            analysisType={metric.analysis_type}
            metricColumn={metric.column}
            metricObject={localMetricObject}
            updatePropertyMetric={handleChangePropertyMetric}
          />
        )}

      {isTargetObject && (
        <TargetFields
          conditions={metric.filters}
          targetPeriod={targetInputs.target_period}
          targetType={targetInputs.target_type}
          setTargetInputs={setTargetInputs}
        />
      )}

      {isForecastSubmissionObject && (
        <ForecastSubmissionType
          selectedForecastSubmissionType={metric.forecast_submission_type}
          metricObject={localMetricObject}
          updateForecastSubmissionType={handleChangeForecastSubmissionType}
        />
      )}

      <Conditions
        metric={metric}
        columnFields={[localMetricObject]}
        forecastPeriod={
          forecastSubmissionType?.forecastSubmissionType?.forecast_period
        }
        onCompleteConditions={handleChangeConditions}
      />

      {metric.analysis_type === AnalysisType.LIVE && (
        <>
          {isForecastSubmissionObject ? (
            <ForecastSubmissionOptions
              key={`${metric.object}-${metric.forecast_submission_metric}`}
              isForecastAmountAccuracy={
                !!forecastSubmissionMetric.forecast_submission_metric &&
                forecastSubmissionMetric.forecast_submission_metric ===
                  FORECAST_AMOUNT_ACCURACY
              }
              forecastSubmissionCalculation={
                forecastSubmissionOptions.forecast_submission_calculation
              }
              managerAggregationType={
                forecastSubmissionOptions.manager_aggregation_type
              }
              setForecastSubmissionOptions={setForecastSubmissionOptions}
            />
          ) : (
            <AdvancedOptions
              metric={metric}
              onAdvanceOptionComplete={handleChangeAdvancedOptions}
            />
          )}
        </>
      )}
    </>
  );
};
