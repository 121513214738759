import { css } from 'emotion';

export const tableHeaderCellBorders = css`
  & th {
    border-right: 1px solid var(--bu-gray-400) !important;

    span {
      text-align: left;
      text-transform: inherit;
    }
  }

  & tbody {
    tr {
      & td {
        min-width: 180px !important;
      }

      > td:not(:last-child) {
        border-right: 1px solid var(--bu-gray-400) !important;
      }
    }

    tr:last-child {
      td {
        border-bottom: 1px solid var(--bu-gray-400) !important;
      }
    }
  }
`;

export const extraHeaderTableCellBordersSingleMetric = css`
  & thead {
    tr:first-child {
      > th:not(:last-child) {
        border-right: 2px solid var(--bu-gray-400) !important;
      }
    }

    tr:last-child {
      > th:not(:last-child) {
        border-right: 2px solid var(--bu-gray-400) !important;
      }
    }

    span {
      text-align: left;
      text-transform: inherit;
    }
  }

  & tbody {
    tr {
      & td {
        min-width: 100px !important;
      }

      > td:not(:last-child) {
        border-right: 2px solid var(--bu-gray-400) !important;
      }
    }

    tr:last-child {
      td {
        border-bottom: 2px solid var(--bu-gray-400) !important;
      }
    }
  }
`;

export const extraHeaderMultiplePivotsTableCellBorders = (
  metricsCount: number
) => css`
  & thead {
    tr:first-child {
      > th:not(:last-child) {
        border-right: 2px solid var(--bu-gray-400) !important;
      }
    }

    tr:last-child {
      & th {
        border-right: 1px solid var(--bu-gray-400) !important;
      }

      > th:nth-child(${metricsCount}n + 1):not(:last-child) {
        border-right: 2px solid var(--bu-gray-400) !important;
      }
    }

    span {
      text-align: left;
      text-transform: inherit;
    }
  }

  & tbody {
    tr {
      & td {
        min-width: 100px !important;
      }

      > td:not(:last-child) {
        border-right: 1px solid var(--bu-gray-400) !important;
      }

      > td:nth-child(${metricsCount}n + 1):not(:last-child) {
        border-right: 2px solid var(--bu-gray-400) !important;
      }
    }

    tr:last-child {
      td {
        border-bottom: 1px solid var(--bu-gray-400) !important;
      }
    }
  }
`;

export const extraHeaderSinglePivotTableCellBorders = (
  metricsCount: number
) => css`
  & thead {
    tr:first-child {
      > th:not(:last-child) {
        border-right: 2px solid var(--bu-gray-400) !important;
      }
    }

    tr:last-child {
      & th {
        border-right: 1px solid var(--bu-gray-400) !important;
      }

      > th:nth-child(${metricsCount}n):not(:last-child) {
        border-right: 2px solid var(--bu-gray-400) !important;
      }
    }

    span {
      text-align: left;
      text-transform: initial;
    }
  }

  & tbody {
    tr {
      & td {
        min-width: 100px !important;
      }

      > td:not(:last-child) {
        border-right: 1px solid var(--bu-gray-400) !important;
      }

      > td:nth-child(${metricsCount}n):not(:last-child) {
        border-right: 2px solid var(--bu-gray-400) !important;
      }
    }

    tr:last-child {
      td {
        border-bottom: 2px solid var(--bu-gray-400) !important;
      }
    }
  }
`;

const MAP_DEPTH_TO_COLOR = [
  '#ffffff',
  '#f7f8f9',
  '#f3f4f5',
  '#efefef',
  '#ebebeb',
  '#e6e6e6',
  '#e2e2e2',
  '#dfdfdf',
];

export const getStyleForRowInDepth = (depth: number): string => css`
  background-color: ${MAP_DEPTH_TO_COLOR[depth - 1]};

  & td:first-child {
    background-color: ${MAP_DEPTH_TO_COLOR[depth - 1]} !important;
  }

  &:hover {
    & td:first-child {
      background: var(--bu-gray-200) !important;
    }
  }
`;

export const subtotalRow = css`
  background-color: var(--bu-gray-100) !important;
  border-right: 1px solid var(--bu-gray-300);
  border-bottom: 2px solid var(--bu-gray-400);
`;
