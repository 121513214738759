import React, { FC } from 'react';

import { FunnelControls } from 'components/dashboard/Metrics/Widget/FunnelWidget/Controls/FunnelControls';
import { FunnelVisualization } from 'components/dashboard/Metrics/Widget/FunnelWidget/Visualization/FunnelVisualization';
import { WidgetHeader } from 'components/dashboard/Metrics/Widget/Header/WidgetHeader';
import { TemplateFilters } from 'components/dashboard/Metrics/Widget/TemplateFilters/TemplateFilters';
import { WidgetContainer } from 'components/dashboard/Metrics/Widget/widgets.styles';
import {
  BIDashboardSettings,
  BIWidget,
} from 'components/dashboard/Metrics/metrics.types';

interface Props {
  isControlsShown: boolean;
  isDashboardWidget?: boolean;
  isDashboardModal?: boolean;
  dashboardName?: string;
  dashboardSettings?: BIDashboardSettings;
  widget: Partial<BIWidget>; // TODO Fix type - remove Partial
  onCloneWidget?: (metricId: string) => void;
  onEditWidget?: (metricId: string) => void;
  onRemoveWidget?: (metricId: string) => void;
  onTitleClicked?: () => void;
  onCloseWidgetModal?: () => void;
  onChangeWidget: (widget: Partial<BIWidget>) => void;
}

export const FunnelWidget: FC<Props> = ({
  isControlsShown = false,
  isDashboardWidget = false,
  isDashboardModal = false,
  dashboardName = '',
  dashboardSettings,
  widget,
  onCloneWidget,
  onEditWidget,
  onRemoveWidget,
  onTitleClicked,
  onCloseWidgetModal,
  onChangeWidget,
}) => (
  <WidgetContainer
    key={widget._id}
    isDashboard={isDashboardWidget}
    isMetricsPreview={false}
  >
    <WidgetHeader
      id={widget._id}
      name={widget.name}
      dashboardName={dashboardName}
      isDashboardWidget={isDashboardWidget}
      isDashboardModal={isDashboardModal}
      onCloneWidget={onCloneWidget}
      onEditWidget={onEditWidget}
      onRemoveWidget={onRemoveWidget}
      onTitleClicked={onTitleClicked}
      onCloseWidgetModal={onCloseWidgetModal}
    />

    <FunnelControls
      showControls={isControlsShown}
      widget={widget}
      onChangeWidget={onChangeWidget}
    />

    <TemplateFilters
      showControls={isControlsShown}
      templateFilters={widget.template_filters ?? []}
      widgetFilters={widget.widget_filters ?? []}
      onChangeWidget={onChangeWidget}
    />

    <FunnelVisualization
      dashboardSettings={dashboardSettings}
      widget={widget}
    />
  </WidgetContainer>
);
