import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { DateOperatorOption } from 'components/dashboard/Metrics/Widget/TemplateFilters/TemplateFilter/types';
import { DropdownCalendar } from 'components/dashboard/Metrics/common/Calendar/Calendar';
import { Checkbox } from 'components/dashboard/Metrics/common/Calendar/types';
import { ALL_TIME_VALUE } from 'components/dashboard/Metrics/constants';
import { BIMetricsFilter } from 'components/dashboard/Metrics/metrics.types';
import { getTimeOptions } from 'selectors/revbi/metrics';
import { value } from 'components/modals/SyntheticMetricModal/styles';

interface Props {
  timeInterval?: string;
  timePeriod?: string;
  templateFilter?: BIMetricsFilter;
  relativeDateFilterOptions: Filters.DateFilterElement[];
  widgetFilterLabel: string;
  widgetFilterName: string;
  onValueChange: (change: DateOperatorOption) => void;
}

export const DateTypeFilter: React.FC<Props> = ({
  timeInterval,
  timePeriod,
  templateFilter,
  relativeDateFilterOptions,
  widgetFilterLabel,
  widgetFilterName,
  onValueChange,
}) => {
  const timeFilterOptions = useSelector(getTimeOptions);

  useEffect(() => {
    // when loading a metric to edit, if the filter value is a custom time period
    // the custom time period need to be added to timeValueOptions so that it
    // can be pre-selected in the UI
    if (
      typeof templateFilter?.value === 'string' &&
      templateFilter?.value.includes(',') &&
      !timeFilterOptions.predefined.some(
        (e) => e.value === templateFilter?.value
      ) &&
      timeFilterOptions.predefined.length
    ) {
      timeFilterOptions.predefined.push({
        label: templateFilter.value.split(',').join(' / '),
        value: templateFilter.value,
      });
    }

    // when loading a metric to edit, if the filter value is a custom period
    // the custom time period need to be added to timeValueOptions so that it
    // can be pre-selected in the UI
    if (
      typeof templateFilter?.value === 'string' &&
      templateFilter?.value.includes('_') &&
      !timeFilterOptions.predefined.some(
        (e) => e.value === templateFilter?.value
      ) &&
      timeFilterOptions.predefined.length
    ) {
      timeFilterOptions.predefined.push({
        label: templateFilter.value
          .split('_')
          .map((e) => e.charAt(0).toUpperCase() + e.slice(1))
          .join(' '),
        value: templateFilter.value,
      });
    }
  }, [JSON.stringify(timeFilterOptions)]);

  const buildDateOptions = (
    options: Filters.DateFilterElement[]
  ): Checkbox[] => {
    const isChecked = (option: Filters.DateFilterElement): boolean => {
      if (option.value === ALL_TIME_VALUE) {
        return true;
      }

      if (templateFilter && templateFilter.value) {
        // now the value could be a string or an array.
        if (typeof templateFilter.value === 'string') {
          return option.value === templateFilter.value;
        }

        return option.value === (templateFilter.value as string[])[0];
      }

      return false;
    };

    return options.map((option) => ({ ...option, checked: isChecked(option) }));
  };

  const value = templateFilter
    ? typeof templateFilter.value === 'string'
      ? templateFilter.value
      : Array.isArray(templateFilter?.value) && templateFilter.value[0]
    : '';

  const includedBlankValues =
    Array.isArray(templateFilter?.value) &&
    typeof templateFilter?.value !== 'string'
      ? !!templateFilter?.value[1]
      : false;

  return (
    <DropdownCalendar
      config={{
        allowFuture: true,
        title: widgetFilterLabel,
        isLocked: false,
        customEnd: true,
        customStart: true,
        customPeriodValue: value as string,
        values: buildDateOptions(timeFilterOptions.predefined),
        fiscalYearValues: buildDateOptions(timeFilterOptions.fiscal_year),
        relativeValues: buildDateOptions(relativeDateFilterOptions),
        timeInterval: timeInterval,
        timePeriod: timePeriod,
        showInNotInFilter: true,
        includedBlankValues,
      }}
      key={widgetFilterName}
      name="Filter"
      onChange={onValueChange}
    />
  );
};
