import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from 'react';

import BuRadio from 'components/UI/BuRadio';
import {
  container,
  infoParagraphRadio,
  optionContainer,
  radiosContainer,
} from 'components/dashboard/Metrics/Create/MetricDefinitionInputs/SimpleMetricsInputs/ForecastSubmissionOptions/styles';
import { MetricCreateSubTitle } from 'components/dashboard/Metrics/metrics.styles';
import { BIMetricSimple } from 'components/dashboard/Metrics/metrics.types';

interface Props {
  isForecastAmountAccuracy: boolean;
  forecastSubmissionCalculation?: string;
  managerAggregationType?: string;
  setForecastSubmissionOptions: Dispatch<
    SetStateAction<Partial<BIMetricSimple>>
  >;
}

export const ForecastSubmissionOptions: FC<Props> = ({
  isForecastAmountAccuracy,
  forecastSubmissionCalculation,
  managerAggregationType,
  setForecastSubmissionOptions,
}) => {
  const [dataAggregation, setDataAggregation] = useState<string>(
    managerAggregationType || 'self_revenue'
  );
  const [showForecastValues, setShowForecastValues] = useState<string>(
    forecastSubmissionCalculation || 'absolute'
  );

  const handleChangeHierarchyRollupSum = (value: string): void => {
    setDataAggregation(value);
  };

  useEffect(() => {
    setForecastSubmissionOptions({
      manager_aggregation_type: dataAggregation,
      forecast_submission_calculation: showForecastValues,
    });
  }, [dataAggregation, showForecastValues]);

  return (
    <div className={container} data-testing="advanced-options-container">
      {isForecastAmountAccuracy && (
        <div className={radiosContainer}>
          <MetricCreateSubTitle>Show</MetricCreateSubTitle>
          <BuRadio
            onChange={() => setShowForecastValues('absolute')}
            checked={showForecastValues === 'absolute'}
            label="Absolute Values"
          ></BuRadio>
          <p className={infoParagraphRadio}>
            Will show absolute accuracy % e.g. 105%, 84% etc.
          </p>

          <BuRadio
            onChange={() => setShowForecastValues('deviation')}
            checked={showForecastValues === 'deviation'}
            label="Deviation Values"
          ></BuRadio>
          <p className={infoParagraphRadio}>
            Will show variation from the actual booked e.g. +5%, -5% etc.
          </p>
        </div>
      )}

      <MetricCreateSubTitle>Data Aggregation</MetricCreateSubTitle>
      <div className={optionContainer}>
        <BuRadio
          onChange={(_, { value }: { value: string }) =>
            handleChangeHierarchyRollupSum(value)
          }
          checked={['self_revenue', '', undefined].includes(dataAggregation)}
          value={'self_revenue'}
          label="Self Forecast Submissions"
        ></BuRadio>
        <p className={infoParagraphRadio}>
          Considers only the individual's submissions
        </p>
      </div>

      <div className={optionContainer} data-testing="manager-level-aggregation">
        <BuRadio
          onChange={(_, { value }: { value: string }) =>
            handleChangeHierarchyRollupSum(value)
          }
          checked={dataAggregation === 'direct_report_revenue'}
          value={'direct_report_revenue'}
          label="Team Forecast Submissions"
        ></BuRadio>
        <p className={infoParagraphRadio}>
          Aggregates only direct reports submissions
        </p>
      </div>
    </div>
  );
};
