import classNames from 'classnames';
import { css } from 'emotion';
import * as R from 'ramda';
import React from 'react';
import { Icon, Radio } from 'semantic-ui-react';

import { color } from 'components/UI/BuRadio';
import {
  IDataCellProps,
  isDisable,
  isEditable,
  TypedTableCellConfig,
  ValueType,
} from 'components/UI/common/TypedTable/TypedTable';
import NotAvailableCell from 'components/UI/common/TypedTable/renderers/NotAvailableCell';

export interface RadioConfig extends TypedTableCellConfig {
  style?: 'checkbox' | 'toggle' | 'slider';
  radioValue: ValueType | null;
}

const removeMargin = css`
  &.ui.checkbox {
    margin-top: 8px;
    margin-bottom: 0;
  }
`;

const center = css`
  &.ui.checkbox {
    margin-left: auto;
    margin-right: auto;
    display: table;
  }
`;

const RadioCell = ({
  column,
  row,
  rows,
  onChange = () => {},
}: IDataCellProps) => {
  const rawValue = R.path<boolean>(column.field.split('.'), row);

  const config = R.pathOr<RadioConfig>({ radioValue: null }, [], column.config);

  const handleChange = () => {
    onChange(column, row, config.radioValue);
  };

  const isChecked = config.radioValue === rawValue;

  if (!isEditable(column, row, rows)) {
    return (
      <div
        className={classNames('ui', 'fitted', 'checkbox', removeMargin, center)}
      >
        {rawValue === undefined ? (
          <NotAvailableCell />
        ) : (
          <Icon name={isChecked ? 'check' : 'cancel'} color="grey" />
        )}
      </div>
    );
  }

  return (
    <Radio
      toggle={config.style === 'toggle'}
      slider={config.style === 'slider'}
      disabled={isDisable(column, row, rows)}
      checked={isChecked}
      className={classNames(removeMargin, center, color)}
      onChange={handleChange}
    />
  );
};

export default RadioCell;
