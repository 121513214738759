import { css } from 'emotion';
import React, { useState } from 'react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import { fontCaption } from 'assets/css/common';
import BuCheckbox from 'components/UI/BuCheckbox';
import BuIcon from 'components/UI/BuIcon';
import BuPopup from 'components/UI/BuPopup';

type Props = {
  checked: boolean;
  onChange: (value: boolean) => void;
};

const container = css`
  display: flex;
  gap: 6px;
  align-items: center;
  height: 12px;
`;

const infoIcon = css`
  font-size: 12px;
`;

const label = css`
  ${fontCaption}
`;

const popupContent = css`
  width: 200px;
  padding: 9px 12px;
  color: var(--bu-gray-700);
`;

const ActiveUsersToggle: React.FC<Props> = ({ checked, onChange }) => {
  return (
    <div className={container}>
      <BuCheckbox
        checked={checked}
        onChange={(_checked): void => onChange(!_checked)}
        testingLabel="active-users-toggle"
        elementLabel={<label className={label}>Active users only</label>}
      ></BuCheckbox>
      <BuPopup
        trigger={(ref) => (
          <div ref={ref}>
            {
              <BuIcon
                name={BoostUpIcons.BadgeInfoOutline}
                color={'var(--bu-gray-900)'}
                className={infoIcon}
              />
            }
          </div>
        )}
      >
        <div className={popupContent}>
          When OFF data from all users (including deactivated ones) is displayed
        </div>
      </BuPopup>
    </div>
  );
};

export default ActiveUsersToggle;
