import classNames from 'classnames';
import { css } from 'emotion';
import moment from 'moment';
import React from 'react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuIcon from 'components/UI/BuIcon';
import TooltipWrapper from 'components/UI/common/TypedTable/renderers/common/TooltipWrapper';
import {
  CustomComponentFunction,
  getFieldValue,
  makeComponent,
} from 'components/UI/common/TypedTable/renderers/custom/common';

const noWrap = css`
  white-space: nowrap;
  font-size: 14px;
  display: flex;
  align-items: center;
  .bu-icon {
    font-size: 18px;
    margin-right: 4px;
  }
  &.failed {
    color: var(--bu-red-500);
  }
  &.notEnabled {
    color: var(--bu-gray-700);
  }
`;

const tooltipContainer = css`
  font-size: 14px;
  > .header {
    line-height: 20px;
    color: var(--bu-gray-900);
    &.failed {
      color: var(--bu-red-500);
    }
  }
  > .timeStamp {
    color: var(--bu-gray-700);
    line-height: 20px;
  }
  > ul {
    color: var(--bu-red-500);
    padding-left: 1em;
    padding-top: 6px;
    font-size: 12px;
  }
`;

const NotEnabled: React.FC = () => (
  <div className={classNames(noWrap, 'notEnabled')}>
    <BuIcon name={BoostUpIcons.CrossCircle} className="icon" />
    <span>Not Enabled</span>
  </div>
);

type SyncFieldProps = {
  isEmail: boolean;
  enabledField: string;
  lastSyncDateField: string;
  watcherFailedField: string;
  syncFailedField: string;
};

export const syncField: CustomComponentFunction<SyncFieldProps> =
  ({
    isEmail,
    enabledField,
    lastSyncDateField,
    watcherFailedField,
    syncFailedField,
  }) =>
  (props) => {
    const enabled = getFieldValue<boolean>(enabledField, props.row);
    const lastSyncTimeStamp = getFieldValue<string>(
      lastSyncDateField,
      props.row
    );
    const hasWatcherFailed = getFieldValue<boolean>(
      watcherFailedField,
      props.row
    );
    const hasSyncFailed = getFieldValue<boolean>(syncFailedField, props.row);

    if (!enabled) {
      return <NotEnabled />;
    }
    if (!lastSyncTimeStamp && !(hasSyncFailed || hasWatcherFailed)) {
      return <>-</>;
    }

    const lastSync = lastSyncTimeStamp ? moment(lastSyncTimeStamp) : undefined;

    const label = (
      <div
        className={classNames(noWrap, {
          failed: hasSyncFailed || hasWatcherFailed,
        })}
      >
        {hasSyncFailed || hasWatcherFailed ? (
          <BuIcon
            name={BoostUpIcons.DangerCircle}
            color={
              props.row.isDisabled ? 'var(--bu-gray-500)' : 'var(--bu-red-500)'
            }
          />
        ) : (
          <BuIcon
            name={BoostUpIcons.CheckCircle}
            color={
              props.row.isDisabled
                ? 'var(--bu-gray-500)'
                : 'var(--bu-green-500)'
            }
          />
        )}
        <span>
          {hasSyncFailed || !lastSync ? 'Failed' : lastSync.fromNow()}
        </span>
      </div>
    );

    const tooltipContent = (
      <div className={tooltipContainer}>
        {hasSyncFailed ? (
          <div className="header failed">
            {isEmail ? 'Email' : 'Calendar'} sync failed
          </div>
        ) : (
          <div className="header">
            Last {isEmail ? 'email' : 'calendar'} synced
          </div>
        )}
        {lastSync && (
          <div className="timeStamp">
            on {lastSync.format('Do MMMM, h:mm a')}
          </div>
        )}
        {hasWatcherFailed && (
          <ul>
            <li>Watcher failed to start</li>
          </ul>
        )}
      </div>
    );

    return <TooltipWrapper tooltip={tooltipContent}>{label}</TooltipWrapper>;
  };

export const SyncField = makeComponent(syncField);
