import { css } from 'emotion';

import * as s from 'components/UI/Wrapper/LeftPanel/styles';

export const wrapper = css`
  display: flex;
  height: 100vh;
  overflow: hidden;
`;

export const content = css`
  width: 100vw;

  .${s.container}:hover + & {
    width: calc(100vw - 200px);
  }
`;

export const content_box = css`
  width: calc(100% - 50px);
  margin-left: 50px;
  height: calc(100vh - 50px);
  overflow-y: auto;
  overflow-x: hidden;
`;
