import React, { useEffect } from 'react';

import BuSelect from 'components/UI/BuSelect';
import { NULL_OPERATOR_OPTIONS } from 'components/dashboard/Metrics/Create/Conditions/Condition/constants';
import { BIMetricsFilter } from 'components/dashboard/Metrics/metrics.types';

interface Props {
  templateFilter?: BIMetricsFilter;
  widgetFilterLabel: string;
  updateFilters: (
    emptyFilters: boolean,
    operator: string,
    value: string | string[] | number
  ) => void;
}

export const TextTypeFilter: React.FC<Props> = ({
  templateFilter,
  widgetFilterLabel,
  updateFilters,
}) => {
  const filterValue =
    (templateFilter?.value as string) ?? NULL_OPERATOR_OPTIONS[0].value;

  useEffect(() => {
    handleOperatorSelect(filterValue);
  }, []);

  const handleOperatorSelect = (value: string): void => {
    updateFilters(false, value, value);
  };

  return (
    <BuSelect
      isLargePlaceholder
      secondary
      inlineLabel={widgetFilterLabel}
      defaults={[filterValue]}
      options={NULL_OPERATOR_OPTIONS}
      onChange={(values: string[]) => {
        handleOperatorSelect(values[0]);
      }}
    />
  );
};
