import classNames from 'classnames';
import { css } from 'emotion';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Popup } from 'semantic-ui-react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import { fontDefault } from 'assets/css/common';
import BuButton, { BuControlSize } from 'components/UI/BuButton';
import BuIcon from 'components/UI/BuIcon';
import {
  IDataCellProps,
  TypedTableCellConfig,
  ValueProp,
  ValueType,
  isDisable,
  isEditable,
} from 'components/UI/common/TypedTable/TypedTable';
import DeltaValueCell, {
  getValueFromDelta,
} from 'components/UI/common/TypedTable/renderers/common/DeltaValueCell';
import { getCellValue } from 'components/UI/common/TypedTable/renderers/custom/common';
import * as s from 'components/UI/common/TypedTable/styles';
import { useHandleCellStatus } from 'components/hooks/useHandleCellStatus';

export interface NoteCellConfig extends TypedTableCellConfig {}

export const BuCharLengthInfo = ({
  maxLength,
  textLength,
}: {
  maxLength?: number;
  textLength: number;
}) => (
  <>
    {maxLength ? (
      <div className={classNames(maxLengthInfo, 'bu-font-caption')}>
        <div className="length-info">
          {textLength > maxLength && (
            <span className="error">
              <BuIcon name={BoostUpIcons.BadgeWarningSolid} /> Exceeds character
              limit.
            </span>
          )}
          {textLength === maxLength && (
            <span className="warning">
              <BuIcon name={BoostUpIcons.BadgeWarningSolid} /> Character limit
              reached.
            </span>
          )}
        </div>
        <span className="counter">
          <span className={textLength > maxLength ? 'error' : ''}>
            {textLength}
          </span>{' '}
          / {maxLength}
        </span>
      </div>
    ) : (
      <div className={classNames(maxLengthInfo, 'bu-font-caption', 'right')}>
        <span className="counter">Character entered {textLength}</span>
      </div>
    )}
  </>
);

const editableCellBorder = css`
  border: 1px solid transparent;
  box-sizing: border-box;
  border-radius: var(--bu-control-border-radius);

  tr:hover & {
    border: 1px solid var(--bu-gray-400);
    box-sizing: border-box;
    background-color: var(--bu-white);

    &:hover {
      border-color: var(--bu-gray-500);
    }
  }

  &.disabled {
    border-color: #edf0f2;
    opacity: 0.45;
  }

  &.loading {
    background-color: #fffaca !important;
  }

  &.success {
    background-color: #cff6d7 !important;
  }

  &.error {
    background-color: #fce3dc !important;
  }
`;

const padding = css`
  padding-left: 5px;
  padding-right: 5px;
`;

const editingCellBorder = css`
  border-color: var(--bu-primary-500);
`;

const noteTooltip = css`
  max-height: 300px;
  max-width: 250px;
  height: auto;
  padding: 2px 10px 2px 0;
  overflow-y: auto;
`;

const textOverflow = css`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const maxLengthInfo = css`
  display: flex;
  justify-content: space-between;
  margin-top: -3px;
  margin-bottom: 2px;

  &.right {
    justify-content: flex-end;
  }

  span.warning {
    color: var(--bu-orange-500);
  }

  span.error {
    color: var(--bu-red-500);
  }

  .counter {
    font-size: 10px;
    color: var(--bu-gray-700);
  }
`;

export interface IEditingFormProps {
  onCancel(): void;
  onBlur(): void;
  onChange(tmpValue: string): void;
  onSubmit(value: string): void;
  value?: string | null;
  maxLength?: number;
}

const textArea = css`
  ${fontDefault};

  color: var(--bu-gray-700);
  width: 380px;
  height: 185px;
  border: 1px solid var(--bu-primary-500);
  border-radius: 4px;
  padding: 5px;
  position: relative;
  transition: 0.4s all;

  &.error {
    transition: 0.4s all;
    border: 1px solid var(--bu-red-500);
    background-color: var(--bu-red-100);
  }
`;

const draftLabel = css`
  color: green;
  font-style: italic;
`;

type LabelProps = {
  disabled?: boolean;
  editable?: boolean;
  onClick?: () => void;
  className?: string;
};
const Label: React.FC<LabelProps> = ({
  onClick = () => {},
  editable = false,
  disabled = false,
  className,
  children,
  ...props
}: React.PropsWithChildren<LabelProps>) => (
  <div
    className={classNames(s.fluid, s.textOverflow, className, padding, {
      [editableCellBorder]: editable || disabled,
      disabled: disabled,
    })}
    onClick={onClick}
    {...props}
  >
    {children}
  </div>
);

const contentPadding = css`
  padding: 0.833em;
`;

const EditingForm: React.FC<IEditingFormProps> = ({
  onCancel,
  onBlur,
  onChange,
  onSubmit,
  value = '',
  maxLength,
}) => {
  const [text, setText] = useState(value || '');
  const inputRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
      inputRef.current.selectionStart = value?.length || 0;
    }
  }, []);

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onSubmit(text);
  };

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onCancel();
  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(e.target.value);
    onChange(e.target.value);
  };

  return (
    <div className={contentPadding} onClick={(e) => e.stopPropagation()}>
      <textarea
        className={classNames(textArea, {
          error: maxLength && text.length > maxLength,
        })}
        name="text"
        onChange={handleChange}
        onBlur={onBlur}
        ref={inputRef}
        value={text}
        data-testing="txt_field"
      />

      <BuCharLengthInfo maxLength={maxLength} textLength={text.length} />

      <BuButton
        disabled={maxLength ? text.length > maxLength : false}
        size={BuControlSize.SMALL}
        onClick={handleSubmit}
      >
        Save
      </BuButton>

      <BuButton secondary size={BuControlSize.SMALL} onClick={handleCancel}>
        Cancel
      </BuButton>
    </div>
  );
};

const resetPadding = css`
  &.ui.popup {
    padding: 0;
  }
`;

type LabelWithTooltipProps = {
  value: string;
  editable?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  className?: string;
};

const LabelWithTooltip: React.FC<LabelWithTooltipProps> = ({
  value,
  editable = false,
  disabled = false,
  onClick = () => {},
  children,
  className,
}: React.PropsWithChildren<LabelWithTooltipProps>) => (
  <>
    {value ? (
      <Popup
        style={{ width: 'auto', height: 'auto' }}
        trigger={
          <Label
            editable={editable}
            disabled={disabled}
            onClick={onClick}
            className={className}
          >
            {children}
          </Label>
        }
        hoverable
        mouseEnterDelay={500}
        position="bottom right"
        offset={[0, 0]}
      >
        <div className={classNames('note-tooltip', noteTooltip)}>
          {textToArray(value).map((line: string, i: number) => (
            <p key={i}>{line || '\u00A0'}</p>
          ))}
        </div>
      </Popup>
    ) : (
      <Label editable={editable} disabled={disabled} onClick={onClick}>
        {children}
      </Label>
    )}
  </>
);

const textToArray = (value: string): string[] =>
  !Array.isArray(value) ? String(value || '').split('\n') : value ?? [];

const firstLine = (text: string) => {
  return textToArray(text)[0];
};

const NoteCell = ({
  column,
  row,
  draftRow,
  rows,
  onChange = () => {},
  onDraftChange = () => {},
  status,
}: IDataCellProps) => {
  const [rawValue, setRawValue] = useState('');
  const [value, setValue] = useState('');
  const [draftValue, setDraftValue] = useState<string>();
  const [tmpDraftValue, setTmpDraftValue] = useState('');

  useEffect(() => {
    setRawValue(getCellValue<string>({ column, row }));
  }, [column.field, row]);

  useEffect(() => {
    setDraftValue(
      getCellValue<string>({ column, row: draftRow || { id: row.id } })
    );
  }, [column.field, draftRow]);

  useEffect(() => {
    setValue(getValue(rawValue));
  }, [rawValue]);

  useEffect(() => {
    setTmpDraftValue(draftValue || value);
  }, [draftValue, value]);

  const getValue = useCallback(
    (val: ValueProp) =>
      (column.delta ? (getValueFromDelta(val) as ValueProp) : val) as string,
    [column.delta]
  );

  const reset = () => {
    setValue(getValue(rawValue));
    setDraftValue(undefined);
    onDraftChange(column, row, undefined);
  };

  const config = (column.config as NoteCellConfig) || {};

  const [isEdit, setEdit] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const displayValue = (
    <div className={classNames(config.className, textOverflow)}>
      {firstLine(value as string)}&nbsp;
    </div>
  );

  const draftDisplayValue = (
    <div className={classNames(config.className, textOverflow, draftLabel)}>
      Draft in progress
    </div>
  );

  const { showStatus } = useHandleCellStatus(status);

  useEffect(() => {
    if (isEdit && inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEdit]);

  const handleSubmit = (newValue: string) => {
    onChange(column, row, newValue as ValueType);
    onDraftChange(column, row, undefined);
    setEdit(false);
    setDraftValue(undefined);
  };

  const handleClose = () => {
    if (tmpDraftValue !== value) {
      onDraftChange(column, row, tmpDraftValue);
    }
    setEdit(false);
  };

  const handleCancel = () => {
    reset();
    setEdit(false);
  };

  const handleBlur = () => {
    if (rawValue?.trim() !== tmpDraftValue?.trim()) {
      setDraftValue(tmpDraftValue);
    } else {
      reset();
      setEdit(false);
    }
  };

  if (!isEditable(column, row, rows)) {
    return (
      <DeltaValueCell column={column} row={row} rows={rows}>
        <LabelWithTooltip value={value}>{displayValue}</LabelWithTooltip>
      </DeltaValueCell>
    );
  }

  if (isDisable(column, row, rows)) {
    return (
      <DeltaValueCell column={column} row={row} rows={rows}>
        <LabelWithTooltip value={value} disabled>
          {displayValue}
        </LabelWithTooltip>
      </DeltaValueCell>
    );
  }

  const handleDraftValueChange = (value: string) => {
    setTmpDraftValue(value);
  };

  return (
    <DeltaValueCell column={column} row={row} rows={rows}>
      {!isEdit && (
        <LabelWithTooltip
          value={value}
          editable
          onClick={() => setEdit(true)}
          className={classNames({
            [(status && status.status) || '']: showStatus,
          })}
        >
          {draftValue !== undefined ? draftDisplayValue : displayValue}
        </LabelWithTooltip>
      )}

      {isEdit && (
        <Popup
          className={resetPadding}
          style={{ width: 'auto', height: 'auto' }}
          trigger={
            <Label
              editable
              className={classNames(editingCellBorder, {
                [(status && status.status) || '']: showStatus,
              })}
            >
              {draftValue !== undefined ? draftDisplayValue : displayValue}
            </Label>
          }
          position="bottom right"
          on="click"
          open
          onClose={handleClose}
          offset={[0, 0]}
        >
          <EditingForm
            onCancel={handleCancel}
            onBlur={handleBlur}
            onChange={handleDraftValueChange}
            onSubmit={handleSubmit}
            value={tmpDraftValue}
            maxLength={column.length}
          />
        </Popup>
      )}
    </DeltaValueCell>
  );
};

export default NoteCell;
