import classNames from 'classnames';
import isArray from 'lodash/isArray';
import isString from 'lodash/isString';
import React, {
  useState,
  useEffect,
  useMemo,
  useLayoutEffect,
  useCallback,
} from 'react';
import { useSelector } from 'react-redux';
import { selectTimeRange } from 'sharedLogicMainAppAndRevBi/timeSpanOptions';
import { formatTo } from 'sharedLogicMainAppAndRevBi/timeSpanOptions';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuIcon from 'components/UI/BuIcon';
import BuRadio from 'components/UI/BuRadio';
import { DateOperatorOption } from 'components/dashboard/Metrics/Widget/TemplateFilters/TemplateFilter/types';
import { TimeFilter } from 'components/dashboard/Metrics/common/Calendar/TimeFilter/TimeFilter';
import * as s from 'components/dashboard/Metrics/common/Calendar/styles';
import {
  BetweenDate,
  Checkbox,
  FiltersConfig,
} from 'components/dashboard/Metrics/common/Calendar/types';
import { useClickOutside } from 'components/hooks/useClickOutside';
import { getFiscalYearStartDate } from 'selectors';
import { findDOMNode } from 'react-dom';

type Props = {
  config: FiltersConfig;
  name: string;
  onChange: (values: DateOperatorOption) => void;
};

type IN_FILTER_VALUES = 'in' | 'not_in' | 'is_null';

export const DropdownCalendar: React.FC<Props> = ({
  config,
  name,
  onChange,
}) => {
  const CUSTOM_DATE_END_VALUES = [
    'days',
    'weeks',
    'months',
    'quarters',
    'years',
  ];

  const fiscalYearStartDate = useSelector(getFiscalYearStartDate);

  const { isOpen, setIsOpen, refElement } = useClickOutside();

  const [localTab, setLocalTab] = useState<string>('Predefined');
  const [operator, setOperator] = useState<IN_FILTER_VALUES>('in');
  const [position, setPosition] = useState<number[]>([0, 0]);
  const [selectedDate, setSelectedDate] = useState<string>('all_time');
  const [includeBlanks, setIncludesBlanks] = useState(false);
  const [betweenDate, setBetweenDate] = useState<BetweenDate>({
    startDate: '',
    endDate: '',
  });

  const {
    isLocked,
    title,
    values,
    fiscalYearValues,
    relativeValues,
    customPeriodValue,
    showInNotInFilter,
    isSimpleCalendar,
    includedBlankValues,
    showIncludeBlanksValues = true,
  } = config;

  const currentValue: Checkbox | undefined = useMemo(
    () =>
      fiscalYearValues?.find((item) => item.checked) ||
      relativeValues?.find((item) => item.checked) ||
      values.find((item) => item.checked),
    [fiscalYearValues, relativeValues, values]
  );

  const label: string = useMemo(() => {
    if (currentValue?.label) {
      return currentValue?.label;
    }

    if (!!customPeriodValue) {
      return customPeriodValue.split('_').join(' ');
    }

    if (!!betweenDate?.startDate && !!betweenDate?.endDate) {
      return `${betweenDate?.startDate} - ${betweenDate?.endDate}`;
    }

    return '';
  }, [currentValue?.label, customPeriodValue, betweenDate]);

  const value: string =
    currentValue?.value ||
    (typeof customPeriodValue === 'string' && customPeriodValue) ||
    (customPeriodValue?.length && customPeriodValue[0]) ||
    '';

  const isHistoricalCalendar: boolean = !!relativeValues?.length;

  useEffect(() => {
    setSelectedDate(value || 'all_time');
    setIncludesBlanks(includedBlankValues);

    if (!isSimpleCalendar) {
      const format = (date: string) =>
        formatTo(date, 'YYYY-MM-DD', 'MM/DD/YYYY');

      if (isString(value) && value.length === 21) {
        const dates = value.split(',');

        setLocalTab('Range');
        setBetweenDate({
          startDate: format(dates[0]),
          endDate: format(dates[1]),
        });
      } else if (value && value[0] && value[0].length >= 10) {
        const dates = value[0].split(',');

        setBetweenDate({
          startDate: format(dates[0]),
          endDate: format(dates[1]),
        });
      } else {
        const date = selectTimeRange(
          isArray(value) ? value : [value],
          fiscalYearStartDate,
          false
        );

        setBetweenDate({
          startDate: date ? format(Object.keys(date[0])[0]) : '',
          endDate: date ? format(date[0][Object.keys(date[0])[0]]) : '',
        });
      }

      const isPredefined = values?.some((el) => el.value === value);
      const isFiscalYear = fiscalYearValues?.some((el) => el.value === value);
      const isRelative = relativeValues?.some((el) => el.value === value);
      const isRange = value.includes(',');
      const isCustom =
        !isHistoricalCalendar &&
        value.includes('_') &&
        value.split('_').length === 3 &&
        parseInt(value.split('_')?.[1] || '') &&
        CUSTOM_DATE_END_VALUES.includes(value.split('_')?.[2]);

      if (isPredefined) {
        setLocalTab('Predefined');
      }
      if (isFiscalYear) {
        setLocalTab('Fiscal Year');
      }
      if (isRelative) {
        setLocalTab('Relative');
      }
      if (isRange) {
        setLocalTab('Range');
      }
      if (isCustom) {
        setLocalTab('Custom');
      }
    }
  }, [value]);

  const handleCalendarClick = (): void => {
    if (!isLocked) {
      setIsOpen(!isOpen);
    }
  };

  const handleChangeDetected = (id: string): void => {
    setSelectedDate(id);
    const selection = includeBlanks ? [{ id: 'is_null', checked: true }] : [];
    onChange({
      selected: [{ id, checked: true }, ...selection],
      operator: operator,
    });
  };

  const handleInclusionOfBlank = (value: boolean): void => {
    setIncludesBlanks(value);
    const selection = value ? [{ id: 'is_null', checked: true }] : [];
    onChange({
      selected: [{ id: selectedDate, checked: true }, ...selection],
      operator: operator,
    });
  };

  const handleRadioChange = (
    _: React.FormEvent<HTMLInputElement>,
    { value }: { value: IN_FILTER_VALUES }
  ): void => {
    setOperator(value);
    let dateValue = selectedDate;
    /**
     * when the user selects "Is Blank" option we must send 'is_null' as a value
     * and when the user changes to any other option we must send a default 'PAST' value.
     */
    if (value === 'is_null') {
      dateValue = 'is_null';
    } else if (dateValue === 'is_null') {
      dateValue = 'PAST';
    }
    const selection =
      includeBlanks && dateValue !== 'is_null'
        ? [{ id: 'is_null', checked: true }]
        : [];
    onChange({
      selected: [{ id: dateValue, checked: true }, ...selection],
      operator: value,
    });
  };

  const onScroll = useCallback(() => {
    setPosition([
      refElement.current?.getBoundingClientRect().top || 0,
      refElement.current?.getBoundingClientRect().left || 0,
    ]);
  }, []);

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    const maxIter = 50; // Just in case to avoid infinite loops
    const parents: ParentNode[] = [];
    let parent: ParentNode | HTMLDivElement | null | undefined =
      refElement.current;

    // Call the first time to set the initial position
    onScroll();

    // Find all the parents and attach an scroll event listener to them
    // to update the position of the dropdown in case of a scroll on any of them
    for (let i = 0; i < maxIter; i++) {
      const domNode = findDOMNode(parent as HTMLElement);
      parent = domNode?.parentNode;
      if (!parent || parent === document.body) {
        break;
      }
      parents.push(parent);
      parent.addEventListener('scroll', onScroll);
    }
    return () => {
      parents.forEach((parent) => {
        parent.removeEventListener('scroll', onScroll);
      });
    };
  }, [isOpen]);

  const isNULLOperator = useMemo(() => 'is_null' === operator, [operator]);

  return (
    <div
      data-testing="operator-value"
      id={`${name}_dropdown`}
      ref={refElement}
      className={s.panel_wrapper}
    >
      <div
        data-testing="calendar_dropdown"
        className={classNames(s.panel_dropdown_button, {
          isLocked,
          isOpen,
        })}
        onClick={handleCalendarClick}
      >
        <span className={s.panel_dropdown_name}>{title}:</span>

        {showInNotInFilter && (
          <span className={s.operator_value}>
            {isNULLOperator ? 'Is Blank' : `${operator.replace('_', ' ')}`}
          </span>
        )}

        <span
          className={classNames(s.panel_dropdown_value, {
            hided: isNULLOperator,
          })}
        >
          {label}
        </span>

        <BuIcon
          name={BoostUpIcons.Calendar}
          color="var(--bu-primary-500)"
          className={s.big_icon}
        />

        <BuIcon
          name={isOpen ? BoostUpIcons.TriangleUp : BoostUpIcons.TriangleDown}
          color="var(--bu-gray-900)"
          className={s.icon}
        />
      </div>

      {isOpen && (
        <div
          style={{
            top: position[0],
            left: position[1],
          }}
          className={classNames(s.panel_dropdown, {
            disabled: isNULLOperator,
          })}
        >
          {showInNotInFilter && (
            <section
              data-testing="operator-selection-section"
              className={s.operator_section}
            >
              <BuRadio
                label="In"
                value="in"
                checked={'in' === operator}
                onChange={handleRadioChange}
              />
              <BuRadio
                label="Not In"
                value="not_in"
                checked={'not_in' === operator}
                onChange={handleRadioChange}
              />
              <BuRadio
                label="Is Blank"
                value="is_null"
                onChange={handleRadioChange}
                checked={isNULLOperator}
              />
            </section>
          )}

          <TimeFilter
            config={config}
            betweenDate={betweenDate}
            localTab={localTab}
            isHistorical={isHistoricalCalendar}
            value={value}
            includeBlanks={includeBlanks}
            onChange={handleChangeDetected}
            onChangeLocalTab={setLocalTab}
            onClose={setIsOpen}
            setBetweenDate={setBetweenDate}
            onIncludeBlank={handleInclusionOfBlank}
            showIncludeBlanks={showIncludeBlanksValues}
          />
        </div>
      )}
    </div>
  );
};
