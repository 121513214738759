import styled from '@emotion/styled';
import { css } from 'emotion';

export const MainContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  overflow: 'auto',
  backgroundColor: 'var(--bu-primary-100)',
  position: 'relative',

  '&.no-scroll': {
    overflow: 'hidden',
  },
});

export const DashboardHeaderRow = styled.div({
  position: 'sticky',
  top: '0px',
  zIndex: 2,
  backgroundColor: 'var(--bu-white)',
  display: 'flex',
  padding: '16px 24px',
  width: '100%',
  gap: '10px',
  height: '64px',
  borderBottom: 'var(--bu-gray-400) 1px solid',
});

export const DashboardHeaderRowTitle = styled.div({
  flexGrow: 1,
  cursor: 'pointer',
});

export const DashboardHeaderRowControls = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  columnGap: '16px',
  alignItems: 'center',
});

export const DashboardWidgetsContainer = styled.div(
  (props: { shouldRenderFullscreen: boolean }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    padding: props.shouldRenderFullscreen ? 0 : '16px 24px',
    gap: '24px',
    height: '100%',
    zIndex: 1,
    overflowY: 'auto',
  })
);

export const LoaderContainer = styled.div({
  position: 'relative',
  width: '100%',
  height: '100%',
});

export const dashboardSettingContainer = css`
  padding: 8px;
`;

interface WidgetModalComponentProps {
  readonly scrollDistance?: number;
  readonly heightView?: number;
}

export const WidgetModalComponent = styled.div(
  ({ scrollDistance = 0, heightView = 0 }: WidgetModalComponentProps) => ({
    position: 'absolute',
    zIndex: 100,
    width: '100%',
    height: '0',
    top: heightView + scrollDistance,
    backgroundColor: 'white',
    transitionProperty: 'top',
    transitionDuration: '1s',
    visibility: 'hidden',

    '&.opened': {
      top: scrollDistance,
      visibility: 'visible',
      height: 'calc(100%)',
      overflowY: 'auto',
    },

    '&.closing': {
      top: heightView + scrollDistance,
      visibility: 'visible',
      height: 'calc(100%)',
    },
  })
);

export const TitleNotEditing = styled.div({
  display: 'flex',
  alignItems: 'center',
  p: {
    margin: '0px 8px 0px 0px',
    fontWeight: 315,
    fontSize: '18px',
    lineHeight: '21.6px',
  },
  '.icon-button': {
    visibility: 'hidden',
    cursor: 'pointer',
    i: {
      fontSize: '20px',
    },
  },
  '&:hover': {
    '>.icon-button': {
      visibility: 'unset',
    },
  },
});
