import styled from '@emotion/styled';
import React, { useMemo } from 'react';

import SyntheticFormulaPreview from 'components/UI/BuFormulaTextField/SyntheticFormulaPreview';
import { ConditionsDescription } from 'components/dashboard/Metrics/common/ConditionsDescription/ConditionsDescription';
import { MetricAdvancedOptionsList } from 'components/dashboard/Metrics/common/MetricAdvancedOptionsList/MetricAdvancedOptionsList';
import {
  getAggFunc,
  getMetricDescription,
} from 'components/dashboard/Metrics/common/MetricInfo/helpers';
import { MetricTag } from 'components/dashboard/Metrics/common/MetricTag/MetricTag';
import { MetricType } from 'components/dashboard/Metrics/enums';
import { hasMetricAdvancedOptions } from 'components/dashboard/Metrics/metrics.helpers';
import {
  BIMetricCreated,
  BIMetricFormula,
  BIMetricSimple,
} from 'components/dashboard/Metrics/metrics.types';

const MetricFiltersDescription = styled.div({
  color: 'var(--bu-gray-700)',
  fontSize: '12px',
  ul: {
    marginBottom: '0',
    paddingLeft: '20px',
  },
});

interface Props {
  readonly hasPopups?: boolean;
  readonly metric: BIMetricCreated;
}

export const MetricInfo: React.FC<Props> = ({ hasPopups = false, metric }) => {
  const { aggregationFunction, metricDescription } = useMemo(() => {
    const aggregationFunction = getAggFunc(
      (metric as BIMetricSimple)?.object,
      (metric as BIMetricSimple)?.aggregation_function
    );
    const metricDescription = getMetricDescription(metric as BIMetricSimple);

    return { aggregationFunction, metricDescription };
  }, [metric]);

  return (
    <>
      {metric?.metadata?.type === MetricType.Formula &&
        (metric as BIMetricFormula).synthetic_metric && (
          <SyntheticFormulaPreview
            formula={(metric as BIMetricFormula).synthetic_metric}
            hasPopups={hasPopups}
          />
        )}

      {metric?.metadata?.type === MetricType.Simple && (
        <MetricTag
          aggregationFunction={aggregationFunction}
          metricDescription={metricDescription}
        />
      )}

      {metric?.filters && metric.filters.length > 0 && (
        <MetricFiltersDescription>
          <ConditionsDescription filters={metric.filters} />
        </MetricFiltersDescription>
      )}

      {hasMetricAdvancedOptions(metric as BIMetricSimple) && (
        <MetricFiltersDescription>
          <MetricAdvancedOptionsList
            metricObject={(metric as BIMetricSimple)?.object || ''}
            managerAggregationType={
              (metric as BIMetricSimple)?.manager_aggregation_type
            }
            isCumulativeSum={(metric as BIMetricSimple)?.is_cumulative_sum}
            cumulativeSumPeriod={
              (metric as BIMetricSimple)?.cumulative_sum_period
            }
            forecastAmountAccuracyCalculation={
              (metric as BIMetricSimple)?.forecast_submission_calculation
            }
            forecastSubmissionMetric={
              (metric as BIMetricSimple).forecast_submission_metric
            }
          />
        </MetricFiltersDescription>
      )}
    </>
  );
};
