import React from 'react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuIcon from 'components/UI/BuIcon';
import { TemplateFilter } from 'components/dashboard/Metrics/Widget/TemplateFilters/TemplateFilter/TemplateFilter';
import {
  FilterBarELement,
  FiltersContainer,
  FiltersDropdownsContainer,
} from 'components/dashboard/Metrics/Widget/TemplateFilters/styles';
import {
  BIColumnListItem,
  BIMetricsFilter,
  BIWidget,
} from 'components/dashboard/Metrics/metrics.types';

interface Props {
  isDateFirstPivot?: boolean;
  showControls?: boolean;
  templateFilters?: BIMetricsFilter[];
  widgetFilters?: BIColumnListItem[];
  relativeDateFilterOptions?: Filters.DateFilterElement[];
  timeInterval?: string;
  forecastPeriod?: string;
  timePeriod?: string;
  targetPeriod?: string;
  onChangeWidget: (widget: Partial<BIWidget>) => void;
}

export const TemplateFilters: React.FC<Props> = ({
  isDateFirstPivot = false,
  showControls = false,
  templateFilters = [],
  widgetFilters = [],
  relativeDateFilterOptions = [],
  timeInterval,
  forecastPeriod,
  timePeriod,
  targetPeriod,
  onChangeWidget,
}) => (
  <>
    {!!widgetFilters.length && (
      <FiltersContainer
        showControls={showControls}
        data-testing="filters-container"
      >
        <FilterBarELement>
          <BuIcon name={BoostUpIcons.Filter} color="var(--bu-gray-600)" />
        </FilterBarELement>
        <FiltersDropdownsContainer>
          {widgetFilters.map((widgetFilter, idx) => {
            if (widgetFilter && widgetFilter.name && widgetFilter.type) {
              return (
                <FilterBarELement
                  // please do not remove the `${widgetFilters.length}` for now it force an updates the widget referenced inside the updateWidget
                  key={`${widgetFilter.type}_${widgetFilter.name}_${widgetFilter.label}_${widgetFilters.length}`}
                  data-testing={`filter-bar-element-${idx}`}
                >
                  <TemplateFilter
                    isDateFirstPivot={isDateFirstPivot}
                    widgetFilter={widgetFilter}
                    templateFilters={templateFilters}
                    relativeDateFilterOptions={relativeDateFilterOptions}
                    forecastPeriod={forecastPeriod}
                    timeInterval={timeInterval}
                    timePeriod={timePeriod}
                    targetPeriod={targetPeriod}
                    onChangeWidget={onChangeWidget}
                  />
                </FilterBarELement>
              );
            }
          })}
        </FiltersDropdownsContainer>
      </FiltersContainer>
    )}
  </>
);
