import React, { useEffect, useState } from 'react';

import { FormulaMetricInputs } from 'components/dashboard/Metrics/Create/MetricDefinitionInputs/FormulaMetricInputs';
import { MetricTypeSelection } from 'components/dashboard/Metrics/Create/MetricDefinitionInputs/MetricTypeSelection';
import { SimpleMetricsInputs } from 'components/dashboard/Metrics/Create/MetricDefinitionInputs/SimpleMetricsInputs';
import {
  FORMULA_HISTORICAL_METRIC_NEWBORN_MOCK,
  FORMULA_METRIC_NEWBORN_MOCK,
  NOT_SAVED_METRIC,
  SIMPLE_HISTORICAL_METRIC_NEWBORN_MOCK,
  SIMPLE_METRIC_NEWBORN_MOCK,
} from 'components/dashboard/Metrics/Create/constants';
import { AnalysisType } from 'components/dashboard/Metrics/constants';
import { MetricType } from 'components/dashboard/Metrics/enums';
import {
  BIMetricFormula,
  BIMetricSimple,
  BIMetricUnion,
} from 'components/dashboard/Metrics/metrics.types';

type IProps = {
  readonly isForecastSubmissionWidget: boolean | null;
  readonly metric: BIMetricUnion;
  onChangeMetricDefinitionInputs: (
    complete: boolean,
    metricInputs: Partial<BIMetricUnion>
  ) => void;
};

export const MetricDefinitionInputs: React.FC<IProps> = ({
  isForecastSubmissionWidget,
  metric,
  onChangeMetricDefinitionInputs,
}) => {
  const [metricType, setMetricType] = useState<MetricType>(
    metric.metadata.type || MetricType.Simple
  );
  const [metricInputs, setMetricInputs] =
    useState<{ complete: boolean; metricInputs: Partial<BIMetricUnion> }>();

  const handleChangeMetricType = (type: MetricType): void => {
    setMetricType(type);
    setMetricInputs((prev) => ({
      complete: false,
      metricInputs: {
        ...(type === MetricType.Simple && {
          ...(metric.analysis_type === AnalysisType.LIVE
            ? SIMPLE_METRIC_NEWBORN_MOCK
            : SIMPLE_HISTORICAL_METRIC_NEWBORN_MOCK),
        }),
        ...(type === MetricType.Formula && {
          ...(metric.analysis_type === AnalysisType.LIVE
            ? FORMULA_METRIC_NEWBORN_MOCK
            : FORMULA_HISTORICAL_METRIC_NEWBORN_MOCK),
        }),
        name: prev?.metricInputs.name ?? metric.name,
        name_identifier:
          prev?.metricInputs.name_identifier ?? metric.name_identifier,
      },
    }));
  };

  const handleCompleteMetricInputs = (
    complete: boolean,
    metricInputs: Partial<BIMetricUnion>
  ): void => {
    setMetricInputs({ complete, metricInputs });
  };

  useEffect(() => {
    onChangeMetricDefinitionInputs(!!metricInputs?.complete, {
      ...metricInputs?.metricInputs,
      metadata: {
        ...metric.metadata,
        type: metricType,
      },
    });
  }, [metricInputs, metricType]);

  return (
    <>
      <MetricTypeSelection
        metricType={metricType}
        updateMetricType={handleChangeMetricType}
        editing={metric._id !== NOT_SAVED_METRIC}
      />

      {metricType === MetricType.Simple && (
        <SimpleMetricsInputs
          isForecastSubmissionWidget={isForecastSubmissionWidget}
          metric={metric as BIMetricSimple}
          onCompleteMetricInputs={handleCompleteMetricInputs}
        />
      )}

      {metricType === MetricType.Formula && (
        <FormulaMetricInputs
          metric={metric as BIMetricFormula}
          onCompleteMetricInputs={handleCompleteMetricInputs}
        />
      )}
    </>
  );
};
