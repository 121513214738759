import React, { FC, useMemo } from 'react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import { sortAlphabetically } from 'common/helpers';
import BuIcon from 'components/UI/BuIcon';
import BuSelect from 'components/UI/BuSelect';
import { ISelectOption } from 'components/UI/BuSelect/types';
import { FilterColumnDropdownContainer } from 'components/dashboard/Metrics/Create/Conditions/Condition/ColumnSelector/styles';
import {
  FlexRow,
  RemoveIconContainer,
} from 'components/dashboard/Metrics/metrics.styles';
import {
  BIColumnListItem,
  BIMetricsFilter,
} from 'components/dashboard/Metrics/metrics.types';

type Props = {
  filter: Partial<BIMetricsFilter>;
  filterColumns: BIColumnListItem[];
  onChangeColumn: (values: string[]) => void;
  onRemove: () => void;
};

export const ColumnSelector: FC<Props> = ({
  filter,
  filterColumns,
  onChangeColumn,
  onRemove,
}) => {
  const filterOptions: ISelectOption[] = useMemo(
    () =>
      sortAlphabetically(
        filterColumns.map((column) => ({
          text: column.label,
          value: column.name,
        }))
      ),
    [filterColumns]
  );

  return (
    <FlexRow>
      <FilterColumnDropdownContainer>
        <BuSelect
          fullWidth
          isLargePlaceholder
          searchable
          secondary
          className="select"
          defaults={[filter?.column?.name ?? '']}
          options={filterOptions}
          placeholder="Select a column"
          testingLabel="column"
          onChange={onChangeColumn}
        />
      </FilterColumnDropdownContainer>
      <RemoveIconContainer onClick={onRemove}>
        <BuIcon name={BoostUpIcons.Trash} />
      </RemoveIconContainer>
    </FlexRow>
  );
};
