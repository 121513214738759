import React, { useContext } from 'react';
import {
  container,
  metricNameParagraph,
  objectNameParagraph,
  syntheticContainer,
  tagContainer,
  tooltipConditions,
  totalParagraph,
} from './styles';

import { formatMoney, formatNumber } from 'common/numbers';
import SyntheticFormulaPreview from 'components/UI/BuFormulaTextField/SyntheticFormulaPreview';
import { ConditionsDescription } from 'components/dashboard/Metrics/common/ConditionsDescription/ConditionsDescription';
import { MetricAdvancedOptionsList } from 'components/dashboard/Metrics/common/MetricAdvancedOptionsList/MetricAdvancedOptionsList';
import { MetricTag } from 'components/dashboard/Metrics/common/MetricTag/MetricTag';
import {
  AGGREGATION_OPTIONS,
  AnalysisType,
  FORECAST_SUBMISSION,
  TARGET,
} from 'components/dashboard/Metrics/constants';
import { RevBISettingsContext } from 'components/dashboard/Metrics/contexts/RevBISettingsContext';
import { MetricType } from 'components/dashboard/Metrics/enums';
import {
  formatMetricObject,
  getForecastSubmissionDescription,
  getTargetMetricDescription,
  hasMetricAdvancedOptions,
} from 'components/dashboard/Metrics/metrics.helpers';
import {
  BIMetricCreated,
  BIMetricSimple,
} from 'components/dashboard/Metrics/metrics.types';
import { isBIMetricFormula } from '../../Create/utils';
import { WidgetMetricDisplayNameMap } from '../../Widget/helper';

interface Props {
  metric: BIMetricCreated;
  total: number | string;
  prefix: string;
  sufix: string;
  isCountAgg?: boolean;
  totalType?: string;
  widgetMetricsDisplayName?: WidgetMetricDisplayNameMap;
}

export const TooltipContent: React.FC<Props> = ({
  metric,
  total,
  prefix,
  sufix,
  isCountAgg = false,
  totalType = 'number',
  widgetMetricsDisplayName,
}) => {
  const { currency } = useContext(RevBISettingsContext);

  const aggregationFunction = AGGREGATION_OPTIONS.find(
    (option) =>
      option.value === (metric as BIMetricSimple)?.aggregation_function
  )?.text;

  const columnPP = isCountAgg
    ? 'of ' + (metric as BIMetricSimple)?.column?.label
    : '';

  const hasAdvancedOptions = hasMetricAdvancedOptions(metric as BIMetricSimple);
  const isForecastSubmission =
    (metric as BIMetricSimple)?.object === FORECAST_SUBMISSION;

  const signal = total < 0 ? '-' : '';
  const totalValue =
    totalType === 'money' && typeof total === 'number'
      ? formatMoney(currency, total)
      : typeof total === 'number'
      ? `${signal}${prefix}${formatNumber(Math.abs(total), 0)}${sufix}`
      : total ?? '-';

  return (
    <div className={container}>
      {metric?.analysis_type !== AnalysisType.HISTORICAL &&
        !isForecastSubmission && <p className={totalParagraph}>{totalValue}</p>}

      <p className={metricNameParagraph}>{metric?.name}</p>

      {metric?.metadata?.type === MetricType.Simple && (
        <p className={objectNameParagraph}>
          {`${formatMetricObject(
            isForecastSubmission
              ? (metric as BIMetricSimple)?.forecast_submission_metric || ''
              : (metric as BIMetricSimple)?.object || ''
          )} object`}
        </p>
      )}

      <div className={tagContainer}>
        {isBIMetricFormula(metric) && metric?.synthetic_metric && (
          <div className={syntheticContainer}>
            <SyntheticFormulaPreview
              formula={metric.synthetic_metric}
              metricDisplayNames={widgetMetricsDisplayName}
            />
            <br />
          </div>
        )}

        {metric?.metadata?.type === MetricType.Simple && (
          <>
            {(metric as BIMetricSimple)?.object === TARGET ? (
              <MetricTag
                aggregationFunction="target"
                metricDescription={getTargetMetricDescription(
                  (metric as BIMetricSimple)?.target_period,
                  (metric as BIMetricSimple)?.target_type
                )}
              />
            ) : (
              <>
                {isForecastSubmission ? (
                  <MetricTag
                    aggregationFunction="forecast"
                    metricDescription={getForecastSubmissionDescription(
                      (metric as BIMetricSimple).forecast_submission_metric,
                      (metric as BIMetricSimple).forecast_submission_type
                    )}
                  />
                ) : (
                  <MetricTag
                    aggregationFunction={
                      (metric as BIMetricSimple)?.aggregation_function || ''
                    }
                    metricDescription={`${aggregationFunction} ${columnPP}`}
                  />
                )}
              </>
            )}
          </>
        )}
      </div>

      {metric?.filters && metric.filters.length > 0 && (
        <div className={tooltipConditions}>
          <ConditionsDescription filters={metric.filters} />
        </div>
      )}

      {hasAdvancedOptions && (
        <div className={tooltipConditions}>
          <MetricAdvancedOptionsList
            metricObject={(metric as BIMetricSimple)?.object || ''}
            managerAggregationType={
              (metric as BIMetricSimple)?.manager_aggregation_type
            }
            isCumulativeSum={(metric as BIMetricSimple)?.is_cumulative_sum}
            cumulativeSumPeriod={
              (metric as BIMetricSimple)?.cumulative_sum_period
            }
            forecastAmountAccuracyCalculation={
              (metric as BIMetricSimple)?.forecast_submission_calculation
            }
          />
        </div>
      )}
    </div>
  );
};
