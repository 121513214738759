import styled from '@emotion/styled';
import { css } from 'emotion';

import { fontDefault, fontHeading, fontSubtitle } from 'assets/css/common';

export const QUARTER_DELIMITER_COLUMN = 'q-delimiter-col';

export const dropdownArea = (hover: boolean) => css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px dashed var(--bu-primary-500);
  border-radius: 4px;
  margin: 15px 30px 20px 30px;
  background-color: ${hover ? 'var(--bu-primary-200)' : 'var(--bu-gray-200)'};
  height: 500px;
  ${fontHeading};
`;

export const fullscreen = css`
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  position: absolute;

  background-color: var(--bu-white);
  z-index: 990;
`;

export const header = css`
  width: 100%;
  height: 50px;
  border-bottom: 1px solid var(--bu-gray-500);
  display: grid;
  grid-template-columns: 10fr 14fr 60px;
`;

export const stepHeaderMark = css`
  border: 1px solid var(--bu-gray-500);
  border-radius: 50%;
  background-color: var(--bu-gray-300);
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  color: var(--bu-gray-700);

  &.selected {
    color: var(--bu-primary-500);
    border: 1px solid var(--bu-primary-500);
    background-color: var(--bu-primary-200);
  }
  &.completed {
    border: 1px solid var(--bu-green-500);
    background-color: var(--bu-green-500);
  }
  ${fontDefault}
`;

export const headerControlsContainer = css`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const stepHeader = (selected: boolean) => css`
  display: flex;
  align-items: center;
  color: ${selected ? 'var(--bu-primary-500)' : 'var(--bu-gray-700)'};
  font-weight: 'normal';
  margin-right: 30px;
  ${fontDefault}
`;

export const headerTitle = css`
  color: var(--bu-gray-900);
  display: flex;
  align-items: center;
  padding: 0 20px;
`;

export const dropdownLabel = css`
  ${fontHeading};
  color: var(--bu-gray-900);
  font-size: 16px;
`;

export const browseFilesButton = css`
  padding: 5px 10px;
  white-space: nowrap;
  border: 1px solid var(--bu-primary-500);
  border-radius: 4px;
  font-size: 14px;
  background-color: var(--bu-gray-100);
  font-family: var(--bu-font-regular);
  cursor: pointer;
  color: var(--bu-primary-500);
  transition: 0.4s;
`;

export const fileInput = css`
  position: fixed;
  top: -100%;
  opacity: 0;
`;

export const noteContainer = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--bu-orange-300);
  margin: 20px 30px 0 30px;
  min-height: 50px;
  border-radius: 4px;
  padding: 0 20px;
  ${fontHeading}
`;

export const button = css`
  margin-right: 30px;
  padding: 5px 10px;
  white-space: nowrap;
  background: var(--bu-primary-500);
  border: 1px solid var(--bu-primary-500);
  border-radius: 4px;
  ${fontSubtitle}
  cursor: pointer;
  color: var(--bu-white);
  transition: 0.4s;
  margin-left: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  &.disabled {
    background: none;
    border: none;
    color: var(--bu-gray-700);
  }
`;

export const closeButton_box = css`
  border-left: 1px solid var(--bu-gray-500);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const closeButton = css`
  width: 100%;
  height: 100%;
  position: relative;
  cursor: pointer;
  i {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: color 0.4s;
    color: var(--bu-gray-900);
    font-size: var(--bu-font-size-big);
  }
`;

export const chipContainer = css`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 50px;
  padding-left: 20px;
`;

export const chipListHeading = css`
  color: var(--bu-gray-900);
  margin-right: 10px;
  font-size: 14px;
  ${fontSubtitle}
`;

export const chip = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 6px;
  align-items: center;
  background-color: var(--bu-gray-200);
  border-radius: 2px;
  color: var(--bu-gray-900);
  height: 25px;
  padding: 0 10px;
  text-transform: capitalize;
  ${fontDefault}
  span {
    color: var(--bu-gray-700);
    margin-right: 6px;
  }
`;

export const tableContainer = css`
  height: 100%;
  overflow: auto;
  margin-bottom: 40px;

  .ui.table.structured.compact > tbody > tr {
    &.topRow {
      background-color: var(--bu-gray-100);

      &:nth-child(2n) {
        background-color: var(--bu-gray-100);

        td:first-child {
          background-color: var(--bu-gray-100);
        }

        &:hover td {
          background: var(--bu-gray-300);
        }
      }

      td:first-child {
        background-color: var(--bu-gray-100);
      }

      &:hover td {
        background: var(--bu-gray-300);
      }

      .typed-table-cell {
        font-family: var(--bu-font-medium);
        font-size: 14px;
      }
    }

    &.empty {
      .typed-table-cell {
        opacity: 0;
        pointer-events: none;

        .hoverable {
          color: white;
        }
      }
    }

    &:nth-child(2n) {
      background-color: var(--bu-white);

      td:first-child {
        background-color: var(--bu-white);
      }

      &:hover td {
        background: var(--bu-gray-300);
      }
    }

    &.noEdit {
      pointer-events: none;

      td:first-child {
        pointer-events: auto;
      }
    }
  }

  .ui.table {
    &
      > tbody
      > tr
      > td.${QUARTER_DELIMITER_COLUMN},
      &
      > thead
      > tr
      > th.${QUARTER_DELIMITER_COLUMN} {
      border-left: 1px solid var(--bu-gray-500);
    }
  }
`;

export const importTargetsContainer = css`
  display: flex;
  justify-content: flex-end;
  position: sticky;
  margin-top: auto;
  align-items: center;
  bottom: 0;
  background: var(--bu-white);
  position: fixed;
  z-index: 990;
  width: 100%;
  height: 52px;
  border-top: 1px solid var(--bu-gray-400);
  box-shadow: 0 0 2px 3px rgb(0 0 0 / 8%), 0 0 2px 1px rgb(0 0 0 / 15%);
`;

export const panelButton = css`
  border-radius: 4px;
  height: 32px;
  cursor: pointer;
  ${fontSubtitle}
  text-transform: inherit;
  color: var(--bu-primary-500);
  background: transparent;
  border: none;
  transition: 0.4s;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &:hover,
  &:focus {
    background: var(--bu-gray-200);
    outline: none;
  }
`;

export const checkIcon = css`
  color: var(--bu-white);
  font-size: small;
  margin: 10px 0 13px 0px !important;
`;

export const checkModalIcon = css`
  &.icon.green {
    font-size: 40px;
    line-height: 40px;
    color: var(--bu-green-500) !important;
  }
`;

export const ErrorMessage = styled.div`
  display: flex;
  border: 1px solid var(--bu-red-500);
  background-color: var(--bu-red-200);
  border-radius: var(--bu-control-border-radius);
  color: var(--bu-red-500);
  padding: 15px;
  margin: 20px 30px 0 30px;
`;

export const container = css`
  display: flex;
  flex-direction: column;
  height: calc(100% - 52px);
  justify-content: start;
`;

export const targetCellContainer = css`
  display: flex;
  flex-direction: column;
  height: 50px;
  justify-content: center;
`;

export const targetCellValue = css`
  font-size: 17px;
`;

export const targetCellSubValue = css`
  font-size: 12px;
  color: var(--bu-gray-700);
`;

export const cellGreen = css`
  background-color: var(--bu-green-200);
`;

export const cellRed = css`
  background-color: var(--bu-red-200) !important;
`;

export const capitalizeText = css`
  text-transform: capitalize;
`;
