import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Loader } from 'semantic-ui-react';
import { StringParam, useQueryParam } from 'use-query-params';

import * as metricActions from 'actions/revbi/metrics';
import BuConfirmationPopup from 'components/UI/BuConfirmationPopup';
import { Header } from 'components/dashboard/Metrics/Create/Header/Header';
import { MetricOptions } from 'components/dashboard/Metrics/Create/MetricCreate/MetricOptions/MetricOptions';
import { MetricPreview } from 'components/dashboard/Metrics/Create/MetricCreate/MetricPreview/MetricPreview';
import { BASE_PREVIEW_WIDGET } from 'components/dashboard/Metrics/Create/MetricCreate/constants';
import {
  CreationType,
  FORECAST_SUBMISSION_DEFAULT_PIVOTS,
  SIMPLE_METRIC_NEWBORN_MOCK,
} from 'components/dashboard/Metrics/Create/constants';
import { FlexColumn } from 'components/dashboard/Metrics/Create/styles';
import {
  isBIMetricFormula,
  isBIMetricSimple,
  isFormulaMetricCanPreview,
  isFormulaMetricValid,
  isMetricFiltersValid,
  isObjectIncluded,
  isSimpleMetricCanPreview,
  isSimpleMetricValid,
} from 'components/dashboard/Metrics/Create/utils';
import {
  AnalysisType,
  FORECAST_SUBMISSION_OBJECTS,
} from 'components/dashboard/Metrics/constants';
import { RevBISettingsProvider } from 'components/dashboard/Metrics/contexts/RevBISettingsContext';
import { UsersByActivityProvider } from 'components/dashboard/Metrics/contexts/UsersByActivityContext';
import { VisualizationType } from 'components/dashboard/Metrics/enums';
import {
  dispatchIfNotAsked,
  parseSaveRedirect,
} from 'components/dashboard/Metrics/metrics.helpers';
import { FlexRow } from 'components/dashboard/Metrics/metrics.styles';
import {
  BIMetricSimple,
  BIMetricFormula,
  BIMetricUnion,
  BIWidget,
} from 'components/dashboard/Metrics/metrics.types';
import * as metricSelectors from 'selectors/revbi/metrics';
import { history } from 'store/configureStore';
import { QueryStatus, fetchApi } from 'utils/network';
import { fetchApiWithoutCb } from 'utils/network/fetchApiWithoutCb';

export const MetricCreate: React.FC = () => {
  const dispatch = useDispatch();

  // query params
  const match = useRouteMatch<{ metricId: string }>();
  const [saveRedirect] = useQueryParam('saveRedirect', StringParam);

  // Holds the state of the original metric before user interaction.
  // This is the initial state from which we start, whether we're in the process of creating or editing a metric.
  const [originalMetric, setOriginalMetric] = useState<BIMetricUnion>(
    SIMPLE_METRIC_NEWBORN_MOCK
  );

  // Holds the state of the updated metric after user changes.
  // This state represents the current user changes (draft) in the process of creating a new metric or editing an existing one.
  const [userModifiedMetric, setUserModifiedMetric] = useState<BIMetricUnion>(
    SIMPLE_METRIC_NEWBORN_MOCK
  );

  const [fetchMetricStatus, setFetchMetricStatus] =
    useState<QueryStatus>('notAsked');

  const [touched, setTouched] = useState<boolean>(false);

  const [isSaveAndCloneDialogOpen, setIsSaveAndCloneDialogOpen] =
    useState<boolean>(false);
  const [isDelConfirmationOpen, setIsDelConfirmationOpen] =
    useState<boolean>(false);

  // Widget wrapper in order to render the metric
  const [previewWidget, setPreviewWidget] =
    useState<BIWidget>(BASE_PREVIEW_WIDGET);

  const isEditMode = !!match.params.metricId;

  // selectors
  const objectListStatus = useSelector(metricSelectors.getObjectListStatus);
  const timeSeriesObjectsListStatus = useSelector(
    metricSelectors.getObjectsListHistoryStatus
  );
  const metricsWithTSStatus = useSelector(
    metricSelectors.getAllMetricsWithTSStatus
  );
  const metricsListStatus = useSelector(metricSelectors.getMetricsListStatus);
  const timeOptionsStatus = useSelector(metricSelectors.getTimeOptionsStatus);
  const quarterOptionsStatus = useSelector(
    metricSelectors.getQuarterForecastPeriodOptionsStatus
  );
  const monthOptionsStatus = useSelector(
    metricSelectors.getMonthForecastPeriodOptionsStatus
  );
  const weekOptionsStatus = useSelector(
    metricSelectors.getWeekForecastPeriodOptionsStatus
  );
  // selectors end

  const isReadyForSave: boolean = useMemo(
    () =>
      userModifiedMetric !== null &&
      isMetricFiltersValid(userModifiedMetric) &&
      ((isBIMetricSimple(userModifiedMetric) &&
        isSimpleMetricValid(userModifiedMetric)) ||
        (isBIMetricFormula(userModifiedMetric) &&
          isFormulaMetricValid(userModifiedMetric))),
    [userModifiedMetric]
  );

  const isReadyForPreview: boolean = useMemo(() => {
    const metricsListValidation = previewWidget.metric_list.reduce(
      (acc: boolean, metricInList: BIMetricUnion) => {
        const hasValidConditions = metricInList.filters?.length
          ? isMetricFiltersValid(metricInList)
          : true;

        if (hasValidConditions && isBIMetricSimple(metricInList)) {
          return acc && isSimpleMetricCanPreview(metricInList);
        }

        if (hasValidConditions && isBIMetricFormula(metricInList)) {
          return acc && isFormulaMetricCanPreview(metricInList);
        }
        return false;
      },
      true
    );

    return previewWidget.metric_list.length ? metricsListValidation : false;
  }, [userModifiedMetric, previewWidget]);

  useEffect(() => {
    dispatchIfNotAsked(
      dispatch,
      metricActions.fetchObjectList,
      objectListStatus
    );
    dispatchIfNotAsked(
      dispatch,
      metricActions.fetchTimeSeriesObjectList,
      timeSeriesObjectsListStatus
    );
    dispatchIfNotAsked(
      dispatch,
      metricActions.fetchAllMetrics,
      metricsListStatus
    );
    dispatchIfNotAsked(
      dispatch,
      metricActions.fetchAllTSMetrics,
      metricsWithTSStatus
    );
    dispatchIfNotAsked(
      dispatch,
      metricActions.fetchTimeOptions,
      timeOptionsStatus
    );
    dispatchIfNotAsked(
      dispatch,
      metricActions.fetchQuarterForecastPeriodTimeOptions,
      quarterOptionsStatus
    );
    dispatchIfNotAsked(
      dispatch,
      metricActions.fetchMonthForecastPeriodTimeOptions,
      monthOptionsStatus
    );
    dispatchIfNotAsked(
      dispatch,
      metricActions.fetchWeekForecastPeriodTimeOptions,
      weekOptionsStatus
    );
  }, []);

  useEffect(() => {
    const abortController = new AbortController();

    if (match.params.metricId) {
      fetchApi<undefined, BIMetricSimple | BIMetricFormula>({
        url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/metrics/${match.params.metricId}`,
        queryMethod: 'get',
        setData: (result) => {
          if (
            result &&
            (isBIMetricSimple(result) || isBIMetricFormula(result))
          ) {
            setOriginalMetric(result);
            setUserModifiedMetric(result);
            setPreviewWidget({
              ...BASE_PREVIEW_WIDGET,
              analysis_type: result.analysis_type,
            });
          }
        },
        setError: (error: string | null) => {
          toast.error(`Failed to fetch metric: ${error}`);
        },
        setStatus: setFetchMetricStatus,
        signal: abortController.signal,
      });
    }

    return () => {
      setOriginalMetric(SIMPLE_METRIC_NEWBORN_MOCK);
      setUserModifiedMetric(SIMPLE_METRIC_NEWBORN_MOCK);
      abortController.abort();
    };
  }, [match.params.metricId]);

  useEffect(() => {
    if (originalMetric) {
      if (userModifiedMetric.analysis_type === AnalysisType.HISTORICAL) {
        /**
         * this is needed because the BE is not persisting this prop when the Analysis type is Historical
         */
        const tempMetric = { ...userModifiedMetric };
        delete (tempMetric as BIMetricSimple).cumulative_sum_period;
      }

      let isIncluded = isObjectIncluded(userModifiedMetric, originalMetric);
      // evaluating is the user removes one filter.
      if (userModifiedMetric.filters.length) {
        isIncluded =
          isIncluded &&
          originalMetric.filters.length === userModifiedMetric.filters.length;
      }

      // if the new metric is included in the initial metric it means the user doesn't performed any change.
      setTouched(!isIncluded);
    } else {
      setTouched(false);
    }

    let newPreviewWidget: Partial<BIWidget> = {
      metric_list: [userModifiedMetric],
    };

    /**
     * the next lines defaults the correct pivots in case the user selects a FS object.
     */
    if (isBIMetricSimple(userModifiedMetric)) {
      const hasFSObject = FORECAST_SUBMISSION_OBJECTS.includes(
        userModifiedMetric.object
      );

      if (hasFSObject) {
        const widgetForecastSubmissionMetric = (
          previewWidget.metric_list[0] ?? {}
        ).forecast_submission_metric;
        if (
          userModifiedMetric.forecast_submission_metric !==
          widgetForecastSubmissionMetric
        ) {
          newPreviewWidget = {
            ...newPreviewWidget,
            ...FORECAST_SUBMISSION_DEFAULT_PIVOTS,
            properties: {
              metricToChartType: [
                {
                  chartType: VisualizationType.ColumnStacked,
                  metricId: originalMetric._id,
                },
              ],
            },
          };
        }
      }
    }

    setPreviewWidget((prev) => ({
      ...prev,
      ...newPreviewWidget,
    }));
  }, [userModifiedMetric, originalMetric]);

  const handleUpdateMetricPerAnalysisType = (
    newMetric: BIMetricUnion
  ): void => {
    setUserModifiedMetric(newMetric);
    setPreviewWidget({
      ...BASE_PREVIEW_WIDGET,
      analysis_type: newMetric.analysis_type,
    });
  };

  const handleNameChange = (name: string): void => {
    setUserModifiedMetric((prev) => ({ ...prev, name }));
  };

  const handleChangeMetricDefinitionInputs = (
    complete: boolean,
    metricInputs: Partial<BIMetricUnion>
  ): void => {
    let additionalDefaultConfig = {};

    setUserModifiedMetric((prev) => ({
      ...prev,
      ...additionalDefaultConfig,
      ...metricInputs,
    }));
  };

  const cloneThisMetric = (pMetric: BIMetricSimple | BIMetricFormula): void => {
    fetchApiWithoutCb<BIMetricUnion, BIMetricSimple | BIMetricFormula>({
      url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/metrics/${pMetric._id}/clone`,
      queryMethod: 'get',
    }).then(({ result }) => {
      if (result?._id) {
        history.push(`/revbi/metrics/edit/${result?._id}`);
        toast.success(`Metric "${result?.name}" has been cloned`);
      } else {
        toast.success('Some went wrong cloning the metric, please try again.');
      }
    });
  };

  const handleClone = (): void => {
    if (touched) {
      setIsSaveAndCloneDialogOpen(true);
    } else if (
      isBIMetricFormula(userModifiedMetric) ||
      isBIMetricSimple(userModifiedMetric)
    ) {
      cloneThisMetric(userModifiedMetric);
    }
  };

  const handleSave = (): void => {
    let redirect = '/revbi/metrics/list';

    if (saveRedirect) {
      redirect = parseSaveRedirect(saveRedirect);
    }

    if (!isEditMode && isReadyForSave) {
      toast.success('Saving metric... ');
      fetchApiWithoutCb<BIMetricUnion, BIMetricSimple | BIMetricFormula>({
        url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/metrics`,
        queryMethod: 'post',
        queryParams: userModifiedMetric,
      })
        .then(() => {
          history.push(redirect);
          toast.success('Metric saved.');
        })
        .catch(() => {
          toast.error(`Error! Metric isn't saved.`);
        });
    } else if (
      isEditMode &&
      isReadyForSave &&
      (isBIMetricSimple(userModifiedMetric) ||
        isBIMetricFormula(userModifiedMetric))
    ) {
      fetchApiWithoutCb<BIMetricUnion, BIMetricSimple | BIMetricFormula>({
        url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/metrics/${userModifiedMetric._id}`,
        queryMethod: 'put',
        queryParams: userModifiedMetric,
      })
        .then(() => {
          history.push(redirect);
          toast.success('Metric saved.');
        })
        .catch(() => {
          toast.error(`Error! Metric isn't saved.`);
        });
    }
  };

  const handleSaveAndCloneConfirm = (): void => {
    if (
      isEditMode &&
      isReadyForSave &&
      (isBIMetricFormula(userModifiedMetric) ||
        isBIMetricSimple(userModifiedMetric))
    ) {
      toast.warning('Cloning...');
      fetchApiWithoutCb<BIMetricUnion, BIMetricSimple | BIMetricFormula>({
        url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/metrics/${userModifiedMetric._id}`,
        queryMethod: 'put',
        queryParams: userModifiedMetric,
      }).then(() => {
        cloneThisMetric(userModifiedMetric);
      });
    }
  };

  const handleDelete = (): void => {
    setIsDelConfirmationOpen(true);
  };

  const handleDeleteConfirm = (): void => {
    if (
      (isBIMetricSimple(userModifiedMetric) ||
        isBIMetricFormula(userModifiedMetric)) &&
      userModifiedMetric._id
    ) {
      fetchApiWithoutCb({
        url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/metrics/${userModifiedMetric._id}`,
        queryMethod: 'delete',
      }).then(() => {
        history.push('/revbi/metrics/list');
        toast.success(`Metric "${userModifiedMetric.name}" has been removed`);
      });
    }
  };

  if (userModifiedMetric === null || fetchMetricStatus === 'loading') {
    return <Loader active content="Loading" />;
  }

  return (
    <>
      <UsersByActivityProvider includeDisabledUsers={false}>
        <RevBISettingsProvider>
          <FlexColumn>
            <Header
              id={
                isEditMode
                  ? (userModifiedMetric as BIMetricSimple)._id
                  : undefined
              }
              name={userModifiedMetric.name}
              type={CreationType.METRIC}
              isSaveDisabled={!(isReadyForSave && touched)}
              onNameChange={handleNameChange}
              onClone={handleClone}
              onDelete={handleDelete}
              onSave={handleSave}
            />

            <FlexRow
              cssProps={{ height: '100%' }}
              data-testing="metric-section"
            >
              <MetricOptions
                isEditMode={isEditMode}
                metric={userModifiedMetric}
                updateMetricPerAnalysisType={handleUpdateMetricPerAnalysisType}
                onChangeMetricDefinitionInputs={
                  handleChangeMetricDefinitionInputs
                }
              />

              <MetricPreview
                isWidgetReadyForPreview={isReadyForPreview}
                metric={userModifiedMetric}
                previewWidget={previewWidget}
                setPreviewWidget={setPreviewWidget}
              />
            </FlexRow>
          </FlexColumn>
        </RevBISettingsProvider>
      </UsersByActivityProvider>

      <BuConfirmationPopup
        headerText="Save changes and clone metric"
        isOpen={isSaveAndCloneDialogOpen}
        onClose={() => {
          setIsSaveAndCloneDialogOpen(false);
        }}
        onConfirm={() => {
          setIsSaveAndCloneDialogOpen(false);
          handleSaveAndCloneConfirm();
        }}
      >
        There are unsaved changes in the metric. Do you want to save and clone
        the metric?
      </BuConfirmationPopup>

      <BuConfirmationPopup
        cancelText="No"
        confirmText="Yes"
        headerText="Confirmation Required!"
        isOpen={isDelConfirmationOpen}
        onClose={() => {
          setIsDelConfirmationOpen(false);
        }}
        onConfirm={() => {
          setIsDelConfirmationOpen(false);
          handleDeleteConfirm();
        }}
      >
        Are you sure you want to delete this? It will be permanently removed.
      </BuConfirmationPopup>
    </>
  );
};
