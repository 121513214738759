import { FC, useEffect, useMemo } from 'react';

import { IColumn, IRow } from 'components/UI/common/TypedTable/TypedTable';
import { HistoricalWidgetControls } from 'components/dashboard/Metrics/Widget/Controls/Historical/HistoricalWidgetControls';
import {
  RefreshButtonConfiguration,
  WidgetHeader,
} from 'components/dashboard/Metrics/Widget/Header/WidgetHeader';
import { WidgetVisualization } from 'components/dashboard/Metrics/Widget/Visualization/WidgetVisualization';
import { HierarchicalWidget } from 'components/dashboard/Metrics/Widget/hooks/useHierarchicalWidgetFetching/useHierarchicalWidgetFetching.helper';
import { WidgetContainer } from 'components/dashboard/Metrics/Widget/widgets.styles';
import { VisualizationType } from 'components/dashboard/Metrics/enums';
import {
  BIMetricToChartType,
  BIMetricsMap,
  BIWidget,
} from 'components/dashboard/Metrics/metrics.types';
import { QueryStatus } from 'utils/network';
import { OnChartDataClick } from '../../Chart/WidgetChart.types';
import { useIsMutating } from '@tanstack/react-query';

interface Props {
  readonly isControlsShown: boolean;
  readonly isDashboardModal?: boolean;
  readonly isDashboardWidget?: boolean;
  readonly isMetricPreview?: boolean;
  readonly isReadOnly?: boolean;
  readonly dashboardName?: string;
  readonly metricsInUse: BIMetricsMap;
  readonly widget: BIWidget;
  readonly widgetData: HierarchicalWidget;
  readonly widgetDataStatus: QueryStatus;
  readonly isTableRefetching?: boolean;
  onCloneWidget?: (metricId: string) => void;
  onEditWidget?: (metricId: string) => void;
  onRemoveWidget?: (metricId: string) => void;
  onTitleClicked?: () => void;
  onCloseWidgetModal?: () => void;
  onChartDataClick: OnChartDataClick;
  onTableDataClick: (column: IColumn, row: IRow) => void;
  onUpdateWidget: (changes: Partial<BIWidget>) => void;
  addSubTreeToFetch?: (subTree: string) => void;
  onCleanCache: () => void;
}

export const BaseHistoricalWidget: FC<Props> = ({
  isControlsShown = false,
  isDashboardModal = false,
  isDashboardWidget = false,
  isMetricPreview = false,
  isReadOnly = false,
  dashboardName = '',
  metricsInUse,
  widget,
  widgetData,
  widgetDataStatus,
  isTableRefetching,
  onEditWidget,
  onCloneWidget,
  onRemoveWidget,
  onTitleClicked,
  onCloseWidgetModal,
  onChartDataClick,
  onTableDataClick,
  onUpdateWidget,
  addSubTreeToFetch,
  onCleanCache,
}) => {
  const metricToChartType = useMemo<BIMetricToChartType[]>(() => {
    if (widget?.properties?.metricToChartType) {
      return widget?.properties?.metricToChartType;
    }

    // Case for metric creation
    if (isMetricPreview && Object.keys(metricsInUse).length === 1) {
      return [
        {
          chartType:
            widget.chart_type === VisualizationType.Table
              ? VisualizationType.Table
              : VisualizationType.Column,
          metricId: Object.keys(metricsInUse)[0],
        },
      ];
    }

    return [];
  }, [JSON.stringify(widget?.properties?.metricToChartType)]);

  // triggered when metric is added to widget and updates visualization types
  useEffect(() => {
    const metricToChartTypes = Object.keys(metricsInUse).map(
      (metricId: string) => {
        const current = metricToChartType.find(
          (mtct) => mtct.metricId === metricId
        );

        if (current && current.chartType !== VisualizationType.Pie) {
          return current;
        }

        return {
          chartType:
            widget.chart_type === VisualizationType.Table
              ? VisualizationType.Table
              : VisualizationType.Column,
          metricId,
        };
      }
    );

    onUpdateWidget({
      properties: {
        ...widget?.properties,
        metricToChartType: metricToChartTypes,
      },
    });
  }, [JSON.stringify(metricsInUse)]);

  const handleTableSortChange = (columnName: string): void => {
    onUpdateWidget({
      properties: {
        ...widget?.properties,
        table_view_order_by_column: columnName,
      },
    });
  };

  const isTableVisualization = metricToChartType.some(
    (el) => el.chartType === 'table'
  );

  // checks if any mutation is running to lock the refresh button.
  const isMutatingInProgress = useIsMutating(['updateObjectField']);

  const refreshButtonConfiguration = useMemo<RefreshButtonConfiguration>(
    () => ({
      cacheDate: '',
      showRefreshButton: isTableVisualization,
      refreshButtonLoading: !!isTableRefetching,
      disabled: isMutatingInProgress > 0 || widgetDataStatus === 'loading',
      onRefreshData: onCleanCache,
    }),
    [
      isTableVisualization,
      isTableRefetching,
      isMutatingInProgress,
      onCleanCache,
      widgetDataStatus,
    ]
  );

  return (
    <WidgetContainer
      isDashboard={isDashboardWidget}
      isMetricsPreview={isMetricPreview}
      data-testing="widget-container"
    >
      <WidgetHeader
        id={widget.id}
        name={widget.name}
        dashboardName={dashboardName}
        isCreateEditMetric={isMetricPreview}
        isDashboardWidget={isDashboardWidget}
        isDashboardModal={isDashboardModal}
        onEditWidget={onEditWidget}
        onCloneWidget={onCloneWidget}
        onRemoveWidget={onRemoveWidget}
        onTitleClicked={onTitleClicked}
        onCloseWidgetModal={onCloseWidgetModal}
        data-testing="widget-header"
        refreshButtonConfig={refreshButtonConfiguration}
      />

      {isControlsShown && (
        <HistoricalWidgetControls
          widget={widget}
          metricToChartType={metricToChartType}
          onUpdateWidget={onUpdateWidget}
          data-testing="metrics-widget-controls"
        />
      )}

      <WidgetVisualization
        widgetDataStatus={widgetDataStatus}
        isTableRefetching={isTableRefetching}
        isDashboardWidget={isDashboardWidget && !isDashboardModal}
        isReadOnly={isReadOnly}
        metricsInUse={metricsInUse}
        metricToChartType={metricToChartType}
        showControls={isControlsShown}
        widget={widget}
        widgetData={widgetData}
        onUpdateWidget={onUpdateWidget}
        onChartDataClick={onChartDataClick}
        onTableDataClick={onTableDataClick}
        onTableSortChange={handleTableSortChange}
        onTotalsClick={() => {}}
        addSubTreeToFetch={addSubTreeToFetch}
        data-testing="widget-visualization"
      />
    </WidgetContainer>
  );
};
