import MultiCurrencyIconTooltip from '../renderers/common/MultiCurrencyIconTooltip';
import classNames from 'classnames';
import { css } from 'emotion';
import moment from 'moment';
import * as R from 'ramda';
import React from 'react';
import { useSelector } from 'react-redux';
import { Popup } from 'semantic-ui-react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuIcon from 'components/UI/BuIcon';
import styles from 'components/UI/DealsFlatTableTS/Table/styles';
import {
  CRMMetadataType,
  IDataCellProps,
  IRow,
  TypedTableCellConfig,
  IRowWithChangesSince,
  ValueProp,
} from 'components/UI/common/TypedTable/TypedTable';
import { ColumnTypes } from 'components/UI/common/TypedTable/renderers';
import { OppSplitTooltip } from 'components/UI/common/TypedTable/renderers/common/OppSplitTooltip';
import TooltipWrapper from 'components/UI/common/TypedTable/renderers/common/TooltipWrapper';
import * as selectors from 'selectors';

const wrapper = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin: -2px -10px;

  .multi_currency-container {
    margin-right: 20px;
  }
`;

const cell = css`
  box-shadow: inset 0 0 0 2px var(--bu-white);

  .bu-icon-multi_currency {
    font-size: 0.7em;
    font-size: inherit;
  }

  &.up {
    background-color: var(--bu-green-200);
  }

  &.down {
    background-color: var(--bu-red-200);
  }
`;

const valueStyle = css`
  color: var(--bu-gray-900);
  letter-spacing: 0;
  line-height: 16px;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex: 1;
`;

const displayValueStyle = css`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  margin-right: 5px;
`;

const valueContainer = css`
  display: flex;
  align-items: center;
`;

const deltaValueStyle = css`
  color: var(--bu-gray-700);
  font-size: 0.85em;
  letter-spacing: 0;
  line-height: 15px;
  margin-top: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-right: 20px;
`;

const popupWidth = css`
  min-width: 300px;
  max-height: 280px;
`;

const alignRight = css`
  text-align: right;
`;

const alignCenter = css`
  text-align: center;
`;

const arrowMargin = css`
  align-self: center;
  text-align: center;
  line-height: 1;
  font-size: 6px;
  padding: 5px;
  border-radius: 4px;

  &.up {
    background-color: var(--bu-green-500);
    color: var(--bu-white);
  }

  &.down {
    background-color: var(--bu-red-400);
    color: var(--bu-white);
  }
`;

const deltaIconSpaceHolder = css`
  display: inline-block;
  width: 19px;
`;

type ISequence = {
  display_name: string;
  kind: string;
  sequence: number;
  type: string;
};

interface IChangeCellProps extends IDataCellProps {
  row: IRowWithChangesSince;
}

export interface NodeConfig extends TypedTableCellConfig {
  renderer: React.FC<IDataCellProps>;
}

const dateFormatter = (format: string = '', timestamp: string): string => {
  if (timestamp && timestamp !== '-') {
    if (['ago', 'to'].includes(format)) {
      return moment(timestamp).fromNow();
    }

    if (format === 'local') {
      return moment(timestamp).local().format('DD MMM YYYY, h:mm A');
    }

    return moment(timestamp).format(format || "Do MMM 'YY");
  }

  return '-';
};

const getIdx = (
  sequence: ISequence[],
  name: string,
  sequenceType: string
): number => {
  const sequencyTypeMatches = (i: ISequence) =>
    ['forecast_category', 'forecast_category_name'].includes(sequenceType)
      ? i.type.startsWith('forecast_category')
      : i.type === sequenceType;
  const elemSearched = sequence.find(
    (i: ISequence) => i.display_name === name && sequencyTypeMatches(i)
  );
  const elem = elemSearched || { kind: '', sequence: 0 };

  if (elem.kind === 'won') {
    const maxSequence: number = Math.max(
      ...sequence.map((i: ISequence) => i.sequence)
    );
    return maxSequence + 1;
  }

  if (elem.kind === 'lost') {
    const minSequence: number = Math.min(
      ...sequence.map((i: ISequence) => i.sequence)
    );
    return minSequence - 1;
  }

  return elem.sequence;
};

type Value = number | string;

type DeltaField = {
  value: Value;
  prev_value: Value;
  updated_at: string;
};

const isNumber = (value: Value): value is number => typeof value === 'number';
const isString = (value: Value): value is string => typeof value === 'string';

const getRawValue = (field: string, row: IRow): ValueProp => {
  if (R.path(field.split('.'), row) !== undefined)
    return R.path(field.split('.'), row) as ValueProp;

  return R.pathOr(undefined, ['extra', field, 'value'], row);
};

const ArrowIcon: React.FC<{
  isNegative: boolean;
  prevDisplayValue?: React.ReactNode;
  displayValue?: React.ReactNode;
  changesSinceCopy?: string;
  changesSinceDate?: string;
}> = ({
  isNegative,
  prevDisplayValue,
  displayValue,
  changesSinceCopy,
  changesSinceDate,
}) => (
  <Popup
    trigger={
      <div className={classNames(arrowMargin, isNegative ? 'down' : 'up')}>
        {isNegative ? (
          <BuIcon name={BoostUpIcons.ChangeDown} />
        ) : (
          <BuIcon name={BoostUpIcons.ChangeUp} />
        )}
      </div>
    }
    offset={[0, 0]}
    position="bottom center"
    mouseEnterDelay={500}
  >
    <div className={classNames(styles.popupText, popupWidth)}>
      <div>
        Changed from{' '}
        <span className={styles.popupTextBold}>{prevDisplayValue}</span> to{' '}
        <span className={styles.popupTextBold}>{displayValue}</span>
        {changesSinceDate && changesSinceCopy && (
          <div>
            Delta is based on Changes Since {changesSinceCopy} (
            <span className={styles.popupTextBold}>{changesSinceDate}</span>)
          </div>
        )}
      </div>
    </div>
  </Popup>
);

const ChangeCell = ({ column, row, onChange = () => {} }: IChangeCellProps) => {
  const fields: string[] = column.field.split('.');
  const changesSinceCopy = row?.changesSinceCopy;
  const changesSinceDate = row?.changesSinceDate;
  const deltaField = row?.[fields?.[0]] as { [key: string]: DeltaField | any };
  const sequenceType = fields?.[1] || fields?.[0];
  const fieldName = fields?.[1] || fields?.[0];

  /**
   * Forcing a key that is not a delta to have the value key inside the object
   */
  if (
    deltaField &&
    deltaField[fieldName] &&
    R.type(deltaField[fieldName]) !== 'Object'
  ) {
    deltaField[fieldName] = {
      value: deltaField[fieldName],
    };
  }

  let { value, prev_value } = deltaField?.[fields?.[1]] || deltaField || {};
  let isNegative: boolean = false;
  let isEqual: boolean = false;
  const companyCurrencyCode = useSelector(selectors.getUserLocalCurrency);

  if (value === null || prev_value === null) {
    if (value === null) {
      /*
       * Changes to null are always red and value is displayed as a hyphen.
       * Expect for number which value should be 0.
       */
      value = isNumber(prev_value) ? 0 : '-';
      isNegative = true;
    } else {
      /*
       * Changes coming from null are always green and prev_value is displayed as a hyphen.
       * Expect for number which prev_value should be 0.
       */
      prev_value = isNumber(value) ? 0 : '-';
    }
  } else {
    if (isNumber(prev_value) && isNumber(value)) {
      isNegative = value - prev_value < 0;
    }

    if (column.config.sequence && isString(prev_value) && isString(value)) {
      const currIdx = getIdx(
        column.config.sequence as ISequence[],
        value,
        sequenceType
      );
      const prevIdx = getIdx(
        column.config.sequence as ISequence[],
        prev_value,
        sequenceType
      );
      isNegative = currIdx < prevIdx;
      isEqual = currIdx === prevIdx;
    }

    if (column.field.toLowerCase().includes('date') || column.config.isDate) {
      isNegative = new Date(prev_value).getTime() < new Date(value).getTime();
    }
  }

  /**
   * If value and prev_value are the same, do not show changes in the cell
   */
  if (value === prev_value) {
    isEqual = true;
  }

  const crmMetadata = getRawValue('crm_metadata', row) as CRMMetadataType;
  const currency =
    column.type === ColumnTypes.CORPORATE_CURRENCY ||
    !column.config.isMulticurrency
      ? companyCurrencyCode
      : crmMetadata?.currency || companyCurrencyCode;
  const exchangeRate =
    column.type === ColumnTypes.CORPORATE_CURRENCY
      ? null
      : getRawValue('exchange_rate', row) || null;

  const hasChanged = !R.isNil(prev_value) && !isEqual;
  const displayValue = isNumber(value)
    ? column.config.formatter!(value, currency, exchangeRate)
    : column.field.includes('date')
    ? dateFormatter(column.config.format, value)
    : value;
  const prevDisplayValue = isNumber(prev_value)
    ? column.config.formatter!(prev_value, currency, exchangeRate)
    : column.field.includes('date')
    ? dateFormatter(column.config.format, prev_value)
    : prev_value;
  const showMultiCurrencyIcon =
    column.config.isMoney &&
    column.config.isMulticurrency &&
    (row.crm_metadata as any).currency &&
    (row.crm_metadata as any).currency !== companyCurrencyCode;

  const isOppSplitFlagEnabled = useSelector(selectors.isOppSplitEnabled);
  const splitted_fields = (row?.splitted_fields ?? []) as string[];

  const isColumnSplited =
    splitted_fields.includes(fieldName) && isOppSplitFlagEnabled;

  const OppSplitColumnTooltip = isColumnSplited ? (
    <OppSplitTooltip
      dealId={row._id as string}
      fieldName={fieldName}
      crmField={column.crm_field}
      rowUserName={(row.account_executive as string) || (row.owner as string)}
    />
  ) : null;

  return (
    <div
      className={classNames('change-cell', wrapper, {
        [cell]: hasChanged,
        up: !isNegative,
        down: isNegative,
      })}
    >
      {showMultiCurrencyIcon ? (
        <MultiCurrencyIconTooltip column={column} row={row} />
      ) : null}
      {OppSplitColumnTooltip}
      <div
        className={classNames('change-value', valueStyle, {
          [alignRight]: column.align === 'right',
          [alignCenter]: column.align === 'center',
        })}
      >
        <div className={valueContainer}>
          <TooltipWrapper
            tooltip={
              column.showTooltip && !showMultiCurrencyIcon && displayValue
            }
          >
            <div className={classNames(displayValueStyle)}>{displayValue}</div>
          </TooltipWrapper>
          {hasChanged ? (
            <ArrowIcon
              isNegative={isNegative}
              prevDisplayValue={prevDisplayValue}
              displayValue={displayValue}
              changesSinceCopy={changesSinceCopy}
              changesSinceDate={changesSinceDate}
            />
          ) : (
            <div className={deltaIconSpaceHolder} />
          )}
        </div>
        {hasChanged && (
          <TooltipWrapper tooltip={column.showTooltip && prevDisplayValue}>
            <div className={classNames('change-delta', deltaValueStyle)}>
              {prevDisplayValue}
            </div>
          </TooltipWrapper>
        )}
      </div>
    </div>
  );
};

export default ChangeCell;
