import { useModal } from '../components/modals/ModalContext/modal.context';
import BadgeTitle from './BadgeTitle';
import { full_screen as fullScreen } from './styles';
import { ModalsScheme } from './types';
import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { Switch as RouterSwitch, useLocation } from 'react-router-dom';

import { actions } from 'actions';
import Modal from 'components/UI/Modal/Modal';
import ModalEmailDetail from 'components/UI/Modal/ModalEmailDetails';
import QuickView from 'components/UI/Modal/QuickView';
import { MODALS } from 'navigation/modals';
import { getSchemeFromSection } from 'navigation/utils';
import { IReduxState } from 'reducers/types';
import { conf } from 'store/configureStore';

const basename = conf ? conf.basename : '';

export const Switch: React.FC<{}> = ({ children }) => {
  const location = useLocation();

  return (
    <RouterSwitch
      location={{
        ...location,
        pathname: location.pathname.replace(basename, '').split('/~/')[0],
      }}
    >
      {children}
    </RouterSwitch>
  );
};

type ParsedSchemes = {
  scheme: ModalsScheme;
  params?: string;
};

type ReduxDispatchProps = typeof dispatchToProps;
type IsLiveView = { isLiveView: boolean };
type ModalsSwitchImplProps = IsLiveView & ReduxDispatchProps;
type RenderModalProps = IsLiveView & ParsedSchemes & ReduxDispatchProps;

const RenderModal: React.FC<RenderModalProps> = React.memo(
  ({ scheme, params, remove, isLiveView }) => {
    const { size, type, title, component, fullWidth } = MODALS[scheme];
    const Component: React.FC<any> = component;
    const parsedParams = params ? JSON.parse(params) : undefined;
    const modal = useModal();

    const handleClose = () => {
      modal.closeModal(scheme, () => remove({ scheme }));
    };
    if (type === 'window') {
      return (
        <Modal
          title={
            <>
              {title || ''}
              {scheme === '/deals' && isLiveView && (
                <BadgeTitle value="Live View" />
              )}
            </>
          }
          onClose={handleClose}
          size={size || 'fullscreen'}
          fullWidth={fullWidth}
        >
          <Component params={parsedParams} />
        </Modal>
      );
    }

    if (type === 'email_window') {
      return (
        <ModalEmailDetail
          onClose={handleClose}
          size={size || 'fullscreen'}
          type="email_window"
        >
          <Component params={parsedParams} />
        </ModalEmailDetail>
      );
    }

    if (type === 'next_window') {
      return (
        <ModalEmailDetail
          onClose={handleClose}
          size={size || 'fullscreen'}
          type="next_window"
        >
          <Component params={parsedParams} />
        </ModalEmailDetail>
      );
    }

    if (type === 'activity_window') {
      return (
        <ModalEmailDetail
          onClose={handleClose}
          size={size || 'fullscreen'}
          type="activity_window"
        >
          <Component params={parsedParams} />
        </ModalEmailDetail>
      );
    }

    if (type === 'event_window') {
      return (
        <ModalEmailDetail
          onClose={handleClose}
          size={size || 'fullscreen'}
          type="event_window"
        >
          <Component params={parsedParams} />
        </ModalEmailDetail>
      );
    }

    if (type === 'full_screen') {
      return (
        <div className={fullScreen}>
          <Component params={parsedParams} onClose={handleClose} />
        </div>
      );
    }

    if (type === 'panel') {
      return <Component params={parsedParams} onClose={handleClose} />;
    }

    return (
      <QuickView onClose={handleClose} size={size}>
        <Component params={parsedParams} onClose={handleClose} />
      </QuickView>
    );
  }
);

const ModalsSwitchImpl: React.FC<ModalsSwitchImplProps> = ({
  remove,
  isLiveView,
}) => {
  const { pathname } = useLocation();

  const schemes: ParsedSchemes[] = useMemo(() => {
    const parts = pathname.replace(basename, '').split('/~');

    return parts
      .slice(1)
      .map((section) => getSchemeFromSection(section))
      .filter((scheme) => !!scheme) as any;
  }, [pathname]);

  return (
    <>
      {schemes.map((item, order) => (
        <RenderModal
          key={item.scheme + order}
          params={item.params}
          scheme={item.scheme}
          remove={remove}
          isLiveView={isLiveView}
        />
      ))}
    </>
  );
};

const mapStateToProps = ({ deals }: IReduxState) => {
  const showLiveBadge = deals?.showLiveBadgeOnModal ?? false;
  return {
    isLiveView: showLiveBadge,
  };
};

const dispatchToProps = {
  remove: actions.ui.modal.remove,
};

export const ModalsSwitch = connect(
  mapStateToProps,
  dispatchToProps
)(ModalsSwitchImpl);
