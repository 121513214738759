import classNames from 'classnames';
import { css } from 'emotion';
import React, { ChangeEvent, useCallback } from 'react';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import { fontDefault } from 'assets/css/common';
import closeIcon from 'assets/images/close.svg';
import { BuControlSize } from 'components/UI/BuButton';
import BuIcon from 'components/UI/BuIcon';
import { useClickOutside } from 'components/hooks/useClickOutside';

export type TSearchVariant = 'collapsible' | 'default' | 'top-header';

export type TProps = {
  type?: TSearchVariant;
  name: string;
  onChange: (value: string) => void;
  value: string;
  size?: BuControlSize;
};

const close_icon = css`
  cursor: pointer;
  height: 10px;
  width: 10px;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
`;

const search_icon = css`
  font-size: 15px;
`;

const mapVariantToStyles: {
  [key in TSearchVariant]: { [key: string]: string };
} = {
  collapsible: {
    container: css`
      width: 32px;

      &.open {
        width: 224px;
      }
    `,
    label: css`
      display: flex;
      flex-direction: row;
      align-items: baseline;
      position: relative;
      width: 100%;
      border: 1px solid var(--bu-gray-500);
      border-radius: 100px;
      background-color: var(--bu-gray-200);

      &.open {
        background-color: var(--bu-white);
      }
      :focus-within {
        border: 1px solid var(--bu-gray-700);
      }

      &::placeholder {
        color: var(--bu-gray-700);
      }
    `,
    close_icon,
    search_icon,
    input: css`
      display: none;
      &.open {
        display: block;
        height: 30px;
        width: 100%;
        border: none;
        border-radius: 100px;
        color: var(--bu-gray-900);
        ${fontDefault};

        &::placeholder {
          color: var(--bu-gray-700);
        }
      }
    `,
  },
  default: {
    container: css`
      width: 224px;
      margin: 0 15px;
    `,
    label: css`
      display: flex;
      flex-direction: row;
      position: relative;
      width: 100%;
      border: 1px solid var(--bu-gray-500);
      border-radius: 100px;
      color: var(--bu-gray-900);
      cursor: default;
      border-radius: 8px;
      padding: 0 7px;
      align-items: center;

      :focus-within {
        border: 1px solid var(--bu-primary-500);

        i {
          color: var(--bu-primary-700) !important;
        }
      }

      &::placeholder {
        color: var(--bu-gray-700);
      }
    `,
    close_icon,
    search_icon,
    input: css`
      display: block;
      height: 30px;
      width: 100%;
      border: none;
      border-radius: 100px;
      color: var(--bu-gray-900);
      margin-left: 5px;
      font-size: 12px;
      font-weight: normal;

      &::placeholder {
        color: var(--bu-gray-700);
      }
    `,
  },
  ['top-header']: {
    container: css`
      width: 224px;
    `,
    label: css`
      display: flex;
      flex-direction: row;
      align-items: baseline;
      position: relative;
      width: 100%;
      background-color: var(--bu-gray-100);
      border: 1px solid var(--bu-gray-500);
      border-radius: 100px;

      :focus-within {
        background-color: var(--bu-white);
        border: 1px solid var(--bu-gray-700);
      }
    `,
    close_icon,
    search_icon,
    input: css`
      height: 30px;
      width: 100%;
      border: none;
      border-radius: 100px;
      background-color: var(--bu-gray-100);
      color: var(--bu-gray-900);
      padding: 0 30px 2px 30px;
      ${fontDefault};

      :focus {
        background-color: var(--bu-white);
      }

      &::placeholder {
        color: var(--bu-gray-700);
      }
    `,
  },
};

const Search: React.FC<TProps> = ({
  type = 'default',
  name,
  onChange,
  value,
  size,
}) => {
  const { isOpen, setIsOpen, refElement } = useClickOutside();

  const handleOpen = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  }, []);

  const handleReset = useCallback(() => {
    onChange('');
  }, []);

  const s = mapVariantToStyles[type];

  return (
    <div
      className={classNames('bu-search', s.container, { open: isOpen })}
      onClick={handleOpen}
      ref={refElement}
    >
      <label
        className={classNames('bu-search-label', s.label, { open: isOpen })}
      >
        <BuIcon
          className={s.search_icon}
          name={BoostUpIcons.Search}
          color="var(--bu-gray-500)"
        />
        <input
          placeholder="Search opportunities"
          name={name}
          onChange={handleChange}
          value={value}
          type="search"
          className={classNames('bu-font-default', s.input, size, {
            open: isOpen,
          })}
          data-testing="txt_field"
        />
        {value && (
          <img
            className={classNames(s.close_icon, size, 'close_icon')}
            onMouseDown={handleReset}
            src={closeIcon}
            alt="clear search"
          />
        )}
      </label>
    </div>
  );
};

export default Search;
