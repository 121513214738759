import styled from '@emotion/styled';

export const Title = styled.h2`
  color: var(--bu-gray-900);
  font-weight: normal;
  margin-bottom: 4px;
  white-space: nowrap;
  display: flex;
  font-size: 13px;

  i {
    margin-left: 8px;
  }
`;

export const TruncateTitle = styled.span`
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;

  @media (min-width: 1000px) and (max-width: 1099px) {
    max-width: 45px;
  }

  @media (min-width: 1100px) and (max-width: 1199px) {
    max-width: 65px;
  }

  @media (min-width: 1200px) and (max-width: 1400px) {
    max-width: 100px;
  }
`;

export const Body = styled.p`
  align-items: center;
  color: var(--bu-gray-900);
  display: flex;
  font-family: var(--bu-font-medium);
  font-size: 18px;
  letter-spacing: 0;
  line-height: 20px;
`;

interface DeltaProps {
  isPositive?: boolean;
}

export const Delta = styled.span<DeltaProps>`
  font-family: var(--bu-font-medium);
  font-size: 12px;
  letter-spacing: 0;
  margin-left: 8px;
  padding: 0px 4px 0px 4px;
  border-radius: 4px;
  color: var(--bu-white);
  background-color: ${(props) =>
    props.isPositive ? 'var(--bu-green-500)' : 'var(--bu-red-400)'};
`;

interface MetricItemProps {
  isClickable: boolean;
  itemsPerRow: number;
}

export const MetricItem = styled.li<MetricItemProps>`
  box-sizing: border-box;
  border: 1px solid var(--bu-gray-400);
  border-radius: var(--bu-control-border-radius);
  cursor: ${(props) => (props.isClickable ? 'pointer' : 'auto')};
  min-height: 67px;
  padding: 12px 20px;
  margin-bottom: 8px;
  margin-right: 8px;

  &:nth-child(${(props) => `${props.itemsPerRow}n`}) {
    margin-right: 0;
  }
`;

export const MetricList = styled.ul<{ itemsPerRow: number }>`
  display: grid;
  grid-template-columns: ${(props) => `repeat(${props.itemsPerRow}, 1fr)`};
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

export const MetricPanelSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-top: 10px;
`;

// TODO we shold find a way to avoid using a harcoded max-height for the animation
export const CollapseAnimationContainer = styled.div`
  transition: max-height 0.1s;
  max-height: 2000px;
  &.closed {
    max-height: 0px;
    overflow: hidden;
  }
`;
